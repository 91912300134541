import { useContext, useState, useMemo, useCallback, useEffect } from 'react'
import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query'
import { GlobalContext, RulesContext } from 'context'
import { getKeywordsByBaseline, updateKeyword } from 'api'
import { showToast } from 'utils'

export const useKeywordTableController = () => {
  const queryClient = useQueryClient()
  const { isUserRoleEqualOrGreaterThanEditor, isUserRoleEqualOrGreaterThanAdvanced } = useContext(GlobalContext)

  const { selectedBaseline, keyWordFilter, addKeywordFilter, userIsOwnerForSelectedBaseline } = useContext(RulesContext)

  const [selectedKeyword, setSelectedKeyword] = useState(null)
  const [definition, setDefinition] = useState('')

  const { data, isLoading, fetchStatus } = useQuery(
    ['keywordsByBaseline', selectedBaseline?.value],
    getKeywordsByBaseline,
    {
      enabled: !!selectedBaseline,
    }
  )
  const { mutate: update } = useMutation(updateKeyword, {
    onSuccess: response => {
      queryClient.setQueryData(
        ['keywordsByBaseline', selectedBaseline?.value],
        data.map(element => (element.keyword_id === response.data.keyword_id ? response.data : element))
      )
      addKeywordFilter('')
      showToast(response.message)
    },
  })

  const keywordsData = useMemo(() => {
    if (!data || isLoading) {
      return []
    }
    return data.map(field => ({
      id: field.keyword_id,
      data: field.name,
      definition: field.definition || '',
    }))
  }, [data, isLoading])

  const handleDefinitionChange = value => setDefinition(value)

  const handleSave = () => {
    setSelectedKeyword(null)

    const keyword = data.find(k => k.keyword_id === selectedKeyword.id)
    update({
      keywordId: keyword.keyword_id,
      definition,
    })
  }

  const handleCancel = () => setSelectedKeyword(null)

  const handleFilterChange = useCallback(() => {
    setSelectedKeyword(null)
    setDefinition('')
  }, [setSelectedKeyword, setDefinition])

  useEffect(() => {
    if (selectedBaseline) {
      setSelectedKeyword(null)
      setDefinition('')
    }
  }, [selectedBaseline])

  useEffect(() => {
    if (selectedKeyword) {
      setDefinition(selectedKeyword.definition)
    }
  }, [selectedKeyword])

  return {
    selectedBaseline,
    selectedKeyword,
    selectedRowIds: selectedKeyword ? [selectedKeyword.id] : [],
    definition,
    keywordsData,
    keyWordFilter,
    isKeywordsLoading: isLoading || fetchStatus === 'fetching',
    isReadOnly: isUserRoleEqualOrGreaterThanAdvanced
      ? false
      : !(isUserRoleEqualOrGreaterThanEditor && userIsOwnerForSelectedBaseline),
    setSelectedKeyword,
    handleDefinitionChange,
    handleSave,
    handleCancel,
    handleFilterChange,
  }
}
