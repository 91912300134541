import styled from '@emotion/styled'
import { Button } from 'reactstrap'

export const StyledButton = styled(Button)``

const buttonStyles = {
  margin: 0,
  minWidth: '92px',
  minHeight: '38px',

  '@media (max-width: 575px)': {
    width: '100%',
    margin: '0.35rem 0',
  },
}

export const styles = {
  button: buttonStyles,
}
