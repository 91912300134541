/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types'
import { Col } from 'reactstrap'
import { styles } from './Status.styles'

const FALLBACK_COLOR = 'light-gray'
const FALLBACK_LABEL = 'NOT FOUND'

const STATUS_COLORS = {
  EDIT: '#f96332',
  REVIEW: '#2ca8ff',
  PRODUCTION: '#18ce0f',
  HISTORICAL: '#ffb236',
}

const STATUS_LABEL = {
  EDIT: 'EDIT',
  REVIEW: 'REVIEW',
  PRODUCTION: 'PRODUCTION',
  HISTORICAL: 'HISTORICAL',
}

const Status = ({ name }) => {
  const color = STATUS_COLORS[name] || FALLBACK_COLOR
  const label = STATUS_LABEL[name] || FALLBACK_LABEL

  return (
    <div css={styles.container(color)}>
      <Col md="2" sm="2" xs="2" css={styles.icon(color)}>
        <i className="fas fa-circle fa-2xs" />
      </Col>
      <Col md="10" sm="8" xs="14">
        <p css={styles.label}>{`STATUS: ${label}`}</p>
      </Col>
    </div>
  )
}

Status.propTypes = {
  name: PropTypes.oneOf(['EDIT', 'REVIEW', 'PRODUCTION', 'HISTORICAL']).isRequired,
}

export { Status }
