import { useState } from 'react'

export const useTableBodyController = ({ columnsOptions, selectedRowIds, rowIdKey, onRowClick, onCellClick }) => {
  const [selectedCell, setSelectedCell] = useState(null)

  const cursorAsPointer = columnsOptions.autoSelection
    ? !!onRowClick || !!onCellClick
    : (!!onRowClick || !!onCellClick) && selectedRowIds.length < columnsOptions.selectionLimit

  const renderRowStyles = row => ({
    cursor: cursorAsPointer ? 'pointer' : 'auto',
    ...(columnsOptions.highlightSelection &&
      selectedRowIds.some(id => id === row.original[rowIdKey]) && { backgroundColor: 'rgba(0, 0, 0, 0.075)' }),
  })

  const handleClick = selected => {
    if (!columnsOptions.autoSelection && selectedRowIds.length === columnsOptions.selectionLimit) {
      return
    }
    if (onRowClick) onRowClick(selected.row.original)
    if (onCellClick) {
      setSelectedCell({ columnId: selected.column.id, rowId: selected.row.id })
      onCellClick({ id: selected.column.id, label: selected.column.Header, value: selected.value })
    }
  }

  return { selectedCell, renderRowStyles, handleClick }
}
