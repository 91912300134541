import { useMemo, useContext } from 'react'
import { useQuery } from '@tanstack/react-query'
import { getUsers } from 'api'
import { GlobalContext, CRUDManagementContext } from 'context'

export const useUserListController = () => {
  const { data, isLoading } = useQuery(['users'], getUsers)

  const { selected, select, mode, deleteSelected, activateCreationMode } = useContext(CRUDManagementContext)

  const { roles } = useContext(GlobalContext)

  const userListData = useMemo(() => {
    if (!data || isLoading || !Object.keys(roles).length) {
      return []
    }
    return data.map(field => ({
      id: field.user_id,
      user: field.user,
      email: field.email,
      role: roles[field.role_id].name,
      role_id: field.role_id,
    }))
  }, [data, isLoading, roles])

  return {
    userListData,
    isUsersLoading: isLoading,
    select,
    deleteSelected,
    selectedRowIds: selected ? [selected.id] : [],
    mode,
    activateCreationMode,
  }
}
