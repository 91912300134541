import { AppLayout } from '@reddieoffice/reddie_react_lib'
import { useLayoutController } from './Layout.controller'

const Layout = ({ children, routeList, activeRouteName }) => {
  const { user, logOut, handleChangePassword } = useLayoutController()

  return (
    <AppLayout
      user={user}
      routeList={routeList}
      isFooterFluid
      activeRouteName={activeRouteName}
      handleLogOut={logOut}
      handleChangePassword={handleChangePassword}
    >
      {children}
    </AppLayout>
  )
}

export { Layout }
