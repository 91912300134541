/** @jsxImportSource @emotion/react */
import { useMemo } from 'react'
import { Label } from 'reactstrap'
import { Button } from 'components'
import { ADVANCED_ROLE_PRIORITY } from 'utils/constants'
import { useDataTypeFormController } from './DataTypeForm.controller'
import { StyledForm, StyledInput, StyledButtonWrapper, styles } from './DataTypeForm.styles'

export const DataTypeForm = () => {
  const {
    translatorDataType,
    manufacturer,
    baselineDataType,
    isDefaultMode,
    isEditMode,
    isPrimaryButtonDisabled,
    isLoading,
    handleChange,
    handleCancel,
    handlePrimaryButton,
  } = useDataTypeFormController()

  const primaryButtonText = useMemo(() => {
    if (isDefaultMode) return 'Add'
    if (isEditMode) return 'Save'
    return 'Create'
  }, [isDefaultMode, isEditMode])

  return (
    <>
      <StyledForm css={styles.form}>
        <Label>Translator data type</Label>
        <StyledInput
          css={styles.input}
          placeholder="Translator data type"
          name="translator-data-type"
          value={translatorDataType}
          onChange={handleChange}
          disabled={isDefaultMode}
        />
        <Label>Manufacturer</Label>
        <StyledInput
          css={styles.input}
          placeholder="Manufacturer"
          name="manufacturer"
          value={manufacturer}
          onChange={handleChange}
          disabled={isDefaultMode}
        />
        <Label>Baseline data type</Label>
        <StyledInput
          css={styles.input}
          placeholder="Baseline data type"
          name="baseline-data-type"
          value={baselineDataType}
          onChange={handleChange}
          disabled={isDefaultMode}
        />
      </StyledForm>
      <StyledButtonWrapper css={styles.buttonWrapper}>
        {!isDefaultMode && (
          <Button outline isCancelButton color="danger" handleClick={handleCancel}>
            Cancel
          </Button>
        )}
        <Button
          handleClick={handlePrimaryButton}
          isDisabled={isPrimaryButtonDisabled}
          isLoading={isLoading}
          priority={ADVANCED_ROLE_PRIORITY}
        >
          {primaryButtonText}
        </Button>
      </StyledButtonWrapper>
    </>
  )
}
