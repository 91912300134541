import { BaselineHistory } from 'components'
import { useHistoryController } from './History.controller'

export const History = () => {
  const { data, filter, isDisabled, isLoading, selected, handleRowClick, handleRefetch } = useHistoryController()

  return (
    <BaselineHistory
      data={data}
      filter={filter}
      isLoading={isLoading}
      isButtonDisabled={isDisabled}
      exportConfig={{
        columnsToSkip: [],
        excelName: 'baseline_history',
        sheetName: 'Sheet 1',
      }}
      useAdvancedFilter
      selected={selected}
      handleRowClick={handleRowClick}
      refetch={handleRefetch}
    />
  )
}
