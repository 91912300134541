/** @jsxImportSource @emotion/react */
import { FutureTable } from 'futurekit'
import { EmptyState, Spinner } from 'components'
import { useUserListController } from './UserList.controller'

const COLUMNS = [
  {
    Header: 'Name',
    accessor: 'user',
    sortable: true,
    filterable: true,
  },
  {
    Header: 'Email',
    accessor: 'email',
    sortable: true,
    filterable: true,
  },
  {
    Header: 'Role',
    accessor: 'role',
    sortable: true,
    filterable: true,
  },
]

export const UserList = () => {
  const { userListData, isUsersLoading, select, selectedRowIds, mode } = useUserListController()

  if (isUsersLoading) {
    return <Spinner />
  }
  if (!userListData || !userListData.length) {
    return <EmptyState size="5rem" />
  }

  return (
    <FutureTable
      data={userListData}
      defaultColumns={COLUMNS}
      filterValue={mode === 'create' ? '' : null}
      filterNameReachable="user"
      selectedRowIds={selectedRowIds}
      onRowClick={select}
      columnsConfig={{
        isStriped: false,
        highlightSelection: true,
        autoSelection: true,
        isDisabled: mode === 'create',
      }}
    />
  )
}
