import { useEffect, useState, useContext, useMemo, useCallback } from 'react'
import { CRUDManagementContext, GlobalContext } from 'context'
import {
  getUsersAllowedToBeOwner,
  getStatusFilter,
  createBaseline,
  deleteBaseline as deleteBaselineApi,
  updateBaseline,
  searchBaselineWithProdStatus,
} from 'api'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { showPrompt, showToast, showWarningAlert } from 'utils'

const STATUS_PRODUCTION_LABEL = 'PRODUCTION'

const INITIAL_FORM = {
  baselineVersion: '',
  softwareVersion: '',
  technology: '',
  manufacturer: '',
  hardwareType: '',
  owner: null,
  status: null,
}

export const useBaselineFormController = () => {
  const queryClient = useQueryClient()
  const { user, isUserRoleEqualOrGreaterThanEditor, isUserRoleEqualOrGreaterThanAdvanced } = useContext(GlobalContext)
  const { selected, mode, deleteSelected, activateCreationMode } = useContext(CRUDManagementContext)

  const [form, setForm] = useState(INITIAL_FORM)

  const { data: users, isLoading: isUsersLoading } = useQuery(['usersAllowedToBeOwner'], getUsersAllowedToBeOwner)

  const {
    data: status,
    isLoading: isStatusLoading,
    fetchStatus,
  } = useQuery(['status', selected?.baseline_id], getStatusFilter, {
    enabled: !!selected,
  })

  const reset = () => {
    deleteSelected()
    setForm(INITIAL_FORM)
  }

  const { mutate: update, isLoading: isUpdateLoading } = useMutation(updateBaseline, {
    onSuccess: response => {
      queryClient.refetchQueries(['baselines'])
      queryClient.refetchQueries(['baselineStatusHistory'])
      reset()
      showToast(response.message)
    },
  })

  const { mutate: create, isLoading: isCreateLoading } = useMutation(createBaseline, {
    onSuccess: response => {
      queryClient.setQueryData(['baselines'], data => {
        return [...data, response.data]
      })
      reset()
      showToast(response.message)
    },
  })

  const { mutateAsync: deleteBaseline } = useMutation(deleteBaselineApi, {
    onSuccess: (response, variables) => {
      queryClient.setQueryData(['baselines'], data => {
        return data.filter(element => element.baseline_id !== variables.baselineId)
      })
      reset()
      showToast(response.message)
    },
  })

  const { mutate: searchBaseline } = useMutation(searchBaselineWithProdStatus, {
    onSuccess: response => {
      showWarningAlert({ body: response.message })
    },
  })

  const userListData = useMemo(() => {
    if (!users || isUsersLoading) {
      return []
    }
    return users.map(u => ({
      value: u.user_id,
      label: u.user,
    }))
  }, [isUsersLoading, users])

  const statusListData = useMemo(() => {
    if (!status || isStatusLoading) {
      return []
    }
    return status.map(s => ({
      value: s.status_id,
      label: s.name,
    }))
  }, [isStatusLoading, status])

  const isPrimaryButtonDisabled = useMemo(() => {
    if (mode === 'edit') {
      return Object.values(form).some(value => !value)
    }
    if (mode === 'create') {
      const { owner, ...formWithoutOwner } = form
      return Object.values(formWithoutOwner).some(value => !value)
    }
    return false
  }, [form, mode])

  const isReadOnlyLogic = useMemo(() => {
    if (isUserRoleEqualOrGreaterThanAdvanced) return false
    if (!selected && isUserRoleEqualOrGreaterThanEditor) return false
    if (isUserRoleEqualOrGreaterThanEditor && selected?.owner_id === user?.user_id) return false
    return true
  }, [isUserRoleEqualOrGreaterThanAdvanced, isUserRoleEqualOrGreaterThanEditor, selected, user?.user_id])

  const handleChange = useCallback(
    event => {
      const { name, value } = event.target
      setForm({ ...form, [name]: value })
    },
    [form, setForm]
  )

  const handleSelectChange = useCallback(
    (select, event) => {
      setForm({ ...form, [event.name]: select })

      if (event.name === 'status' && select.label === STATUS_PRODUCTION_LABEL) {
        searchBaseline({
          baselineId: selected?.baseline_id,
          technology: form?.technology,
          manufacturer: form?.manufacturer,
          hardwaretype: form?.hardwareType,
        })
      }
    },
    [form, searchBaseline, selected?.baseline_id]
  )

  const handlePrimaryButtonClick = () => {
    switch (mode) {
      case 'create':
        create({
          baseline_version: form.baselineVersion,
          software_version: form.softwareVersion,
          technology: form.technology,
          manufacturer: form.manufacturer,
          hardware_type: form.hardwareType,
        })
        break
      case 'edit':
        update({
          baselineId: selected.baseline_id,
          baseline_version: form.baselineVersion,
          software_version: form.softwareVersion,
          technology: form.technology,
          manufacturer: form.manufacturer,
          hardware_type: form.hardwareType,
          owner: form.owner.value,
          status: form.status.value,
        })
        break
      case 'default':
        activateCreationMode()
        break
      default:
        break
    }
  }

  const handleDeleteBaseline = useCallback(async () => {
    try {
      await deleteBaseline({ baselineId: selected.baseline_id })
    } catch (error) {
      // error handled on api interceptor
    }
  }, [deleteBaseline, selected])

  const handleDelete = () => {
    showPrompt({
      title: 'Are you sure you want to delete the baseline?',
      confirmButtonText: 'Yes, delete',
      callbackAsyncFn: handleDeleteBaseline,
    })
  }

  useEffect(() => {
    if (selected) {
      setForm({
        baselineVersion: selected.baseline_version,
        softwareVersion: selected.software_version,
        technology: selected.technology,
        manufacturer: selected.manufacturer,
        hardwareType: selected.hardware_type,
        owner: userListData.find(el => el.value === selected.owner_id),
        status: statusListData.find(el => el.value === selected.status),
      })
    }
  }, [selected, userListData, statusListData])

  return {
    form,
    selected,
    mode,
    userListData,
    statusListData,
    isDefaultMode: mode === 'default',
    isEditMode: mode === 'edit',
    isLoading: isUsersLoading || isStatusLoading || fetchStatus === 'fetching' || isUpdateLoading || isCreateLoading,
    isReadOnly: isReadOnlyLogic,
    isPrimaryButtonDisabled,
    handleChange,
    handleSelectChange,
    handlePrimaryButtonClick,
    handleDelete,
    handleCancel: reset,
  }
}
