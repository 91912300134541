import { useState } from 'react'
import classnames from 'classnames'
import { Table } from 'reactstrap'
import { v4 as uuidv4 } from 'uuid'

const DEFAULT_OPTIONS = {
  showHead: true,
  readOnlyMode: false,
  highlightSelection: false,
}

const SimpleTable = ({ columns, data, onRowClick, options = DEFAULT_OPTIONS, ...restProps }) => {
  const [selectedRow, setSelectedRow] = useState(null)
  const renderOptions = options ? { ...DEFAULT_OPTIONS, ...options } : DEFAULT_OPTIONS

  const handleClick = element => {
    if (onRowClick) onRowClick(element)
    if (renderOptions.highlightSelection) setSelectedRow(element.id)
  }

  return (
    <div className={classnames(restProps.className)}>
      <Table responsive hover={!renderOptions.readOnlyMode}>
        {!!renderOptions.showHead && (
          <thead className="text-primary">
            <tr>
              {columns.map((column, columnIndex) => (
                <th key={uuidv4()} className={columnIndex === columns.length - 1 ? 'text-right' : ''}>
                  {column}
                </th>
              ))}
            </tr>
          </thead>
        )}
        <tbody>
          {data.map(prop => (
            <tr
              key={prop.id}
              onClick={() => handleClick(prop)}
              style={{
                backgroundColor:
                  !!renderOptions.highlightSelection && selectedRow === prop.id ? 'rgba(0, 0, 0, 0.075)' : 'inherit',
              }}
            >
              {prop.data.map((tableData, tableDataIndex) => (
                <td
                  key={uuidv4()}
                  className={tableDataIndex === columns.length - 1 ? 'text-right' : ''}
                  style={{
                    cursor: !renderOptions.readOnlyMode || renderOptions.highlightSelection ? 'pointer' : 'default',
                  }}
                >
                  {tableData}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

export { SimpleTable }
