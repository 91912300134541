import { createContext, useReducer, useCallback, useMemo, useEffect } from 'react'
import { ADVANCED_BULK_LOAD_INITIAL_STATE, AdvancedBulkLoadReducer } from './AdvancedBulkLoadReducer'

export const AdvancedBulkLoadContext = createContext({})

export const AdvancedBulkLoadProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AdvancedBulkLoadReducer, ADVANCED_BULK_LOAD_INITIAL_STATE)

  const baselineDetails = useMemo(() => {
    if (!state.selectedBaseline || !state.columnsEquivalence) {
      return null
    }
    return state.columnsEquivalence[state.selectedBaseline] || null
  }, [state.columnsEquivalence, state.selectedBaseline])

  const resetToInitialState = useCallback(() => {
    dispatch({ type: 'RESET_STATE' })
  }, [])

  const toggleValidate = useCallback(() => {
    dispatch({ type: 'TOGGLE_VALIDATE' })
  }, [])

  const uploadFile = useCallback(payload => {
    dispatch({ type: 'UPLOAD_FILE', payload })
  }, [])

  const setFormDetails = useCallback(payload => {
    dispatch({ type: 'SET_FORM_DETAILS', payload })
  }, [])

  const addColumnsEquivalence = useCallback(
    response => {
      const payload = Object.values(response).reduce((prev, next, i) => {
        const baseline = Object.keys(response)[i]
        return {
          ...prev,
          [baseline]: {
            ...next,
            import: false,
            baseline: {
              manufacturer: state.formDetails.manufacturer,
              technology: state.formDetails.technology,
              baseline_version: '',
              software_version: '',
              hardware_type: '',
            },
          },
        }
      }, {})
      dispatch({ type: 'ADD_COLUMNS_EQUIVALENCE', payload })
    },
    [state.formDetails.manufacturer, state.formDetails.technology]
  )

  const selectBaseline = useCallback(payload => {
    dispatch({ type: 'SELECT_BASELINE', payload })
  }, [])

  const setColumnValue = useCallback(payload => {
    dispatch({ type: 'SET_COLUMN_VALUE', payload })
  }, [])

  const setBaselineDetails = useCallback(payload => {
    dispatch({ type: 'SET_BASELINE_DETAILS', payload })
  }, [])

  const toggleImport = useCallback(() => {
    dispatch({ type: 'TOGGLE_IMPORT' })
  }, [])

  const contextValue = useMemo(
    () => ({
      ...state,
      baselineDetails,
      resetToInitialState,
      toggleValidate,
      uploadFile,
      setFormDetails,
      addColumnsEquivalence,
      selectBaseline,
      setColumnValue,
      setBaselineDetails,
      toggleImport,
    }),
    [
      state,
      baselineDetails,
      resetToInitialState,
      toggleValidate,
      uploadFile,
      setFormDetails,
      addColumnsEquivalence,
      selectBaseline,
      setColumnValue,
      setBaselineDetails,
      toggleImport,
    ]
  )

  useEffect(() => {
    if (state.columnsEquivalence && !state.selectedBaseline) {
      dispatch({ type: 'SELECT_BASELINE', payload: Object.keys(state.columnsEquivalence)[0] })
    }
  }, [state.selectedBaseline, state.columnsEquivalence])

  return <AdvancedBulkLoadContext.Provider value={contextValue}>{children}</AdvancedBulkLoadContext.Provider>
}
