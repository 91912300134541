import styled from '@emotion/styled'

export const StyledBackground = styled.div``

const backgroundStyles = {
  height: '100vh',
  background: 'linear-gradient(180deg, rgba(49,39,131,1) 17%, rgba(248,15,82,1) 65%, rgba(255,255,255,1) 92%)',
  display: 'flex',
  flexDirection: 'column',
}

export const styles = {
  background: backgroundStyles,
}
