import styled from '@emotion/styled'
import { Input } from 'reactstrap'
import Select from 'react-select'

export const StyledInput = styled(Input)``

export const StyledSelect = styled(Select)``

export const StyledButtonWrapper = styled.div``

const inputStyles = {
  marginBottom: '30px',
  height: '37px',
  '@media(min-width: 1744px)': {
    width: '27rem',
  },
}

const selectStyles = {
  '@media(min-width: 1744px)': {
    width: '27rem',
  },
}

const buttonWrapperStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: 10,
  marginTop: 'auto',
}

const secondaryActionButtonStyles = {
  marginRight: 'auto',
}

export const styles = {
  input: inputStyles,
  select: selectStyles,
  buttonWrapper: buttonWrapperStyles,
  secondaryActionButton: secondaryActionButtonStyles,
}
