/** @jsxImportSource @emotion/react */
import { useMemo } from 'react'
import { Label } from 'reactstrap'
import { sql } from '@codemirror/lang-sql'
import { Button } from 'components'
import { EDITOR_ROLE_PRIORITY } from 'utils/constants'
import { useKeywordFormController } from './KeywordForm.controller'
import {
  StyledForm,
  StyledInput,
  StyledCodeMirror,
  StyledTextarea,
  StyledButtonWrapper,
  styles,
} from './KeywordForm.styles'

export const KeywordForm = () => {
  const {
    name,
    definition,
    description,
    dataType,
    location,
    searchKey,
    isDefaultMode,
    isEditMode,
    isCreationMode,
    isPrimaryButtonDisabled,
    isLoading,
    isReadOnly,
    setDefinition,
    handleChange,
    handleCancel,
    handlePrimaryButton,
  } = useKeywordFormController()

  const primaryButtonText = useMemo(() => {
    if (isDefaultMode) return 'Add'
    if (isEditMode) return 'Save'
    return 'Create'
  }, [isDefaultMode, isEditMode])

  return (
    <>
      <StyledForm css={styles.form}>
        <Label>Definition</Label>
        <StyledCodeMirror
          extensions={[sql()]}
          height="250px"
          css={styles.codeMirror}
          placeholder="Definition"
          value={definition}
          onChange={setDefinition}
          editable={!isDefaultMode}
          readOnly={isReadOnly}
        />
        <Label>Description</Label>
        <StyledTextarea
          css={styles.textarea}
          placeholder="Description"
          name="description"
          value={description}
          onChange={handleChange}
          disabled={isReadOnly || isDefaultMode}
        />
        <Label>Data type</Label>
        <StyledInput
          css={styles.input}
          placeholder="Data type"
          name="data-type"
          value={dataType}
          onChange={handleChange}
          disabled={isReadOnly || isDefaultMode}
        />
        <Label>Location</Label>
        <StyledInput
          css={styles.input}
          placeholder="Location"
          name="location"
          value={location}
          onChange={handleChange}
          disabled={isReadOnly || isDefaultMode}
        />
        <Label>Search key</Label>
        <StyledInput
          css={styles.input}
          placeholder="Search key"
          name="search-key"
          value={searchKey}
          onChange={handleChange}
          disabled={isReadOnly || isDefaultMode}
        />
        {isCreationMode && (
          <>
            <Label>Name</Label>
            <StyledInput css={styles.input} placeholder="Name" name="name" value={name} onChange={handleChange} />
          </>
        )}
      </StyledForm>
      <StyledButtonWrapper css={styles.buttonWrapper}>
        {!isDefaultMode && (
          <Button outline isCancelButton isDisabled={isLoading} color="danger" handleClick={handleCancel}>
            Cancel
          </Button>
        )}
        <Button
          handleClick={handlePrimaryButton}
          isDisabled={isReadOnly || isPrimaryButtonDisabled}
          isLoading={isLoading}
          priority={EDITOR_ROLE_PRIORITY}
        >
          {primaryButtonText}
        </Button>
      </StyledButtonWrapper>
    </>
  )
}
