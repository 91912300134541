import styled from '@emotion/styled'
import { Col } from 'reactstrap'

export const StyledCol = styled(Col)``

const colStyles = (isDeleteMode, transition) => {
  const partialStyles = transition
    ? {
        width: isDeleteMode ? 0 : '33%',
        height: isDeleteMode ? 0 : '100%',
        opacity: isDeleteMode ? 0 : 1,
        transition,
      }
    : {
        width: `${isDeleteMode ? '100' : '66'}%`,
        transition: `width 0.5s ease-in-out ${isDeleteMode ? '0.45s' : ''}`,
      }

  return {
    ...partialStyles,
    flex: '0 0 auto',
    '@media (max-width: 1200px)': {
      width: '100%',
    },
  }
}

export const styles = {
  col: colStyles,
}
