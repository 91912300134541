/** @jsxImportSource @emotion/react */
import { FutureTable } from 'futurekit'
import Switch from 'react-bootstrap-switch'
import { EmptyState, ExcelGenerator, Spinner } from 'components'
import { StyledWrapper, StyledCol, StyledSwitchLabel, StyledButton, styles } from './KeywordList.styles'
import { useKeywordListController } from './KeywordList.controller'

export const KeywordList = ({ deleteMode, toggleDeleteMode }) => {
  const {
    keywords,
    isKeywordsLoading,
    selectedRowIds,
    isCreationMode,
    isDisabled,
    selectKeyword,
    handleSelectAll,
    handleDelete,
  } = useKeywordListController({
    deleteMode,
  })

  if (isKeywordsLoading) {
    return <Spinner />
  }
  if (!keywords || !keywords.length) {
    return <EmptyState size="5rem" />
  }
  return (
    <>
      <StyledWrapper css={styles.toolbar}>
        <StyledWrapper css={styles.switchWrapper}>
          <StyledSwitchLabel css={styles.switchLabel}>Delete mode</StyledSwitchLabel>
          <Switch onColor="future" value={deleteMode} onChange={toggleDeleteMode} disabled={isDisabled} />
        </StyledWrapper>
        <StyledButton outline color="danger" handleClick={handleSelectAll} css={styles.button(deleteMode, 10)}>
          Select all
        </StyledButton>
        <StyledButton
          handleClick={handleDelete}
          disabled={isDisabled || (deleteMode && !selectedRowIds.length)}
          css={styles.button(deleteMode)}
        >
          Delete
        </StyledButton>
        <StyledCol css={styles.col}>
          <ExcelGenerator
            collection={keywords}
            columnsToSkip={['keyword_id']}
            excelName="keywords"
            sheetName="keywords"
          />
        </StyledCol>
      </StyledWrapper>
      <FutureTable
        data={keywords}
        filterValue={isCreationMode ? '' : null}
        filterNameReachable="name"
        rowIdKey="keyword_id"
        selectedRowIds={selectedRowIds}
        onRowClick={selectKeyword}
        columnsConfig={{
          isStriped: false,
          selectionLimit: deleteMode ? keywords.length : 1,
          highlightSelection: true,
          autoSelection: true,
          isDisabled: isCreationMode,
          dynamicColumns: true,
          columnsExcluded: ['keyword_id'],
        }}
        toolbarConfig={{
          showFilter: true,
          showOrder: true,
          showRowNumber: true,
        }}
        css={styles.table}
      />
    </>
  )
}
