import { useContext, useState, useEffect, useMemo } from 'react'
import { useQueryClient, useMutation } from '@tanstack/react-query'
import { GlobalContext, RulesContext } from 'context'
import { updateParameter } from 'api'
import { showToast } from 'utils'
import { READ_ONLY_FIELDS, EDITABLE_STATUS } from 'utils/constants'

export const useParameterFormController = () => {
  const queryClient = useQueryClient()
  const {
    selectedBaseline,
    selectedStatus,
    selectedParameters,
    selectedCell,
    removeAllParameters,
    userIsOwnerForSelectedBaseline,
  } = useContext(RulesContext)
  const { isUserRoleEqualOrGreaterThanEditor, isUserRoleEqualOrGreaterThanAdvanced } = useContext(GlobalContext)

  const [field, setField] = useState(null)
  const [comment, setComment] = useState('')

  const isReadOnly = useMemo(() => {
    if (READ_ONLY_FIELDS.includes(field?.id) || !EDITABLE_STATUS.includes(selectedStatus?.name)) {
      return true
    }
    if (isUserRoleEqualOrGreaterThanAdvanced) {
      return false
    }
    return isUserRoleEqualOrGreaterThanEditor && userIsOwnerForSelectedBaseline
  }, [
    field?.id,
    isUserRoleEqualOrGreaterThanAdvanced,
    isUserRoleEqualOrGreaterThanEditor,
    selectedStatus?.name,
    userIsOwnerForSelectedBaseline,
  ])

  const cleanForm = () => {
    removeAllParameters()
    setTimeout(() => {
      setField(null)
    }, 1000)
  }

  const { mutate: update, isLoading: isMutationLoading } = useMutation(updateParameter, {
    onSuccess: response => {
      queryClient.refetchQueries(['parameters', selectedBaseline?.value])
      queryClient.refetchQueries(['baselineHistory', selectedBaseline?.value])
      queryClient.refetchQueries(['baselineHistoryFilter', selectedBaseline?.value])
      cleanForm()
      showToast(response.message)
    },
  })

  const handleFieldChange = event => setField({ ...field, value: event.target.value })

  const handleCommentChange = event => setComment(event.target.value)

  const handlePrimaryButton = () => {
    update({
      ruleId: selectedParameters[0].rule_id,
      [field.id]: field.value,
      comments: comment,
    })
  }

  useEffect(() => {
    if (selectedCell) {
      setField(selectedCell)
      setComment('')
    }
  }, [selectedCell])

  return {
    field,
    comment,
    isReadOnly,
    isMutationLoading,
    handleFieldChange,
    handleCommentChange,
    handlePrimaryButton,
    handleCancel: cleanForm,
  }
}
