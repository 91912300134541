import { useState, useMemo, useCallback, useEffect, useContext } from 'react'
import { GlobalContext, RulesContext } from 'context'
import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query'
import { getKeywordsByParameter, getRuleDetails, validateParameter, saveValidation } from 'api'
import { showToast } from 'utils'
import { EDITABLE_STATUS } from 'utils/constants'

const UNDEFINED_VALUE = 'UNDEFINED'

export const useRuleDetailsController = ({ selectedParameter }) => {
  const queryClient = useQueryClient()
  const [telefonicaValue, setTelefonicaValue] = useState('')
  const [rule, setRule] = useState('')

  const { selectedBaseline, selectedStatus, userIsOwnerForSelectedBaseline, addKeywordFilter, removeParameter } =
    useContext(RulesContext)
  const { isUserRoleEqualOrGreaterThanAdvanced, isUserRoleEqualOrGreaterThanEditor } = useContext(GlobalContext)

  const { data: details } = useQuery(['parameter', selectedParameter?.rule_id], getRuleDetails, {
    enabled: !!selectedParameter,
  })

  const pseudocode = useMemo(() => {
    if (!selectedParameter) {
      return ''
    }
    return (details && (details.error || details.pseudocode)) || ''
  }, [details, selectedParameter])

  const isPseudocodeError = useMemo(() => {
    if (!selectedParameter) {
      return false
    }
    return details && !!details.error
  }, [details, selectedParameter])

  const {
    data: keywords,
    remove: removeKeywords,
    refetch: refetchKeywords,
    isLoading: isKeywordsLoading,
    fetchStatus: fetchKeywordsStatus,
  } = useQuery(['keywords', selectedParameter?.rule_id], getKeywordsByParameter, {
    enabled: !!selectedParameter,
  })

  const {
    mutate: validate,
    reset: resetValidation,
    data: validationResponse,
    isLoading: isValidationLoading,
  } = useMutation(validateParameter, {
    onSuccess: () => {
      removeKeywords()
      queryClient.setQueryData(['parameter', selectedParameter?.rule_id], {
        ...details,
        telefonica_value: telefonicaValue,
        regla: rule,
        pseudocode: null,
        error: null,
      })
      queryClient.setQueryData(['parameters', selectedBaseline?.value], data => {
        return data.map(element => {
          if (element.rule_id === selectedParameter?.rule_id) {
            return { ...element, telefonica_value: telefonicaValue, regla: rule, verify: UNDEFINED_VALUE }
          }
          return element
        })
      })
      queryClient.refetchQueries(['parameterFilter', selectedBaseline?.value])
    },
  })

  const { mutate: save } = useMutation(saveValidation, {
    onMutate: () => {
      resetValidation()
    },
    onSuccess: (response, variables) => {
      queryClient.setQueryData(['parameter', selectedParameter?.rule_id], {
        ...details,
        regla: rule,
        pseudocode: variables.pseudocode,
        error: variables.error_message,
      })
      refetchKeywords()
      queryClient.refetchQueries({ queryKey: ['parameters', selectedBaseline?.value], type: 'active', exact: true })
      queryClient.refetchQueries(['parameterFilter', selectedBaseline?.value])
      queryClient.refetchQueries({
        queryKey: ['keywordsByBaseline', selectedBaseline?.value],
        type: 'active',
        exact: true,
      })
      showToast(response.message)
    },
  })

  const keywordsData = useMemo(() => {
    if (!keywords || isKeywordsLoading) {
      return []
    }
    return keywords.map(field => ({
      id: field.keyword_id,
      data: [field.name],
    }))
  }, [keywords, isKeywordsLoading])

  const isReadOnly = useMemo(() => {
    if (!EDITABLE_STATUS.includes(selectedStatus?.name)) {
      return true
    }
    if (isUserRoleEqualOrGreaterThanAdvanced) {
      return false
    }
    return isUserRoleEqualOrGreaterThanEditor && userIsOwnerForSelectedBaseline
  }, [
    isUserRoleEqualOrGreaterThanAdvanced,
    isUserRoleEqualOrGreaterThanEditor,
    selectedStatus?.name,
    userIsOwnerForSelectedBaseline,
  ])

  const handleKeywordTableRowClick = useCallback(
    element => {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
      addKeywordFilter(element.data[0])
    },
    [addKeywordFilter]
  )

  const handleTelefonicaValueChange = event => setTelefonicaValue(event.target.value)

  const handleRuleChange = event => setRule(event.target.value)

  const handleValidate = () =>
    validate({
      ruleId: selectedParameter?.rule_id,
      telefonicaValue,
      rule,
    })

  const handleSaveValidation = useCallback(
    () =>
      save({
        ruleId: selectedParameter?.rule_id,
        ...validationResponse,
      }),
    [save, validationResponse, selectedParameter]
  )

  const handleRemove = () => removeParameter(selectedParameter.rule_id)

  useEffect(() => {
    if (details) {
      setTelefonicaValue(details.telefonica_value || '')
      setRule(details.regla || '')
    }
  }, [details])

  useEffect(() => {
    if (!selectedParameter) {
      setTelefonicaValue('')
      setRule('')
    }
  }, [selectedParameter])

  return {
    keywordsData,
    isKeywordsLoading: isKeywordsLoading || fetchKeywordsStatus === 'fetching',
    isValidationLoading,
    validationResponse,
    telefonicaValue,
    rule,
    pseudocodeValue: pseudocode,
    isPseudocodeError,
    isReadOnly,
    handleRemove,
    handleKeywordTableRowClick,
    handleTelefonicaValueChange,
    handleRuleChange,
    handleValidate,
    handleSaveValidation,
    resetValidation,
  }
}
