import { createContext, useReducer, useCallback, useMemo } from 'react'
import { CRUDManagementReducer } from './CRUDManagementReducer'

export const CRUDManagementContext = createContext({})

const CRUD_MANAGEMENT_INITIAL_STATE = {
  selected: null,
  mode: 'default',
}

export const CRUDManagementProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CRUDManagementReducer, CRUD_MANAGEMENT_INITIAL_STATE)

  const select = useCallback(payload => {
    dispatch({ type: 'SET_SELECTED', payload })
  }, [])

  const deleteSelected = useCallback(() => {
    dispatch({ type: 'SET_SELECTED', payload: null })
  }, [])

  const activateCreationMode = useCallback(() => {
    dispatch({ type: 'SET_MODE', payload: 'create' })
  }, [])

  const contextValue = useMemo(
    () => ({ ...state, select, deleteSelected, activateCreationMode }),
    [state, select, deleteSelected, activateCreationMode]
  )

  return <CRUDManagementContext.Provider value={contextValue}>{children}</CRUDManagementContext.Provider>
}
