import { useCallback, useContext } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { CRUDManagementContext } from 'context'
import { cloneBaseline as cloneBaselineApi, validateBaseline as validateBaselineApi } from 'api'
import { showPrompt, showToast } from 'utils'

export const useToolbarController = () => {
  const queryClient = useQueryClient()
  const { selected } = useContext(CRUDManagementContext)

  const { mutateAsync: clone } = useMutation(cloneBaselineApi, {
    onSuccess: response => {
      queryClient.refetchQueries(['baselines'])
      showToast(response.message)
    },
  })

  const { mutateAsync: validate } = useMutation(validateBaselineApi, {
    onSuccess: response => {
      showToast(response.message)
    },
  })

  const cloneBaseline = useCallback(async () => {
    try {
      await clone({
        baselineId: selected.baseline_id,
        baseline_version: `${selected.baseline_version}-copy`,
        software_version: `${selected.software_version}-copy`,
        technology: selected.technology,
        manufacturer: selected.manufacturer,
        hardware_type: selected.hardware_type,
      })
    } catch (error) {
      // error handled on api interceptor
    }
  }, [clone, selected])

  const validateBaseline = useCallback(async () => {
    try {
      await validate({ baselineId: selected.baseline_id })
    } catch (error) {
      // error handled on api interceptor
    }
  }, [validate, selected])

  const handleClone = () =>
    showPrompt({
      title: 'Are you sure you want to clone the baseline?',
      confirmButtonText: 'Yes, clone',
      callbackAsyncFn: cloneBaseline,
    })

  const handleValidate = () =>
    showPrompt({
      title: 'Are you sure you want to validate the baseline?',
      confirmButtonText: 'Yes, validate',
      callbackAsyncFn: validateBaseline,
    })

  return {
    buttonsDisabled: !selected,
    handleClone,
    handleValidate,
  }
}
