/** @jsxImportSource @emotion/react */
import { StyledWrapper, StyledSpinner, styles } from './Spinner.styles'

export const Spinner = ({ color, height, size }) => {
  return (
    <StyledWrapper css={styles.wrapper(height)}>
      <StyledSpinner color={color || 'primary'} css={styles.inner(size)} />
    </StyledWrapper>
  )
}
