import { useCallback, useContext, useState } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getBaselineHistoryByBaseline, getBaselineHistoryFilterByBaseline } from 'api'
import { GlobalContext, RulesContext } from 'context'

export const useHistoryController = () => {
  const queryClient = useQueryClient()

  const { selectedBaseline, removeAllParameters } = useContext(RulesContext)
  const { isUserRoleEqualOrGreaterThanAdvanced } = useContext(GlobalContext)

  const [selected, setSelected] = useState(null)

  const { data, isLoading, fetchStatus, refetch } = useQuery(
    ['baselineHistory', selectedBaseline?.value],
    getBaselineHistoryByBaseline,
    {
      enabled: !!selectedBaseline,
    }
  )

  const {
    data: filter,
    isLoading: isFilterLoading,
    fetchStatus: fetchFilterStatus,
  } = useQuery(['baselineHistoryFilter', selectedBaseline?.value], getBaselineHistoryFilterByBaseline, {
    enabled: !!selectedBaseline,
  })

  const handleRowClick = useCallback(value => {
    setSelected(value)
  }, [])

  const handleRefetch = useCallback(() => {
    removeAllParameters()
    refetch()
    queryClient.refetchQueries(['parameters', selectedBaseline?.value])
  }, [queryClient, refetch, removeAllParameters, selectedBaseline?.value])

  return {
    data,
    filter,
    isDisabled: !isUserRoleEqualOrGreaterThanAdvanced,
    isLoading:
      !!selectedBaseline &&
      (isLoading || fetchStatus === 'fetching' || isFilterLoading || fetchFilterStatus === 'fetching'),
    selected,
    handleRowClick,
    handleRefetch,
  }
}
