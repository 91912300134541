import { createContext, useReducer, useCallback, useMemo } from 'react'
import { removeToken } from 'utils'
import { EDITOR_ROLE_NAME, ADVANCED_ROLE_NAME, ADMIN_ROLE_NAME } from 'utils/constants'
import { globalReducer } from './GlobalReducer'

export const GlobalContext = createContext({})

const GLOBAL_INITIAL_STATE = {
  user: {},
  roles: {},
  highestPriority: 0,
  isUserRoleEqualOrGreaterThanEditor: false,
  isUserRoleEqualOrGreaterThanAdvanced: false,
  isUserAdmin: false,
}

export const GlobalProvider = ({ user = {}, roles = [], children }) => {
  const { roles: rolesObj, priority } = roles.reduce(
    (prev, next) => {
      return {
        roles: { ...prev.roles, [next.role_id]: { ...next } },
        priority: prev.priority < next.priority ? next.priority : prev.priority,
      }
    },
    { roles: {}, priority: 0 }
  )

  const userRole = rolesObj[user.role_id]
  const editorRole = roles.find(r => r.name === EDITOR_ROLE_NAME)
  const advancedRole = roles.find(r => r.name === ADVANCED_ROLE_NAME)

  const [state, dispatch] = useReducer(globalReducer, {
    ...GLOBAL_INITIAL_STATE,
    user,
    roles: rolesObj,
    highestPriority: priority,
    editorRole,
    advancedRole,
    isUserRoleEqualOrGreaterThanEditor: userRole && editorRole ? userRole.priority >= editorRole.priority : false,
    isUserRoleEqualOrGreaterThanAdvanced: userRole && advancedRole ? userRole.priority >= advancedRole.priority : false,
    isUserAdmin: userRole ? userRole.name === ADMIN_ROLE_NAME : false,
  })

  const logOut = useCallback(() => {
    dispatch({ type: 'REMOVE_USER' })
    removeToken()
    window.location = '/login'
  }, [])

  const getUserRole = useCallback(() => {
    if (!state.roles || !state.user) return null
    return state.roles[state.user.role_id]
  }, [state.roles, state.user])

  const contextValue = useMemo(() => ({ ...state, logOut, getUserRole }), [state, logOut, getUserRole])

  return <GlobalContext.Provider value={contextValue}>{children}</GlobalContext.Provider>
}
