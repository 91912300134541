import { useMemo, useContext } from 'react'
import { RulesContext, GlobalContext } from 'context'
import { EDITABLE_STATUS } from 'utils/constants'

export const useRuleValidationController = () => {
  const {
    selectedBaseline,
    selectedStatus,
    selectedParameters,
    selectBaseline,
    selectParameter,
    selectCell,
    addParameter,
    overloadParameter,
    userIsOwnerForSelectedBaseline,
  } = useContext(RulesContext)
  const { isUserRoleEqualOrGreaterThanEditor, isUserRoleEqualOrGreaterThanAdvanced } = useContext(GlobalContext)

  const isReadOnly = useMemo(() => {
    if (!EDITABLE_STATUS.includes(selectedStatus?.name)) {
      return true
    }
    if (isUserRoleEqualOrGreaterThanAdvanced) {
      return false
    }
    return isUserRoleEqualOrGreaterThanEditor && userIsOwnerForSelectedBaseline
  }, [
    isUserRoleEqualOrGreaterThanAdvanced,
    isUserRoleEqualOrGreaterThanEditor,
    selectedStatus?.name,
    userIsOwnerForSelectedBaseline,
  ])

  const [firstParameter, secondParameter] = selectedParameters

  return {
    selectedBaseline,
    selectedParameters,
    firstParameter,
    secondParameter,
    selectBaseline,
    selectParameter,
    selectCell,
    addParameter,
    overloadParameter,
    isReadOnly,
  }
}
