/** @jsxImportSource @emotion/react */
import { useCallback, useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { createBaseline as createBaselineApi, uploadBaselineFile } from 'api'
import { showPrompt, showResponseAlert } from 'utils'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { v4 as uuidv4 } from 'uuid'
import { styles } from './Baseline.styles'

const INITIAL_FORM = {
  baseline_version: '',
  software_version: '',
  technology: '',
  manufacturer: '',
  hardware_type: '',
}

export const useBaselineController = () => {
  const [form, setForm] = useState(INITIAL_FORM)
  const [file, setFile] = useState(null)
  const [validate, setValidate] = useState(true)

  const history = useHistory()

  const showAlert = useCallback(
    response => {
      const body = (
        <div css={styles.alertBody}>
          {response.data.map(baselineRes => (
            <div key={uuidv4()}>
              <div key={uuidv4()}>{baselineRes.baseline}</div>
              {baselineRes.message.split('\n').map(el => (
                <p key={uuidv4()}>{el}</p>
              ))}
            </div>
          ))}
        </div>
      )

      showResponseAlert({
        title: response.message,
        icon: response.status,
        body,
        onClose: () => history.push('/rules/validation'),
      })
    },
    [history]
  )

  const { mutateAsync: createBaseline, isLoading: createBaselineIsLoading } = useMutation(createBaselineApi)

  const { mutateAsync: uploadBaseline, isLoading: loadExcelIsLoading } = useMutation(uploadBaselineFile, {
    onSuccess: showAlert,
  })

  const handleUpload = async () => {
    try {
      const response = await createBaseline(form)
      await uploadBaseline({ baselineId: response.baseline_id, file, validate })
    } catch (error) {
      // error handled on api interceptor
    }
  }

  const handleSave = () => {
    showPrompt({
      title: 'Are you sure you want to upload the baseline?',
      callbackAsyncFn: handleUpload,
    })
  }

  const handleChange = useCallback(
    event => {
      const { name, value } = event.target
      setForm({ ...form, [name]: value })
    },
    [form, setForm]
  )
  const toggleValidate = useCallback(() => {
    setValidate(prevState => !prevState)
  }, [])

  return {
    form,
    validate,
    disabled: !file || Object.values(form).some(field => !field),
    isLoading: createBaselineIsLoading || loadExcelIsLoading,
    handleSave,
    handleChange,
    toggleValidate,
    setFile,
  }
}
