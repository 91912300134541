/** @jsxImportSource @emotion/react */
import { OverlayTrigger } from 'react-bootstrap'
import { useToolbarController } from './Toolbar.controller'
import { StyledButton, StyledIcon, StyledToolbar, StyledTooltip, styles } from './Toolbar.styles'

const TooltipTrigger = ({ text, children }) => {
  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 250 }}
      overlay={<StyledTooltip css={styles.tooltip}>{text}</StyledTooltip>}
    >
      {children}
    </OverlayTrigger>
  )
}

export const Toolbar = () => {
  const { buttonsDisabled, handleClone, handleValidate } = useToolbarController()

  return (
    <StyledToolbar css={styles.toolbar}>
      <StyledButton css={styles.toolbarButton} disabled={buttonsDisabled} onClick={handleClone} color="link">
        <TooltipTrigger text="Clone Baseline">
          <StyledIcon css={styles.toolbarIcon} className="now-ui-icons files_single-copy-04" />
        </TooltipTrigger>
      </StyledButton>
      <StyledButton css={styles.toolbarButton} disabled={buttonsDisabled} onClick={handleValidate} color="link">
        <TooltipTrigger text="Validate Baseline">
          <StyledIcon css={styles.toolbarIcon} className="now-ui-icons ui-1_check" />
        </TooltipTrigger>
      </StyledButton>
    </StyledToolbar>
  )
}
