import styled from '@emotion/styled'

export const StyledTableHead = styled.thead``

const tableHeadStyles = filterable => ({
  display: 'flex',
  flex: '1 0 auto',
  flexDirection: 'column',
  position: 'sticky',
  top: 0,
  zIndex: 1,
  marginBottom: filterable ? '2rem' : 0,
  userSelect: 'none',
  textAlign: 'center',
})

export const styles = {
  tableHead: tableHeadStyles,
}
