import { AdvancedBulkLoadContext, GlobalContext } from 'context'
import { useState, useContext, useEffect } from 'react'

export const useAdvancedBulkLoadController = () => {
  const { editorRole } = useContext(GlobalContext)
  const { step } = useContext(AdvancedBulkLoadContext)

  const [transition, setTransition] = useState()

  useEffect(() => {
    const wTransTiming = step === 1 ? 'ease-in-out' : 'linear'
    const wTransDelay = step === 1 ? '0.9s' : '0.4s'
    const oTransDelay = step === 1 ? '0.35s' : '0.35s'

    setTransition(
      `max-width 0s ${wTransTiming} ${wTransDelay}, max-height 0s ${wTransTiming} ${wTransDelay}, opacity 0.4s ease-in ${oTransDelay}`
    )
  }, [step])

  return {
    editorRole,
    transition,
    isFirstStep: step === 1,
    detailsCardHeight: step === 1 ? 'responsive' : 'default',
  }
}
