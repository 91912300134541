/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types'
import { FutureTable } from 'futurekit'
import { ADVANCED_ROLE_PRIORITY } from 'utils/constants'
import { Spinner } from '../Spinner'
import { EmptyState } from '../EmptyState'
import { Button } from '../Button'
import { ExcelGenerator } from '../ExcelGenerator'
import { StyledToolbar, styles } from './BaselineHistory.styles'
import { useBaselineHistoryController } from './BaselineHistory.controller'

const BaselineHistory = ({
  data,
  filter,
  isLoading,
  isButtonDisabled,
  showRestoreButton,
  exportConfig,
  useAdvancedFilter,
  selected,
  handleRowClick,
  refetch,
}) => {
  const { excelData, selectedRowIds, handleRestore, handleRowsChange } = useBaselineHistoryController({
    selected,
    refetch,
  })

  if (isLoading) {
    return <Spinner />
  }
  if (!data || !data.length || (useAdvancedFilter && (!filter || !Object.keys(filter).length))) {
    return <EmptyState size="5rem" />
  }
  return (
    <>
      <StyledToolbar css={styles.toolbar}>
        {showRestoreButton && (
          <Button
            isDisabled={!selected || isButtonDisabled}
            handleClick={handleRestore}
            priority={ADVANCED_ROLE_PRIORITY}
          >
            Restore
          </Button>
        )}
        <ExcelGenerator
          collection={excelData}
          columnsToSkip={exportConfig.columnsToSkip}
          excelName={exportConfig.excelName}
          sheetName={exportConfig.sheetName}
        />
      </StyledToolbar>
      <FutureTable
        data={data}
        globalFilter={filter}
        selectedRowIds={selectedRowIds}
        onRowClick={handleRowClick}
        onRowsChange={handleRowsChange}
        columnsConfig={{
          isStriped: false,
          highlightSelection: true,
          autoSelection: true,
          dynamicColumns: true,
          advancedFilter: useAdvancedFilter,
          columnsExcluded: ['rule_id'],
        }}
        toolbarConfig={{
          showFilter: true,
          showOrder: true,
          showRowNumber: true,
        }}
        css={styles.table}
      />
    </>
  )
}

BaselineHistory.defaultProps = {
  data: [],
  filter: null,
  isButtonDisabled: false,
  showRestoreButton: true,
  useAdvancedFilter: false,
  selected: null,
  refetch: null,
}

BaselineHistory.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  filter: PropTypes.shape({}),
  isLoading: PropTypes.bool.isRequired,
  isButtonDisabled: PropTypes.bool,
  showRestoreButton: PropTypes.bool,
  exportConfig: PropTypes.shape({
    columnsToSkip: PropTypes.arrayOf(PropTypes.string),
    excelName: PropTypes.string,
    sheetName: PropTypes.string,
  }).isRequired,
  useAdvancedFilter: PropTypes.bool,
  selected: PropTypes.shape({}),
  handleRowClick: PropTypes.func.isRequired,
  refetch: PropTypes.func,
}

export { BaselineHistory }
