import styled from '@emotion/styled'
import { Input } from 'reactstrap'

export const StyledForm = styled.div``

export const StyledInput = styled(Input)``

export const StyledButtonWrapper = styled.div``

const formStyles = {
  overflowY: 'auto',
  paddingRight: '0.5rem',
}

const inputStyles = {
  marginBottom: '30px',
  height: '37px',

  '@media(min-width: 1744px)': {
    width: '27rem',
  },
}

const buttonWrapperStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: 10,
  marginTop: 'auto',
}

export const styles = {
  form: formStyles,
  input: inputStyles,
  buttonWrapper: buttonWrapperStyles,
}
