/** @jsxImportSource @emotion/react */
import { Col } from 'reactstrap'
import { Card, ParameterSelector } from 'components'
import { RulesProvider } from 'context'
import RuleDetails from './components/RuleDetails'
import KeywordTable from './components/KeywordTable'
import { useRuleValidationController } from './RuleValidation.controller'

const RuleValidationState = ({ children }) => {
  return <RulesProvider maxNumberSelectedParameters={2}>{children}</RulesProvider>
}

const RuleValidationContent = () => {
  const {
    selectedBaseline,
    selectedParameters,
    firstParameter,
    secondParameter,
    selectBaseline,
    selectParameter,
    selectCell,
    addParameter,
    overloadParameter,
    isReadOnly,
  } = useRuleValidationController()

  return (
    <>
      <Col xs={12}>
        <Card title="Parameter selector" cardHeight={{ largeDesktop: 'minimized' }} enableAccordionBehavior>
          <ParameterSelector
            hideMainVerticalScroll
            selectedBaseline={selectedBaseline}
            selectedParameters={selectedParameters}
            selectionLimit={2}
            selectBaseline={selectBaseline}
            selectParameter={selectParameter}
            selectCell={selectCell}
            addParameter={addParameter}
            overloadSelectParameter={overloadParameter}
            isSwitchColumnReadOnly={isReadOnly}
          />
        </Card>
      </Col>
      <Col xs={12}>
        <RuleDetails selectedParameter={firstParameter} alwaysVisible />
      </Col>
      {secondParameter && (
        <Col xs={12}>
          <RuleDetails selectedParameter={secondParameter} />
        </Col>
      )}
      <Col xs={12}>
        <Card title="Keyword table" cardHeight={{ largeDesktop: 'minimized' }}>
          <KeywordTable />
        </Card>
      </Col>
    </>
  )
}

export const RuleValidation = () => {
  return (
    <RuleValidationState>
      <RuleValidationContent />
    </RuleValidationState>
  )
}
