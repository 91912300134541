/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types'
import { useCardController } from './Card.controller'
import {
  StyledCard,
  StyledHeader,
  StyledCardTitle,
  StyledButton,
  StyledIcon,
  StyledCollapse,
  StyledCardBody,
  styles,
} from './Card.styles'

const Card = ({
  cardHeight,
  title,
  priority,
  enableAccordionBehavior,
  enableCloseBehavior,
  alwaysVisible,
  onClose,
  children,
}) => {
  const { isVisible, isAccordionOpen, overriddenCardHeight, handleClose, toggleAccordionVisibility } =
    useCardController({
      cardHeight,
      priority,
      onClose,
      alwaysVisible,
    })

  if (!isVisible) {
    return null
  }

  return (
    <StyledCard css={styles.card(isAccordionOpen, overriddenCardHeight)}>
      <StyledHeader css={styles.header}>
        {enableCloseBehavior && (
          <StyledButton css={styles.closeButton} color="link" onClick={handleClose}>
            <StyledIcon css={styles.icon} className="fas fa-times" />
          </StyledButton>
        )}
        <StyledCardTitle tag="h4" css={styles.title}>
          {title}
        </StyledCardTitle>
        {enableAccordionBehavior && (
          <StyledButton css={styles.accordionButton} color="link" onClick={toggleAccordionVisibility}>
            <StyledIcon css={styles.icon} className={`fas fa-chevron-${isAccordionOpen ? 'up' : 'down'}`} />
          </StyledButton>
        )}
      </StyledHeader>
      <StyledCollapse isOpen={isAccordionOpen} css={styles.collapse(isAccordionOpen, overriddenCardHeight)}>
        <StyledCardBody css={styles.cardBody}>{children}</StyledCardBody>
      </StyledCollapse>
    </StyledCard>
  )
}

Card.defaultProps = {
  cardHeight: {
    largeDesktop: 'default',
    desktop: 'default',
  },
  title: '',
  priority: 1,
  enableAccordionBehavior: false,
  enableCloseBehavior: false,
  alwaysVisible: false,
  onClose: null,
}

Card.propTypes = {
  cardHeight: PropTypes.shape({
    largeDesktop: PropTypes.oneOf(['default', 'minimized', 'responsive']),
    desktop: PropTypes.oneOf(['default', 'responsive']),
  }),
  title: PropTypes.string,
  priority: PropTypes.number,
  enableAccordionBehavior: PropTypes.bool,
  enableCloseBehavior: PropTypes.bool,
  alwaysVisible: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export { Card }
