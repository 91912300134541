import { useState, useCallback, useEffect } from 'react'

export const useKeywordEditorController = () => {
  const [transition, setTransition] = useState()
  const [deleteMode, setDeleteMode] = useState(false)

  const toggleDeleteMode = useCallback(() => {
    setDeleteMode(prevState => !prevState)
  }, [])

  useEffect(() => {
    const wTransTiming = deleteMode ? 'linear' : 'ease-in-out'
    const wTransDelay = deleteMode ? '0.5s' : '0.1s'
    const oTransDelay = deleteMode ? '' : '0.45s'

    setTransition(`width 0s ${wTransTiming} ${wTransDelay}, opacity 0.5s ease-in ${oTransDelay}`)
  }, [deleteMode])

  return {
    transition,
    deleteMode,
    toggleDeleteMode,
  }
}
