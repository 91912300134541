import styled from '@emotion/styled'
import { Label, Input, Row, Col } from 'reactstrap'

export const StyledRow = styled(Row)``

export const StyledCol = styled(Col)``

export const StyledSwitchWrapper = styled.div``

export const StyledSwitchLabel = styled(Label)``

export const StyledInput = styled(Input)``

export const StyledButtonWrapper = styled.div``

const rowStyles = {
  margin: '0',
  padding: '2rem',
  borderRadius: '10px',
  border: '2px solid #EBECf4',
}

const colStyles = {
  '@media (min-width: 1200px)': {
    width: '50%',
    margin: '2.75rem auto 0',
    paddingLeft: '2rem',
  },
}

const switchWrapperStyles = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '0.75rem',
}

const switchLabelStyles = {
  marginRight: '0.7rem',
}

const inputStyles = {
  marginBottom: '30px',
  height: '37px',
  '@media (min-width: 1200px)': {
    maxWidth: '25rem',
  },
}

const buttonWrapperStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: 10,
  marginTop: 'auto',
}

const alertBodyStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  marginLeft: '1rem',
  maxHeight: '21rem',
  overflowY: 'auto',

  '& div': {
    fontWeight: 700,
    fontSize: '1.15rem',
    marginBottom: '0.5rem',
  },
  '& p': {
    fontSize: '1rem',
    textAlign: 'left',
  },
}

export const styles = {
  row: rowStyles,
  col: colStyles,
  switchWrapper: switchWrapperStyles,
  switchLabel: switchLabelStyles,
  input: inputStyles,
  buttonWrapper: buttonWrapperStyles,
  alertBody: alertBodyStyles,
}
