import { useMemo, useState } from 'react'
import { saveAs } from 'file-saver'
import Excel from 'exceljs'
import { createLabelFromApiField } from 'utils'

export const useExcelGeneratorController = ({ collection, columnsToSkip, excelName, sheetName }) => {
  const [isLoading, setIsLoading] = useState(false)

  const columns = useMemo(() => {
    if (!collection || !collection.length || !collection[0]) {
      return []
    }
    return Object.keys(collection[0]).reduce((prev, next) => {
      if (columnsToSkip.some(col => col === next)) {
        return prev
      }
      return [
        ...prev,
        {
          name: createLabelFromApiField(next),
          key: next,
        },
      ]
    }, [])
  }, [collection, columnsToSkip])

  const handleClick = () => {
    setIsLoading(true)
    const workbook = new Excel.Workbook()
    const worksheet = workbook.addWorksheet(sheetName)

    worksheet.columns = columns.map(column => ({
      header: column.name,
      key: column.key,
    }))
    worksheet.addRows(collection)

    workbook.xlsx
      .writeBuffer()
      .then(data => {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        saveAs(blob, excelName)
      })
      .finally(() => setIsLoading(false))
  }

  return { isLoading, handleClick }
}
