/** @jsxImportSource @emotion/react */
import { useContext, useMemo, useCallback } from 'react'
import { AdvancedBulkLoadContext } from 'context'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useMutation } from '@tanstack/react-query'
import { uploadAdvancedBaselineFile as uploadAdvancedBaselineFileApi } from 'api'
import { createLabelFromApiField, showPrompt, showResponseAlert } from 'utils'
import { v4 as uuidv4 } from 'uuid'
import { styles } from './ParameterAttributeSelector.styles'

export const useParameterAttributeSelectorController = () => {
  const { validate, file, columnsEquivalence, baselineDetails, selectedBaseline, setColumnValue, resetToInitialState } =
    useContext(AdvancedBulkLoadContext)

  const history = useHistory()

  const showAlert = useCallback(
    response => {
      const body = (
        <div css={styles.alertBody}>
          {response.data.map(baselineRes => (
            <div key={uuidv4()}>
              <div key={uuidv4()}>{baselineRes.baseline}</div>
              {baselineRes.message.split('\n').map(el => (
                <p key={uuidv4()}>{el}</p>
              ))}
            </div>
          ))}
        </div>
      )

      showResponseAlert({
        title: response.message,
        icon: response.status,
        body,
        onClose: () => history.push('/rules/validation'),
      })
    },
    [history]
  )

  const { mutateAsync: uploadAdvancedBaselineFile } = useMutation(uploadAdvancedBaselineFileApi, {
    onSuccess: showAlert,
  })

  const selectOptions = useMemo(() => {
    if (!columnsEquivalence || !selectedBaseline) {
      return []
    }
    return (
      columnsEquivalence[selectedBaseline]?.excel_columns?.map(el => ({
        value: el,
        label: createLabelFromApiField(el),
      })) || []
    )
  }, [columnsEquivalence, selectedBaseline])

  const isButtonDisabled = useMemo(() => {
    const isSomeDetailsFieldEmpty = (importBaseline, details) => {
      return importBaseline && Object.values(details).some(field => !field)
    }

    if (!columnsEquivalence || Object.values(columnsEquivalence).every(baseline => !baseline.import)) {
      return true
    }
    return Object.values(columnsEquivalence).some(baseline => {
      return isSomeDetailsFieldEmpty(baseline.import, baseline.baseline)
    })
  }, [columnsEquivalence])

  const handleChange = useCallback(
    (select, event) => {
      setColumnValue({ name: event.name, value: select?.value || null })
    },
    [setColumnValue]
  )

  const uploadAdvancedBaseline = useCallback(async () => {
    try {
      await uploadAdvancedBaselineFile({ validate, file, columnsEquivalence })
    } catch (error) {
      // error handled on api interceptor
    }
  }, [columnsEquivalence, file, uploadAdvancedBaselineFile, validate])

  const handleSave = () => {
    showPrompt({
      title: 'Are you sure you want to upload the baseline?',
      callbackAsyncFn: uploadAdvancedBaseline,
    })
  }

  return {
    isViewHidden: !baselineDetails,
    baselineDetails,
    selectOptions,
    isButtonDisabled,
    handleChange,
    resetToInitialState,
    handleSave,
  }
}
