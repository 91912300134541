import { useContext } from 'react'
import { RulesContext } from 'context'

export const useStatusFilterController = () => {
  const { statusListFiltered, updateStatusList } = useContext(RulesContext)

  return {
    handleCheck: updateStatusList,
    statusListFiltered,
  }
}
