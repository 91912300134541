import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { logIn } from 'api'
import { saveToken } from 'utils'
import { useMutation } from '@tanstack/react-query'

export const useLoginController = () => {
  const history = useHistory()

  const [user, setUser] = useState('')
  const [password, setPassword] = useState('')

  const ENTER_KEY = 'Enter'

  const { mutate } = useMutation(logIn, {
    onSuccess: res => {
      saveToken(res.token)
      history.go('/parameters')
    },
  })

  const handleLogIn = () => {
    mutate({ user, password })
  }

  const handleUserChange = event => {
    setUser(event.target.value)
  }

  const handlePasswordChange = event => {
    setPassword(event.target.value)
  }

  const clearFields = () => {
    setUser('')
    setPassword('')
  }

  const handleKeyDown = e => {
    if (e.key === ENTER_KEY) {
      handleLogIn()
    }
  }

  return {
    user,
    password,
    handleLogIn,
    handleUserChange,
    handlePasswordChange,
    clearFields,
    handleKeyDown,
  }
}
