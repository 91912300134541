/** @jsxImportSource @emotion/react */
import { Label } from 'reactstrap'
import PropTypes from 'prop-types'
import { useFileUploadController } from './FileUpload.controller'
import {
  StyledFileUploadContainer,
  StyledFormField,
  StyledDragDropText,
  StyledUploadFileBtn,
  StyledIcon,
  StyledFileWrapper,
  StyledDeleteContainer,
  styles,
} from './FileUpload.styles'

const FileUpload = ({ label, isMultiple, isDisabled, callbackFn }) => {
  const {
    file,
    containerRef,
    fileInputRef,
    handleButtonClick,
    handleButtonDrop,
    handleDrop,
    handleDragOver,
    handleDragLeave,
    handleIconClick,
  } = useFileUploadController({ isDisabled, callbackFn })

  return (
    <>
      <Label css={styles.inputLabel}>{label}</Label>
      <StyledFileUploadContainer ref={containerRef} data-dragging="false" css={styles.fileUploadContainer}>
        <StyledDragDropText css={styles.dragDropText}>Drag and drop your file or</StyledDragDropText>
        <StyledUploadFileBtn
          type="file"
          onClick={handleButtonClick}
          onDrop={handleButtonDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          disabled={file || isDisabled}
          css={styles.uploadFileBtn}
        >
          <StyledIcon css={styles.styledIcon} className="fas fa-file-upload" />
          <span> Upload {isMultiple ? 'files' : 'a file'}</span>
        </StyledUploadFileBtn>
        <StyledFormField
          type="file"
          value=""
          ref={fileInputRef}
          onChange={handleDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          disabled={file || isDisabled}
          css={styles.formField}
        />
      </StyledFileUploadContainer>
      {file && (
        <StyledFileWrapper css={styles.fileWrapper}>
          <Label>File Name: {file.name}&nbsp;</Label>
          <StyledDeleteContainer aria-hidden="true" onClick={handleIconClick} css={styles.deleteContainer(isDisabled)}>
            <i className="fas fa-times" />
          </StyledDeleteContainer>
        </StyledFileWrapper>
      )}
    </>
  )
}

FileUpload.defaultProps = {
  label: 'Upload File',
  isMultiple: false,
  isDisabled: false,
}

FileUpload.propTypes = {
  label: PropTypes.string,
  isMultiple: PropTypes.bool,
  callbackFn: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
}

export { FileUpload }
