/** @jsxImportSource @emotion/react */
import { FutureTable } from 'futurekit'
import { EmptyState, Spinner } from 'components'
import { useBaselineListController } from './BaselineList.controller'

export const BaselineList = () => {
  const { baselines, isBaselinesLoading, select, selectedRowIds, isCreationMode } = useBaselineListController()

  if (isBaselinesLoading) {
    return <Spinner />
  }
  if (!baselines || !baselines.length) {
    return <EmptyState size="5rem" />
  }
  return (
    <FutureTable
      data={baselines}
      filterValue={isCreationMode ? '' : null}
      filterNameReachable="manufacturer"
      selectedRowIds={selectedRowIds}
      onRowClick={select}
      rowIdKey="baseline_id"
      columnsConfig={{
        isStriped: false,
        highlightSelection: true,
        autoSelection: true,
        dynamicColumns: true,
        columnsExcluded: ['baseline_id', 'owner_id', 'status', 'user_latest'],
        isDisabled: isCreationMode,
      }}
      toolbarConfig={{
        showFilter: true,
        showOrder: true,
        showRowNumber: true,
      }}
    />
  )
}
