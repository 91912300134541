import styled from '@emotion/styled'

export const StyledToolbar = styled.div``
const toolbarStyles = {
  height: '50px',
  marginTop: '-10px',
  marginBottom: '10px',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: '0.5rem',
}

const tableStyles = {
  height: 'calc(100% - 50px)',
}

export const StyledPromptBody = styled.div``
const promptBodyStyles = {
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'left',

  '& b:nth-of-type(2n)': {
    marginTop: '15px',
  },
}

export const styles = {
  toolbar: toolbarStyles,
  table: tableStyles,
  promptBody: promptBodyStyles,
}
