/** @jsxImportSource @emotion/react */
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useDndContainerController } from './DnDContainer.controller'
import { StyledDraggableElement, styles } from './DnDContainer.styles'

const DnDContainer = ({ visibleColumns, reorderVisibleColumns }) => {
  const { items, onDragEnd } = useDndContainerController(visibleColumns, reorderVisibleColumns)

  const renderClone = (provided, { isDragging }, rubric) => (
    <StyledDraggableElement
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      css={styles.draggableElement(isDragging, provided.draggableProps.style)}
    >
      {items[rubric.source.index].label}
    </StyledDraggableElement>
  )

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="future-table-columns" renderClone={renderClone}>
        {droppableProvided => (
          <div
            ref={droppableProvided.innerRef}
            {...droppableProvided.droppableProps}
            css={{ ...droppableProvided.droppableProps.style }}
          >
            {items.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(draggableProvided, { isDragging }) => (
                  <StyledDraggableElement
                    ref={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps}
                    {...draggableProvided.dragHandleProps}
                    css={styles.draggableElement(isDragging, draggableProvided.draggableProps.style)}
                  >
                    {item.label}
                  </StyledDraggableElement>
                )}
              </Draggable>
            ))}
            {droppableProvided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export { DnDContainer }
