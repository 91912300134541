/** @jsxImportSource @emotion/react */
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Label, Input } from 'reactstrap'

const CHANGE_PASSWORD_ALERT_STYLES = {
  container: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& :last-child': {
      marginBottom: '0',
    },
  },
  label: {
    fontWeight: 700,
    marginBottom: 5,
  },
  input: {
    height: 37,
    margin: '0 0 30px',
    '@media(min-width: 1744px)': {
      width: '27rem',
    },
  },
}

const WARNING_DEFAULT_TEXT = 'Something requires your attention'

const SUCCESS_DEFAULT_TEXT = 'Action has been successfully'

const TFSpainSwal = withReactContent(Swal)

const commonOptions = {
  allowEscapeKey: false,
  allowOutsideClick: false,
  allowEnterKey: true,
  buttonsStyling: false,
  reverseButtons: true,
  customClass: {
    confirmButton: 'swal-btn btn btn-lg btn-primary',
    cancelButton: 'swal-btn btn btn-lg btn-outline-primary',
  },
  showClass: {
    popup: '',
  },
  hideClass: {
    popup: '',
  },
}

const showPrompt = ({
  icon = 'question',
  title,
  body,
  confirmButtonText = 'Yes, continue',
  cancelButtonText = 'No, cancel',
  callbackAsyncFn,
  onClose,
}) =>
  TFSpainSwal.fire({
    icon,
    title,
    html: body,
    confirmButtonText,
    cancelButtonText,
    showCancelButton: true,
    ...commonOptions,
    showLoaderOnConfirm: !!callbackAsyncFn,
    ...(callbackAsyncFn && { preConfirm: callbackAsyncFn }),
  }).then(result => onClose && onClose({ isConfirmed: result.isConfirmed }))

const showWarningAlert = ({ title, body, onClose } = {}) =>
  TFSpainSwal.fire({
    ...commonOptions,
    icon: 'warning',
    title: title || 'Warning',
    html: body || WARNING_DEFAULT_TEXT,
  }).then(result => onClose && onClose({ isConfirmed: result.isConfirmed }))

const showSuccessAlert = ({ title, body, onClose } = {}) =>
  TFSpainSwal.fire({
    ...commonOptions,
    icon: 'success',
    title: title || 'Success',
    html: body || SUCCESS_DEFAULT_TEXT,
  }).then(result => onClose && onClose({ isConfirmed: result.isConfirmed }))

const showErrorAlert = ({ title, text } = {}) =>
  TFSpainSwal.fire({
    ...commonOptions,
    icon: 'error',
    title: title || 'Error',
    text: text || 'An unexpected error has occurred',
  })

const showResponseAlert = ({ title, icon, body, onClose } = {}) =>
  TFSpainSwal.fire({
    ...commonOptions,
    icon: icon || 'question',
    title: title || 'Success',
    html: body || SUCCESS_DEFAULT_TEXT,
  }).then(result => onClose && onClose({ isConfirmed: result.isConfirmed }))

const showToast = (title = SUCCESS_DEFAULT_TEXT) =>
  TFSpainSwal.fire({
    toast: true,
    position: 'top-right',
    showClass: {
      popup: '',
    },
    hideClass: {
      popup: '',
    },
    icon: 'success',
    title,
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    didOpen: toast => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    },
  })

const showChangePasswordAlert = ({ callbackAsyncFn, isUserAdmin = false }) => {
  const handleChange = () => {
    const confirmButton = TFSpainSwal.getConfirmButton()
    const currentPasswordValue = isUserAdmin ? null : document.getElementById('currentPassword').value
    const newPasswordValue = document.getElementById('newPassword').value
    const matchedPasswordValue = document.getElementById('matchedPassword').value

    confirmButton.disabled =
      (!isUserAdmin && !currentPasswordValue) ||
      !newPasswordValue ||
      !matchedPasswordValue ||
      newPasswordValue !== matchedPasswordValue
  }

  return TFSpainSwal.fire({
    ...commonOptions,
    icon: 'info',
    title: 'Change Password',
    html: (
      <div css={CHANGE_PASSWORD_ALERT_STYLES.container}>
        {!isUserAdmin && (
          <>
            <Label css={CHANGE_PASSWORD_ALERT_STYLES.label}>Current password</Label>
            <Input type="password" id="currentPassword" css={CHANGE_PASSWORD_ALERT_STYLES.input} />
          </>
        )}
        <Label css={CHANGE_PASSWORD_ALERT_STYLES.label}>New password</Label>
        <Input type="password" id="newPassword" css={CHANGE_PASSWORD_ALERT_STYLES.input} />
        <Label css={CHANGE_PASSWORD_ALERT_STYLES.label}>Repeat password</Label>
        <Input type="password" id="matchedPassword" css={CHANGE_PASSWORD_ALERT_STYLES.input} />
      </div>
    ),
    confirmButtonText: 'Confirm',
    cancelButtonText: 'Cancel',
    showCancelButton: true,
    showLoaderOnConfirm: true,
    didOpen: () => {
      TFSpainSwal.getConfirmButton().disabled = true
      if (!isUserAdmin) {
        document.getElementById('currentPassword').addEventListener('input', handleChange)
      }
      document.getElementById('newPassword').addEventListener('input', handleChange)
      document.getElementById('matchedPassword').addEventListener('input', handleChange)
    },
    preConfirm: callbackAsyncFn,
  })
}

export {
  showPrompt,
  showWarningAlert,
  showSuccessAlert,
  showErrorAlert,
  showResponseAlert,
  showToast,
  showChangePasswordAlert,
}
