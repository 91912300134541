/** @jsxImportSource @emotion/react */
import { useStatusFilterController } from './StatusFilter.controller'
import { styles, StyledSelect } from './StatusFilter.styles'

const StatusFilter = () => {
  const { handleCheck, statusListFiltered } = useStatusFilterController()

  return (
    <StyledSelect
      css={styles.select}
      value={statusListFiltered.filter(status => status.checked)}
      isMulti
      name="status"
      options={statusListFiltered.filter(status => !status.checked)}
      className="react-select primary"
      classNamePrefix="react-select"
      onChange={handleCheck}
      placeholder="Filter by Status"
      styles={styles.custom}
    />
  )
}

export { StatusFilter }
