import ReactDOM from 'react-dom'
import App from 'App'
import 'bootstrap/dist/css/bootstrap.css'
import './futurekit/core/assets/scss/now-ui-dashboard.scss'
import './assets/fontawesome/css/fontawesome.css'
import './assets/fontawesome/css/brands.css'
import './assets/fontawesome/css/solid.css'
import './assets/fontawesome/css/v5-font-face.css'

ReactDOM.render(<App />, document.getElementById('root'))
