/** @jsxImportSource @emotion/react */
import { Col, Label } from 'reactstrap'
import Switch from 'react-bootstrap-switch'
import { FileUpload, Button } from 'components'
import {
  StyledRow,
  StyledSwitchWrapper,
  StyledSwitchLabel,
  StyledInput,
  StyledCol,
  StyledButtonWrapper,
  styles,
} from './Baseline.styles'
import { useBaselineController } from './Baseline.controller'

export const Baseline = ({ editorRole }) => {
  const { form, validate, disabled, isLoading, handleSave, handleChange, setFile, toggleValidate } =
    useBaselineController()

  return (
    <>
      <StyledRow xl={12} css={styles.row}>
        <Col xl={3}>
          <StyledSwitchWrapper css={styles.switchWrapper}>
            <StyledSwitchLabel css={styles.switchLabel}>Validate</StyledSwitchLabel>
            <Switch onColor="future" value={validate} onChange={toggleValidate} />
          </StyledSwitchWrapper>
          <Label>Baseline Version</Label>
          <StyledInput
            css={styles.input}
            placeholder="Baseline Version"
            value={form.baseline_version}
            onChange={handleChange}
            name="baseline_version"
            disabled={isLoading}
          />
          <Label>Software Version</Label>
          <StyledInput
            css={styles.input}
            placeholder="Software Version"
            value={form.software_version}
            onChange={handleChange}
            name="software_version"
            disabled={isLoading}
          />
          <Label>Technology</Label>
          <StyledInput
            css={styles.input}
            placeholder="Technology"
            value={form.technology}
            onChange={handleChange}
            name="technology"
            disabled={isLoading}
          />
          <Label>Manufacturer</Label>
          <StyledInput
            css={styles.input}
            placeholder="Manufacturer"
            value={form.manufacturer}
            onChange={handleChange}
            name="manufacturer"
            disabled={isLoading}
          />
          <Label>Hardware Type</Label>
          <StyledInput
            css={styles.input}
            placeholder="Hardware Type"
            value={form.hardware_type}
            onChange={handleChange}
            name="hardware_type"
            disabled={isLoading}
          />
        </Col>
        <StyledCol xl={9} css={styles.col}>
          <FileUpload label="Baseline File" isDisabled={isLoading} callbackFn={setFile} />
        </StyledCol>
      </StyledRow>
      <StyledButtonWrapper css={styles.buttonWrapper}>
        <Button priority={editorRole.priority} isDisabled={disabled} handleClick={handleSave}>
          Save
        </Button>
      </StyledButtonWrapper>
    </>
  )
}
