import { useRef, useState, useMemo, useEffect, useCallback } from 'react'
import { useTable, useFilters, useGlobalFilter, useSortBy, useColumnOrder, usePagination } from 'react-table'
import { createLabelFromApiField } from 'utils'
import { renderColumnFilter, fuzzyTextFilterFn } from './helpers'

const DEFAULT_COLUMNS_EXCLUDED_ARRAY = ['id']
const DEFAULT_DYNAMIC_COLUMNS_LENGTH = 4
const INITIAL_ROW_LIMIT = 50
const VIRTUAL_ROWS_LENGTH = 50
const SCROLL_MARGIN = 500
const PAGE_SIZE = 50

const DEFAULT_TABLE_CONFIG = {
  hideMainVerticalScroll: false,
  usePagination: false,
}

const DEFAULT_COLUMNS_CONFIG = {
  isStriped: true,
  isVirtualized: false,
  selectionLimit: 1,
  highlightSelection: false,
  highlightCellSelection: undefined,
  autoSelection: false,
  dynamicColumns: false,
  columnsExcluded: [],
  isDisabled: false,
  advancedFilter: false,
}

const DEFAULT_SWITCH_CONFIG = {
  isVisible: false,
  columnId: 'id',
  callback: null,
  isReadOnly: true,
}

const DEFAULT_TOOLBAR_CONFIG = {
  showFilter: false,
  showOrder: false,
  showRowNumber: false,
}

export const useFutureTableController = ({
  tableConfig,
  columnsConfig,
  switchConfig,
  toolbarConfig,
  placeholder,
  onFilterChange,
  data,
  globalFilter = null,
  defaultColumns,
  filterValue,
  onRowsChange,
}) => {
  const tableOptions = tableConfig ? { ...DEFAULT_TABLE_CONFIG, ...tableConfig } : DEFAULT_TABLE_CONFIG
  const columnsOptions = columnsConfig ? { ...DEFAULT_COLUMNS_CONFIG, ...columnsConfig } : DEFAULT_COLUMNS_CONFIG
  const switchOptions = switchConfig ? { ...DEFAULT_SWITCH_CONFIG, ...switchConfig } : DEFAULT_SWITCH_CONFIG
  const toolbarOptions = toolbarConfig ? { ...DEFAULT_TOOLBAR_CONFIG, ...toolbarConfig } : DEFAULT_TOOLBAR_CONFIG

  const filterRef = useRef()
  const filterInnerRef = useRef()
  const tableRef = useRef()

  const [rowLimit, setRowLimit] = useState(INITIAL_ROW_LIMIT)
  const [isRowLimitChangingOnFilter, setIsRowLimitChangingOnFilter] = useState(false)

  const filterTypes = useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      text: (rows, id, value) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined ? String(rowValue).toLowerCase().startsWith(String(value).toLowerCase()) : true
        })
      },
    }),
    []
  )

  const defaultColumn = useMemo(() => {
    const resetRowLimit = () => {
      if (!columnsOptions.dynamicColumns) {
        return
      }
      setIsRowLimitChangingOnFilter(true)
      setRowLimit(INITIAL_ROW_LIMIT)
      tableRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }

    return {
      Filter: renderColumnFilter({
        placeholder,
        innerRef: null,
        disabled: false,
        onFilterChange,
        resetRowLimit,
      }),
      FilterReachable: renderColumnFilter({
        placeholder,
        ref: filterRef,
        innerRef: filterInnerRef,
        disabled: false,
        onFilterChange,
        resetRowLimit,
      }),
      DisabledFilter: renderColumnFilter({
        placeholder,
        innerRef: null,
        disabled: true,
        onFilterChange,
        resetRowLimit,
      }),
      DisabledFilterReachable: renderColumnFilter({
        placeholder,
        ref: filterRef,
        innerRef: filterInnerRef,
        disabled: true,
        onFilterChange,
        resetRowLimit,
      }),
    }
  }, [placeholder, columnsOptions.dynamicColumns, onFilterChange])

  const columnsFiltered = useMemo(() => {
    const columnsExcluded = DEFAULT_COLUMNS_EXCLUDED_ARRAY.concat(columnsOptions.columnsExcluded)
    if (!data || !data[0]) {
      return []
    }
    return Object.keys(data[0]).filter(key => !columnsExcluded.some(el => el === key))
  }, [columnsOptions.columnsExcluded, data])

  const defaultDynamicColumns = useMemo(() => {
    const length =
      columnsFiltered.length < DEFAULT_DYNAMIC_COLUMNS_LENGTH ? columnsFiltered.length : DEFAULT_DYNAMIC_COLUMNS_LENGTH
    if (!data || !data[0]) {
      return []
    }
    return Array.from({ length }, (_, i) => ({
      Header: createLabelFromApiField(columnsFiltered[i]),
      fixed: switchOptions.isVisible && columnsFiltered[i] === switchOptions.columnId ? 'left' : undefined,
      accessor: columnsFiltered[i],
      sortable: !(switchOptions.isVisible && columnsFiltered[i] === switchOptions.columnId),
      filterable: !(switchOptions.isVisible && columnsFiltered[i] === switchOptions.columnId),
    }))
  }, [data, columnsFiltered, switchOptions.isVisible, switchOptions.columnId])

  const { columns, toolbarFilter } = useMemo(() => {
    if (!data[0]) {
      return { columns: [], toolbarFilter: [] }
    }
    if (!columnsOptions.dynamicColumns && !!defaultColumns.length) {
      return {
        columns: defaultColumns,
        toolbarFilter: defaultColumns.reduce((toolbarPrev, toolbarNext) => {
          return [
            ...toolbarPrev,
            {
              id: toolbarNext.accessor,
              value: true,
              label: createLabelFromApiField(toolbarNext.accessor),
            },
          ]
        }, []),
      }
    }
    return columnsFiltered.reduce(
      (prev, next) => {
        const label = createLabelFromApiField(next)

        if (defaultDynamicColumns.some(col => col.accessor === next)) {
          return {
            columns: prev.columns,
            toolbarFilter: [
              ...prev.toolbarFilter,
              {
                id: next,
                value: true,
                label,
              },
            ],
          }
        }
        return {
          columns: [
            ...prev.columns,
            {
              Header: label,
              accessor: next,
              sortable: true,
              filterable: true,
            },
          ],
          toolbarFilter: [
            ...prev.toolbarFilter,
            {
              id: next,
              value: true,
              label,
            },
          ],
        }
      },
      { columns: columnsOptions.dynamicColumns ? defaultDynamicColumns : defaultColumns, toolbarFilter: [] }
    )
  }, [data, columnsOptions.dynamicColumns, defaultColumns, columnsFiltered, defaultDynamicColumns])

  const globalFilterFn = useCallback(
    (rows, _columnIds, globalFilterValue) => {
      const isRowVisible = rowValues => {
        return Object.keys(rowValues).every(key => {
          const value = rowValues[key]?.toString() || ''
          return globalFilterValue[key][value] !== false
        })
      }

      if (!globalFilterValue || !columnsOptions.advancedFilter) {
        return rows
      }
      return rows?.filter(row => isRowVisible(row?.values))
    },
    [columnsOptions.advancedFilter]
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    pageCount,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    previousPage,
    nextPage,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
    setColumnOrder,
  } = useTable(
    {
      data,
      filterTypes,
      defaultColumn,
      columns,
      globalFilter: globalFilterFn,
      initialState: { pageSize: PAGE_SIZE },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useColumnOrder,
    usePagination
  )
  const headerGroup = headerGroups[0]
  const globalFilterInitialValue = useMemo(() => {
    if (globalFilter) {
      return globalFilter
    }
    return headerGroup.headers.reduce(
      (filterOptions, column) => ({
        ...filterOptions,
        [column.id]: column.preFilteredRows.reduce(
          (prev, next) => ({
            ...prev,
            [next.values[column.id] || '']: true,
          }),
          {}
        ),
      }),
      {}
    )
  }, [globalFilter, headerGroup.headers])
  const globalFilterValue = state.globalFilter

  const [visibleColumns, setVisibleColumns] = useState([])
  const allRowsLoaded = useMemo(() => rowLimit >= rows.length, [rowLimit, rows.length])

  const handleGlobalFilterChange = useCallback(
    (columnId, rowValue) => {
      setGlobalFilter(prevGlobalFilterValue => ({
        ...prevGlobalFilterValue,
        [columnId]: {
          ...prevGlobalFilterValue[columnId],
          [rowValue]: !prevGlobalFilterValue[columnId][rowValue],
        },
      }))
    },
    [setGlobalFilter]
  )

  const handleGlobalFilterSelectAll = columnId => {
    setGlobalFilter(prevGlobalFilterValue => ({
      ...prevGlobalFilterValue,
      [columnId]: Object.keys(globalFilterInitialValue[columnId]).reduce(
        (prev, next) => ({
          ...prev,
          [next]: true,
        }),
        {}
      ),
    }))
  }

  const handleGlobalFilterDeselectAll = columnId => {
    setGlobalFilter(prevGlobalFilterValue => ({
      ...prevGlobalFilterValue,
      [columnId]: Object.keys(globalFilterInitialValue[columnId]).reduce(
        (prev, next) => ({
          ...prev,
          [next]: false,
        }),
        {}
      ),
    }))
  }

  const handleVisibleColumnsChange = event => {
    setVisibleColumns(prevState =>
      prevState.reduce((prev, next) => {
        if (next.id !== event.target.id) {
          return [...prev, next]
        }
        return [...prev, { ...next, value: !next.value }]
      }, [])
    )
  }

  const handleOnMouse = event => {
    if (tableOptions.usePagination || !tableOptions.hideMainVerticalScroll || allRowsLoaded) {
      return
    }

    const mainPanelEl = document.getElementsByTagName('main')[0]
    mainPanelEl.style.overflow = event.type === 'mouseenter' ? 'hidden' : 'auto'
  }

  const handleVerticalScroll = event => {
    if (tableOptions.usePagination) {
      return
    }
    const topReached = event.target.scrollTop === 0
    const bottomAlmostReached =
      event.target.scrollHeight - event.target.scrollTop - SCROLL_MARGIN <= event.target.clientHeight

    if (topReached && isRowLimitChangingOnFilter) {
      setIsRowLimitChangingOnFilter(false)
      return
    }
    if (bottomAlmostReached && !isRowLimitChangingOnFilter) {
      setRowLimit(prevState => {
        const newValue = prevState + VIRTUAL_ROWS_LENGTH
        return newValue <= rows.length ? newValue : rows.length
      })
    }
  }

  const reorderVisibleColumns = rearrangedItems => {
    setVisibleColumns(rearrangedItems)
    setColumnOrder(rearrangedItems.map(c => c.id))
  }

  useEffect(() => {
    if (globalFilterInitialValue && !globalFilterValue) {
      setGlobalFilter(globalFilterInitialValue)
    }
  }, [globalFilterInitialValue, globalFilterValue, setGlobalFilter])

  useEffect(() => {
    setVisibleColumns(toolbarFilter)
  }, [toolbarFilter])

  useEffect(() => {
    if (onRowsChange) onRowsChange(rows.map(row => row.values))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows])

  useEffect(() => {
    if (filterRef && filterRef.current) {
      filterRef.current.props.onChange({
        target: {
          value: filterValue,
        },
      })
      filterInnerRef.current.value = filterValue
    }
  }, [filterValue])

  useEffect(() => {
    const mainPanelEl = document.getElementsByTagName('main')[0]

    if (allRowsLoaded && mainPanelEl.style.overflow === 'hidden') {
      mainPanelEl.style.overflow = 'auto'
    } else if (!allRowsLoaded && mainPanelEl.style.overflow === 'auto') {
      mainPanelEl.style.overflow = 'hidden'
    }
  }, [allRowsLoaded, rowLimit, rows.length])

  return {
    tableRef,
    tableOptions,
    columnsOptions,
    switchOptions,
    toolbarOptions,
    defaultCols: columnsOptions.dynamicColumns ? defaultDynamicColumns : defaultColumns,
    getTableProps,
    getTableBodyProps,
    headerGroup,
    page,
    pageIndex: state.pageIndex,
    pageCount,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    previousPage,
    nextPage,
    rows,
    prepareRow,
    globalFilterValue: globalFilterValue ?? globalFilterInitialValue,
    visibleColumns,
    rowLimit,
    handleGlobalFilterChange,
    handleGlobalFilterSelectAll,
    handleGlobalFilterDeselectAll,
    handleVisibleColumnsChange,
    handleOnMouse,
    handleVerticalScroll,
    reorderVisibleColumns,
  }
}
