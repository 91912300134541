import { useEffect, useRef } from 'react'

const DEFAULT_SUCCESS_TEXT = 'Successfully saved'
const DEFAULT_DANGER_TEXT = 'An unexpected error has occurred'

export const useToastController = ({ type, delay, text }) => {
  const notificationAlert = useRef()

  useEffect(() => {
    if (notificationAlert && notificationAlert.current) {
      const options = {
        place: 'tr',
        message: text || (type === 'success' && DEFAULT_SUCCESS_TEXT) || DEFAULT_DANGER_TEXT,
        type,
        icon: `now-ui-icons ui-1_${type === 'success' ? 'check' : 'simple-remove'}`,
        autoDismiss: delay,
      }

      notificationAlert.current.notificationAlert(options)
    }
  }, [notificationAlert, delay, type, text])

  return { notificationAlert }
}
