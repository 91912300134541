import { useState, useContext, useMemo, useCallback, useEffect } from 'react'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { getDataTypes, getDataTypesFilter, deleteDataType as deleteDataTypesApi } from 'api'
import { showPrompt, showToast } from 'utils'
import { CRUDManagementContext } from 'context'

export const useDataTypeListController = ({ deleteMode }) => {
  const queryClient = useQueryClient()
  const [selectedRowsForDeleteMode, setSelectedRowsForDeleteMode] = useState([])
  const { selected, mode, select, deleteSelected } = useContext(CRUDManagementContext)

  const { data: dataTypes, isLoading } = useQuery(['dataTypes'], getDataTypes)

  const { data: filter, isLoading: isFilterLoading } = useQuery(['dataTypesFilter'], getDataTypesFilter)

  const selectedRowIds = useMemo(() => {
    if (deleteMode) {
      return selectedRowsForDeleteMode.map(row => row.data_type_id)
    }
    return selected ? [selected.data_type_id] : []
  }, [deleteMode, selected, selectedRowsForDeleteMode])

  const manageSelectionForDeleteMode = dataType => {
    const isDataTypeAlreadyAdded = selectedRowsForDeleteMode.some(d => d.data_type_id === dataType.data_type_id)
    setSelectedRowsForDeleteMode(prevState => {
      if (isDataTypeAlreadyAdded) {
        return prevState.filter(d => d.data_type_id !== dataType.data_type_id)
      }
      return [...prevState, dataType]
    })
  }

  const selectDataType = dataType => {
    if (deleteMode) {
      manageSelectionForDeleteMode(dataType)
    } else {
      select(dataType)
    }
  }

  const { mutate: deleteKeywords, isLoading: isDeleteLoading } = useMutation(deleteDataTypesApi, {
    onSuccess: (response, variables) => {
      queryClient.setQueryData(['dataTypes'], data => {
        return data.filter(element => variables.ids.every(id => element.data_type_id !== id))
      })
      setSelectedRowsForDeleteMode([])
      showToast(response.message)
    },
  })

  const handleSelectAll = useCallback(() => {
    setSelectedRowsForDeleteMode(dataTypes)
  }, [dataTypes])

  const handleDeleteDataTypes = useCallback(() => {
    deleteKeywords({ ids: selectedRowIds })
  }, [deleteKeywords, selectedRowIds])

  const handleDelete = () => {
    showPrompt({
      title: `Are you sure you want to delete ${selectedRowsForDeleteMode.length} data type${
        (selectedRowsForDeleteMode.length > 1 && 's') || ''
      }?`,
      confirmButtonText: 'Yes, delete',
      callbackAsyncFn: handleDeleteDataTypes,
    })
  }

  useEffect(() => {
    if (deleteMode) {
      setSelectedRowsForDeleteMode([])
    } else {
      deleteSelected()
    }
  }, [deleteMode, deleteSelected])

  return {
    dataTypes,
    filter,
    isDataTypesLoading: isLoading || isFilterLoading,
    selectedRowIds,
    isCreationMode: mode === 'create',
    isDeleteLoading,
    selectDataType,
    handleSelectAll,
    handleDelete,
  }
}
