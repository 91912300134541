import { useState } from 'react'

function reorder(list, startIndex, endIndex) {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

export const useDndContainerController = (visibleColumns, reorderVisibleColumns) => {
  const [items, setItems] = useState(visibleColumns)

  const onDragEnd = result => {
    if (!result.destination) {
      // dropped outside the list
      return
    }
    const rearrangedItems = reorder(items, result.source.index, result.destination.index)
    setItems(rearrangedItems)
    reorderVisibleColumns(rearrangedItems)
  }

  return { items, onDragEnd }
}
