import { useContext, useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'
import { CRUDManagementContext } from 'context'
import { changeUserPassword as changeUserPasswordApi } from 'api'
import { showChangePasswordAlert, showSuccessAlert } from 'utils'

export const useToolbarController = () => {
  const { selected } = useContext(CRUDManagementContext)

  const { mutateAsync: changeUserPassword } = useMutation(changeUserPasswordApi, {
    onSuccess: response => showSuccessAlert({ body: response.message }),
  })

  const changePassword = useCallback(async () => {
    const alertValues = {
      currentPassword: document.getElementById('currentPassword')?.value,
      newPassword: document.getElementById('newPassword')?.value,
    }
    try {
      await changeUserPassword({
        userId: selected?.id,
        new_password: alertValues.newPassword,
        new_password_confirm: alertValues.newPassword,
      })
    } catch (error) {
      // error handled on api interceptor
    }
  }, [changeUserPassword, selected?.id])

  const handleChangePassword = () => showChangePasswordAlert({ callbackAsyncFn: changePassword, isUserAdmin: true })

  return {
    isDisabled: !selected,
    handleChangePassword,
  }
}
