import { useContext } from 'react'
import { BaselineHistoryContext } from 'context'

export const useFilterController = () => {
  const {
    selectedTechnology,
    selectedManufacturer,
    selectedHardwareType,
    technologyListData,
    manufacturerListData,
    hardwareTypeListData,
    isTechnologyLoading,
    isManufacturerLoading,
    isHardwareTypeLoading,
    selectTechnology,
    selectManufacturer,
    selectHardwareType,
  } = useContext(BaselineHistoryContext)

  return {
    selectedTechnology,
    selectedManufacturer,
    selectedHardwareType,
    technologyListData,
    manufacturerListData,
    hardwareTypeListData,
    isTechnologyLoading,
    isManufacturerLoading,
    isHardwareTypeLoading,
    selectTechnology,
    selectManufacturer,
    selectHardwareType,
  }
}
