const containerStyles = color => ({
  width: '16rem',
  height: 37,
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'center',
  padding: '0.5rem',
  opacity: '50%',
  border: `2px solid`,
  borderColor: color,
  borderRadius: 6,

  '@media (max-width: 574px)': {
    width: '100%',
    marginTop: '10px',
    justifyContent: 'center',
  },
})

const iconStyles = color => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: '20%',
  marginRight: '1rem',
  color,
})

const labelStyles = {
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'center',
  marginBottom: 0,
  minWidth: '80%',

  '@media (max-width: 574px)': {
    transform: 'translateX(-30%)',
    justifyContent: 'center',
    minWidth: '100%',
  },
}

export const styles = {
  container: containerStyles,
  icon: iconStyles,
  label: labelStyles,
}
