/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types'
import { Spinner } from '../Spinner'
import { StyledButton, styles } from './Button.styles'
import { useButtonController } from './Button.controller'

const Button = ({ color, priority, isDisabled, isLoading, isCancelButton, handleClick, children, ...restProps }) => {
  const { isDisabledByRole } = useButtonController({ priority, isCancelButton })

  return (
    <StyledButton
      color={color}
      onClick={handleClick}
      disabled={isDisabled || isLoading || isDisabledByRole}
      css={styles.button}
      {...restProps}
    >
      {(isLoading && <Spinner color="white" size="1rem" />) || children}
    </StyledButton>
  )
}

Button.defaultProps = {
  color: 'primary',
  priority: null,
  isDisabled: false,
  isLoading: false,
  isCancelButton: false,
}

Button.propTypes = {
  color: PropTypes.string,
  priority: PropTypes.number,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isCancelButton: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export { Button }
