/** @jsxImportSource @emotion/react */
import { Row, Col } from 'reactstrap'
import CodeMirror from '@uiw/react-codemirror'
import { sql } from '@codemirror/lang-sql'
import { FutureTable } from 'futurekit'
import { EmptyState, Button, Spinner } from 'components'
import { EDITOR_ROLE_PRIORITY } from 'utils/constants'
import { useKeywordTableController } from './KeywordTable.controller'
import { StyledWrapper, styles } from './KeywordTable.styles'

const READ_ONLY_MODE_COLUMNS = [
  {
    Header: 'Name',
    accessor: 'data',
    sortable: true,
    filterable: true,
  },
  {
    Header: 'Definition',
    accessor: 'definition',
    sortable: false,
    filterable: false,
  },
]

const WRITABLE_MODE_COLUMNS = [
  {
    Header: 'Name',
    accessor: 'data',
    sortable: true,
    filterable: true,
  },
]

export const KeywordTable = () => {
  const {
    selectedBaseline,
    selectedKeyword,
    selectedRowIds,
    definition,
    keywordsData,
    keyWordFilter,
    isKeywordsLoading,
    isReadOnly,
    setSelectedKeyword,
    handleDefinitionChange,
    handleSave,
    handleCancel,
    handleFilterChange,
  } = useKeywordTableController()

  if (selectedBaseline && isKeywordsLoading) {
    return <Spinner />
  }
  if (!keywordsData || !keywordsData.length) {
    return <EmptyState size="5rem" />
  }

  const columns = selectedKeyword ? WRITABLE_MODE_COLUMNS : READ_ONLY_MODE_COLUMNS

  return (
    <>
      <Row css={styles.row}>
        <Col xs={selectedKeyword ? 6 : 12} css={styles.col}>
          <FutureTable
            data={keywordsData}
            defaultColumns={columns}
            selectedRowIds={selectedRowIds}
            placeholder="Keyword"
            filterValue={keyWordFilter}
            filterNameReachable="data"
            onRowClick={setSelectedKeyword}
            onFilterChange={handleFilterChange}
            columnsConfig={{
              isStriped: false,
              selectionLimit: 2,
              highlightSelection: true,
            }}
          />
        </Col>
        {selectedKeyword && (
          <Col xs="6">
            <CodeMirror
              extensions={[sql()]}
              height="555px"
              placeholder=" Definition"
              value={definition}
              onChange={handleDefinitionChange}
              readOnly={isReadOnly}
              css={styles.codeMirror}
            />
          </Col>
        )}
      </Row>
      {selectedKeyword && (
        <StyledWrapper css={styles.buttonWrapper}>
          <Button isCancelButton color="danger" handleClick={handleCancel} outline>
            Cancel
          </Button>
          <Button
            isDisabled={isReadOnly || !definition || !definition.trim()}
            handleClick={handleSave}
            priority={EDITOR_ROLE_PRIORITY}
          >
            Save
          </Button>
        </StyledWrapper>
      )}
    </>
  )
}
