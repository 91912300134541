import { BaselineHistory } from 'components'
import { useBaselineStatusHistoryController } from './BaselineStatusHistory.controller'

export const BaselineStatusHistory = () => {
  const { data, filter, isLoading, selected, handleRowClick } = useBaselineStatusHistoryController()

  return (
    <BaselineHistory
      data={data}
      filter={filter}
      isLoading={isLoading}
      showRestoreButton={false}
      exportConfig={{
        columnsToSkip: [],
        excelName: 'baseline_status_history',
        sheetName: 'Sheet 1',
      }}
      useAdvancedFilter
      selected={selected}
      handleRowClick={handleRowClick}
    />
  )
}
