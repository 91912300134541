/** @jsxImportSource @emotion/react */
import { Card } from 'components'
import { CRUDManagementProvider } from 'context'
import DataTypeList from './components/DataTypeList'
import DataTypeForm from './components/DataTypeForm'
import { StyledCol, styles } from './DataTypes.styles'
import { useDataTypesController } from './DataTypes.controller'

const DataTypesState = ({ children }) => {
  return <CRUDManagementProvider>{children}</CRUDManagementProvider>
}

const DataTypesContent = () => {
  const { transition, deleteMode, toggleDeleteMode } = useDataTypesController()

  return (
    <>
      <StyledCol css={styles.col(!!deleteMode)}>
        <Card title="Data Types table">
          <DataTypeList deleteMode={deleteMode} toggleDeleteMode={toggleDeleteMode} />
        </Card>
      </StyledCol>
      <StyledCol css={styles.col(deleteMode, transition)}>
        <Card title="Data Types details">
          <DataTypeForm />
        </Card>
      </StyledCol>
    </>
  )
}

export const DataTypes = () => {
  return (
    <DataTypesState>
      <DataTypesContent />
    </DataTypesState>
  )
}
