import styled from '@emotion/styled'
import { Input } from 'reactstrap'

export const StyledTableHeader = styled.th``

export const StyledHeaderContent = styled.div``

export const StyledIcon = styled.span``

export const StyledInput = styled(Input)``

export const StyledHeaderText = styled.div``

export const StyledToolbar = styled.div``

export const StyledDropdownOption = styled.div``

export const StyledInputContainer = styled.div``

export const StyledOptionContainer = styled.div``

const tableHeaderStyles = isSomeColumnFilterable => ({
  flex: '1 0 auto',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  transition: 'box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275)',
  margin: '0 auto auto 0',
  padding: '5px 5px',
  position: 'relative',
  minWidth: '14.3rem',
  width: 0,
  minHeight: isSomeColumnFilterable ? '85px' : 0,
  textAlign: 'left',
  lineHeight: '2.5em',
  boxShadow: 'inset 0 0 0 0 transparent',
  fontSize: '12px',
  fontWeight: 400,
  textTransform: 'uppercase',
  color: '#9a9a9a',
  backgroundColor: 'white',

  '&.table-resizable-header': {
    overflow: 'visible',
  },

  '&:focus': {
    outline: 'none',
  },

  '&.-hidden': {
    width: 0,
    minWidth: 0,
    padding: 0,
    border: 0,
    opacity: 0,
  },
})

const flexStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '100%',
}

const headerContentStyles = isSwitchColumn => ({
  display: 'flex',
  justifyContent: isSwitchColumn ? 'center' : 'space-between',
  gap: isSwitchColumn ? '0.5rem' : 0,

  '&:focus': {
    outline: 'none',
  },
})

const toolbarIconStyles = {
  opacity: 0.6,
  padding: '0.3rem',
  fontWeight: 900,
  cursor: 'pointer',
}

const sortableIconStyles = sorted => ({
  ...toolbarIconStyles,
  opacity: sorted !== 'sort' ? 1 : 0.6,
})

const searchStyles = {
  width: '90%',
}

const textOverflowStyles = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}

const headerTextStyles = allFilterOptionsSelected => ({
  ...textOverflowStyles,
  color: allFilterOptionsSelected ? 'inherit' : '#F80F52',
})

const toolbarStyles = {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginRight: '5px',
}

const dropdownMenuStyles = {
  '.dropdown-menu': {
    minWidth: '17rem',
    maxWidth: '17rem',
    maxHeight: '17.5rem',
    overflow: 'hidden auto',
  },
}

const dropdownOptionStyles = {
  ...flexStyles,
  userSelect: 'none',
  marginLeft: '5px',
  marginBottom: '3px',
  input: {
    cursor: 'pointer',
    marginTop: 0,
  },
  span: {
    marginLeft: '0.25rem',
    minWidth: 'max-content',
  },
}

const inputContainerStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

export const styles = {
  tableHeader: tableHeaderStyles,
  headerContent: headerContentStyles,
  icon: toolbarIconStyles,
  sortableIcon: sortableIconStyles,
  search: searchStyles,
  headerText: headerTextStyles,
  toolbar: toolbarStyles,
  dropdownMenu: dropdownMenuStyles,
  dropdownOption: dropdownOptionStyles,
  inputContainer: inputContainerStyles,
  optionContainer: textOverflowStyles,
}
