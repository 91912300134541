/** @jsxImportSource @emotion/react */
import { StyledTableHead, styles } from './TableHeader.styles'
import { StyledTableRow, styles as futureTableStyles } from '../../FutureTable.styles'
import { ColumnHeader } from '../ColumnHeader'

const TableHeader = ({
  headerGroup,
  columnsOptions,
  switchOptions,
  filterNameReachable,
  globalFilterValue,
  visibleColumns,
  handleGlobalFilterChange,
  handleGlobalFilterSelectAll,
  handleGlobalFilterDeselectAll,
}) => {
  const isSomeColumnFilterable = headerGroup.headers.some(column => column.filterable) || false

  return (
    <StyledTableHead className="table-thead -header" css={styles.tableHead(isSomeColumnFilterable)}>
      <StyledTableRow
        {...headerGroup.getHeaderGroupProps()}
        className="table-tr"
        css={futureTableStyles.tableRow(false)}
      >
        {headerGroup.headers.map((column, index) => {
          const isLaterColumn = index === headerGroup.headers.length - 1 || index === headerGroup.headers.length - 2
          if (!visibleColumns.find(el => el.id === column.id)?.value) {
            return null
          }
          return (
            <ColumnHeader
              key={column.id}
              column={column}
              columnsOptions={columnsOptions}
              switchOptions={switchOptions}
              filterNameReachable={filterNameReachable}
              filterOptions={globalFilterValue[column.id]}
              isSomeColumnFilterable={isSomeColumnFilterable}
              isLaterColumn={isLaterColumn}
              handleGlobalFilterChange={handleGlobalFilterChange}
              handleGlobalFilterSelectAll={handleGlobalFilterSelectAll}
              handleGlobalFilterDeselectAll={handleGlobalFilterDeselectAll}
            />
          )
        })}
      </StyledTableRow>
    </StyledTableHead>
  )
}

export { TableHeader }
