/** @jsxImportSource @emotion/react */
import { useCallback, useReducer, useRef } from 'react'
import { useMutation } from '@tanstack/react-query'
import { restoreParameter } from 'api'
import { showErrorAlert, showPrompt, showToast } from 'utils'
import { StyledPromptBody, styles } from './BaselineHistory.styles'

const COLUMN_NAME_NON_RESTORABLE = 'regla'

export const useBaselineHistoryController = ({ selected, refetch }) => {
  const visibleRowsRef = useRef(null)
  const [, forceUpdate] = useReducer(x => x + 1, 0)

  const { mutateAsync: update } = useMutation(restoreParameter, {
    onSuccess: response => {
      if (refetch) {
        refetch()
      }
      showToast(response.message)
    },
  })

  const restore = useCallback(async () => {
    try {
      await update({
        ruleId: selected.rule_id,
        [selected.column_name]: selected.old_value,
      })
    } catch (error) {
      // error handled on api interceptor
    }
  }, [update, selected])

  const handleRestore = () => {
    if (selected.column_name === COLUMN_NAME_NON_RESTORABLE) {
      showErrorAlert({ text: 'Rule must not be restorable' })
      return
    }
    showPrompt({
      title: 'Are you sure you want to restore the old value?',
      body: (
        <StyledPromptBody css={styles.promptBody}>
          <b>New value:</b>
          <span>{selected.new_value || '-'}</span>
          <b>Old value:</b>
          {selected.old_value}
        </StyledPromptBody>
      ),
      confirmButtonText: 'Yes, restore',
      callbackAsyncFn: restore,
    })
  }

  const handleRowsChange = useCallback(rows => {
    const shouldUpdate = JSON.stringify(visibleRowsRef.current) !== JSON.stringify(rows)
    visibleRowsRef.current = rows
    if (shouldUpdate) forceUpdate()
  }, [])

  return {
    excelData: visibleRowsRef.current || [],
    selectedRowIds: selected ? [selected.id] : [],
    handleRestore,
    handleRowsChange,
  }
}
