export const ADVANCED_BULK_LOAD_INITIAL_STATE = {
  step: 1,
  validate: true,
  file: null,
  formDetails: {
    manufacturer: '',
    technology: '',
  },
  columnsEquivalence: null,
  selectedBaseline: null,
}

const AdvancedBulkLoadReducer = (state, action) => {
  switch (action.type) {
    case 'RESET_STATE':
      return { ...ADVANCED_BULK_LOAD_INITIAL_STATE }
    case 'TOGGLE_VALIDATE':
      return {
        ...state,
        validate: !state.validate,
      }
    case 'UPLOAD_FILE':
      return {
        ...state,
        file: action.payload,
      }
    case 'SET_FORM_DETAILS':
      return {
        ...state,
        formDetails: { ...state.formDetails, [action.payload.name]: action.payload.value },
      }
    case 'ADD_COLUMNS_EQUIVALENCE':
      return {
        ...state,
        step: 2,
        columnsEquivalence: action.payload,
      }
    case 'SELECT_BASELINE':
      return {
        ...state,
        selectedBaseline: action.payload,
      }
    case 'SET_COLUMN_VALUE':
      return {
        ...state,
        columnsEquivalence: {
          ...state.columnsEquivalence,
          [state.selectedBaseline]: {
            ...state.columnsEquivalence[state.selectedBaseline],
            columns: {
              ...state.columnsEquivalence[state.selectedBaseline].columns,
              [action.payload.name]: action.payload.value,
            },
          },
        },
      }
    case 'SET_BASELINE_DETAILS':
      return {
        ...state,
        columnsEquivalence: {
          ...state.columnsEquivalence,
          [state.selectedBaseline]: {
            ...state.columnsEquivalence[state.selectedBaseline],
            baseline: {
              ...state.columnsEquivalence[state.selectedBaseline].baseline,
              [action.payload.name]: action.payload.value,
            },
          },
        },
      }
    case 'TOGGLE_IMPORT':
      return {
        ...state,
        columnsEquivalence: {
          ...state.columnsEquivalence,
          [state.selectedBaseline]: {
            ...state.columnsEquivalence[state.selectedBaseline],
            import: !state.columnsEquivalence[state.selectedBaseline].import,
          },
        },
      }
    default:
      return state
  }
}

export { AdvancedBulkLoadReducer }
