import { useState, useContext, useMemo, useCallback, useEffect } from 'react'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { getKeywords, deleteKeywords as deleteKeywordsApi } from 'api'
import { showPrompt, showToast } from 'utils'
import { CRUDManagementContext, GlobalContext } from 'context'

export const useKeywordListController = ({ deleteMode }) => {
  const queryClient = useQueryClient()
  const [selectedRowsForDeleteMode, setSelectedRowsForDeleteMode] = useState([])
  const { isUserRoleEqualOrGreaterThanAdvanced } = useContext(GlobalContext)
  const { selected, mode, select, deleteSelected } = useContext(CRUDManagementContext)

  const { data: keywords, isLoading } = useQuery(['keywords'], getKeywords)

  const selectedRowIds = useMemo(() => {
    if (deleteMode) {
      return selectedRowsForDeleteMode.map(row => row.keyword_id)
    }
    return selected ? [selected.keyword_id] : []
  }, [deleteMode, selected, selectedRowsForDeleteMode])

  const manageSelectionForDeleteMode = keyword => {
    const isKeywordAlreadyAdded = selectedRowsForDeleteMode.some(k => k.keyword_id === keyword.keyword_id)
    setSelectedRowsForDeleteMode(prevState => {
      if (isKeywordAlreadyAdded) {
        return prevState.filter(k => k.keyword_id !== keyword.keyword_id)
      }
      return [...prevState, keyword]
    })
  }

  const selectKeyword = keyword => {
    if (deleteMode) {
      manageSelectionForDeleteMode(keyword)
    } else {
      select(keyword)
    }
  }

  const { mutateAsync: deleteKeywords } = useMutation(deleteKeywordsApi, {
    onSuccess: (response, variables) => {
      queryClient.setQueryData(['keywords'], data => {
        return data.filter(element => variables.ids.every(id => element.keyword_id !== id))
      })
      setSelectedRowsForDeleteMode([])
      showToast(response.message)
    },
  })

  const handleSelectAll = useCallback(() => {
    setSelectedRowsForDeleteMode(keywords)
  }, [keywords])

  const handleDeleteKeywords = useCallback(async () => {
    try {
      await deleteKeywords({ ids: selectedRowIds })
    } catch (error) {
      // error handled on api interceptor
    }
  }, [deleteKeywords, selectedRowIds])

  const handleDelete = () => {
    showPrompt({
      title: `Are you sure you want to delete ${selectedRowsForDeleteMode.length} keyword${
        (selectedRowsForDeleteMode.length > 1 && 's') || ''
      }?`,
      confirmButtonText: 'Yes, delete',
      callbackAsyncFn: handleDeleteKeywords,
    })
  }

  useEffect(() => {
    if (deleteMode) {
      setSelectedRowsForDeleteMode([])
    } else {
      deleteSelected()
    }
  }, [deleteMode, deleteSelected])

  return {
    keywords,
    isKeywordsLoading: isLoading,
    selectedRowIds,
    isCreationMode: mode === 'create',
    isDisabled: !isUserRoleEqualOrGreaterThanAdvanced,
    selectKeyword,
    handleSelectAll,
    handleDelete,
  }
}
