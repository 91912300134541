import styled from '@emotion/styled'

export const StyledWrapper = styled.div``

const containerStyles = {
  overflowY: 'auto',
}

const rowStyles = isFirstStep => ({
  margin: '0',
  padding: '2rem',
  borderRadius: '10px',
  border: isFirstStep ? '2px solid #EBECf4' : 'none',
  ...(!isFirstStep && { paddingTop: 10, paddingLeft: 0 }),
})

const colStyles = {
  '@media (min-width: 1200px)': {
    width: '50%',
    margin: '2.75rem auto 0',
    paddingLeft: '2rem',
  },
}

const selectStyles = {
  marginBottom: '30px',
  '@media (min-width: 1200px)': {
    maxWidth: '25rem',
  },
}

const switchWrapperStyles = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '0.75rem',
}

const resumeLabelStyles = {
  color: '#F80F52',
  fontSize: 18,
  fontWeight: 700,
  margin: '5rem 0.7rem 20px 0',
}

const switchLabelStyles = {
  marginRight: '0.7rem',
}

const inputStyles = {
  marginBottom: '30px',
  height: '37px',
  '@media (min-width: 1200px)': {
    maxWidth: '25rem',
  },
}

const buttonWrapperStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: 10,
  marginTop: 'auto',
}

export const styles = {
  container: containerStyles,
  row: rowStyles,
  col: colStyles,
  select: selectStyles,
  switchWrapper: switchWrapperStyles,
  resumeLabel: resumeLabelStyles,
  switchLabel: switchLabelStyles,
  input: inputStyles,
  buttonWrapper: buttonWrapperStyles,
}
