import styled from '@emotion/styled'
import { Input } from 'reactstrap'
import { Button } from 'components'
import Select from 'react-select'

export const StyledForm = styled.div``
const formStyles = {
  overflowY: 'auto',
  paddingRight: '0.5rem',
}

export const StyledInput = styled(Input)``
const inputStyles = {
  marginBottom: '30px',
  height: '37px',
  '@media(min-width: 1744px)': {
    width: '27rem',
  },
}

export const StyledSelect = styled(Select)``
const selectStyles = {
  marginBottom: '30px',
  height: '37px',
  '@media(min-width: 1744px)': {
    width: '27rem',
  },
}

export const StyledButtonWrapper = styled.div``
const buttonWrapperStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: 10,
  marginTop: 'auto',
}

export const StyledDeleteButton = styled(Button)``
const deleteButtonStyles = {
  marginRight: 'auto',
}

export const styles = {
  form: formStyles,
  input: inputStyles,
  select: selectStyles,
  buttonWrapper: buttonWrapperStyles,
  delete: deleteButtonStyles,
}
