import { useState } from 'react'

export const useColumnHeaderController = ({
  column,
  columnsOptions,
  filterNameReachable,
  handleGlobalFilterChange,
  handleGlobalFilterSelectAll,
  handleGlobalFilterDeselectAll,
}) => {
  const isTooltip = (!columnsOptions.isDisabled && column.sortable) || columnsOptions.advancedFilter
  const sortable = column.sortable && !columnsOptions.isDisabled
  const sortDirection = column.isSortedDesc ? 'sort-down' : 'sort-up'

  const disabled = columnsOptions.isDisabled ? 'Disabled' : ''
  const reachable = column.id === filterNameReachable ? 'Reachable' : ''
  const columnFilter = column.filterable ? column.render(`${disabled}Filter${reachable}`) : null

  const [isAdvancedFilterDropdownVisible, setIsAdvancedFilterDropdownVisible] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  const handleSelectAll = event => {
    if (event.target.checked) {
      handleGlobalFilterSelectAll(column.id)
    } else {
      handleGlobalFilterDeselectAll(column.id)
    }
  }

  const handleInputChange = event => handleGlobalFilterChange(column.id, event.target.id)

  const handleSearch = event => setSearchValue(event.target.value)

  const toggleAdvancedFilterDropdownVisibility = () => {
    setIsAdvancedFilterDropdownVisible(prevState => !prevState)
    if (isAdvancedFilterDropdownVisible) setSearchValue('')
  }

  return {
    isTooltip,
    sortable,
    sortDirection: column.isSorted ? sortDirection : 'sort',
    columnFilter,
    isAdvancedFilterDropdownVisible,
    searchValue,
    handleSelectAll,
    handleInputChange,
    handleSearch,
    toggleAdvancedFilterDropdownVisibility,
  }
}
