/** @jsxImportSource @emotion/react */
import { useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'
import classnames from 'classnames'
import Switch from 'react-bootstrap-switch'
import { StyledTableRow, styles as futureTableStyles } from '../../FutureTable.styles'
import { StyledTableBody, styles } from './TableBody.styles'
import { useTableBodyController } from './TableBody.controller'

const TableBody = ({
  getTableBodyProps,
  tableOptions,
  columnsOptions,
  switchOptions,
  rowIdKey,
  page,
  rows,
  prepareRow,
  selectedRowIds,
  onRowClick,
  onCellClick,
  visibleColumns,
  rowLimit,
}) => {
  const { selectedCell, renderRowStyles, handleClick } = useTableBodyController({
    columnsOptions,
    selectedRowIds,
    rowIdKey,
    onRowClick,
    onCellClick,
  })

  const iterator = useMemo(() => {
    if (tableOptions.usePagination) {
      return page
    }
    if (columnsOptions.isVirtualized) {
      return rows.slice(0, rowLimit)
    }
    return rows
  }, [columnsOptions.isVirtualized, page, rowLimit, rows, tableOptions.usePagination])

  const content = iterator.map((row, index) => {
    prepareRow(row)

    const selectionCondition = columnsOptions.autoSelection || selectedRowIds.length < columnsOptions.selectionLimit
    return (
      <StyledTableRow
        {...row.getRowProps()}
        style={renderRowStyles(row)}
        className={classnames('table-tr', { ' -odd': index % 2 === 0, ' -even': index % 2 === 1 })}
        key={row.id}
        css={futureTableStyles.tableRow(columnsOptions.highlightSelection && selectionCondition)}
      >
        {row.cells.map(cell => {
          if (!visibleColumns.find(el => el.id === cell.column.id)?.value) {
            return null
          }
          if (switchOptions.isVisible && cell.column.id === switchOptions.columnId) {
            return (
              <td className="table-td" key={uuidv4()} css={styles.switchContainer}>
                <Switch
                  onColor="future"
                  offColor="success"
                  defaultValue={cell.value}
                  onText={null}
                  offText={null}
                  disabled={switchOptions.isReadOnly}
                  onChange={() => switchOptions.callback && switchOptions.callback(cell)}
                />
              </td>
            )
          }
          return (
            <td
              {...cell.getCellProps()}
              key={uuidv4()}
              className="table-td"
              data-toggle="tooltip"
              title={cell.value}
              aria-hidden="true"
              onClick={() => handleClick(cell)}
              css={styles.tableCell(
                columnsOptions.highlightCellSelection !== undefined && columnsOptions.highlightCellSelection === false
                  ? null
                  : selectedCell,
                cell.column.id,
                cell.row.id
              )}
            >
              {cell.render('Cell')}
            </td>
          )
        })}
      </StyledTableRow>
    )
  })

  return (
    <StyledTableBody {...getTableBodyProps()} className="table-tbody" css={styles.tableBody(columnsOptions.isDisabled)}>
      {content}
    </StyledTableBody>
  )
}

export { TableBody }
