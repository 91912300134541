import styled from '@emotion/styled'
import { Button } from 'reactstrap'

export const StyledToolbar = styled.div``

export const StyledButton = styled(Button)``

export const StyledIcon = styled.i``

export const StyledDropdownOption = styled.div``

export const StyledRowNumber = styled.span``

const flexStyles = {
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
}

const toolbarButtonStyles = {
  margin: 0,
  padding: '0 0 0 0.5rem',
  '&.btn.btn-link': {
    color: 'gray',
  },
  '&.btn.btn-link:hover': {
    boxShadow: 'none',
  },
  '&.btn.btn-link:not(.disabled):active': {
    color: 'lightgray',
    backgroundColor: 'transparent',
  },
}

const toolbarIconStyles = {
  fontSize: '16px',
}

const dropdownMenuStyles = {
  '.dropdown-menu': {
    minWidth: '225px',
    maxHeight: '280px',
    overflow: 'hidden auto',
  },
}

const dropdownOptionStyles = {
  ...flexStyles,
  userSelect: 'none',
  marginLeft: '5px',
  marginBottom: '3px',
  input: {
    cursor: 'pointer',
    marginTop: 0,
  },
  span: {
    marginLeft: '0.25rem',
    minWidth: 'max-content',
  },
}

const rowNumberStyles = {
  color: '#898989',
  fontWeight: '700',
  marginLeft: 'auto',
}

export const styles = {
  toolbar: flexStyles,
  toolbarButton: toolbarButtonStyles,
  toolbarIcon: toolbarIconStyles,
  dropdownMenu: dropdownMenuStyles,
  dropdownOption: dropdownOptionStyles,
  rowNumber: rowNumberStyles,
}
