/** @jsxImportSource @emotion/react */
import { Card } from 'components'
import { CRUDManagementProvider } from 'context'
import KeywordList from './components/KeywordList'
import KeywordForm from './components/KeywordForm'
import { StyledCol, styles } from './KeywordEditor.styles'
import { useKeywordEditorController } from './KeywordEditor.controller'

const KeywordEditorState = ({ children }) => {
  return <CRUDManagementProvider>{children}</CRUDManagementProvider>
}

const KeywordEditorContent = () => {
  const { transition, deleteMode, toggleDeleteMode } = useKeywordEditorController()

  return (
    <>
      <StyledCol css={styles.col(!!deleteMode)}>
        <Card title="Keyword table">
          <KeywordList deleteMode={deleteMode} toggleDeleteMode={toggleDeleteMode} />
        </Card>
      </StyledCol>
      <StyledCol css={styles.col(deleteMode, transition)}>
        <Card title="Keyword details">
          <KeywordForm />
        </Card>
      </StyledCol>
    </>
  )
}

export const KeywordEditor = () => {
  return (
    <KeywordEditorState>
      <KeywordEditorContent />
    </KeywordEditorState>
  )
}
