import { useQuery } from '@tanstack/react-query'
import { getMe, getRoles } from 'api'

export const useAppStateController = token => {
  const {
    data: user,
    isLoading: isUserLoading,
    fetchStatus: fetchUserStatus,
  } = useQuery(['me'], getMe, {
    enabled: !!token,
  })

  const {
    data: roles,
    isLoading: isRolesLoading,
    fetchStatus: fetchRolesStatus,
  } = useQuery(['roles'], getRoles, {
    enabled: !!token,
  })

  return {
    user,
    roles,
    isLoading: isUserLoading || fetchUserStatus === 'fetching' || isRolesLoading || fetchRolesStatus === 'fetching',
  }
}
