/** @jsxImportSource @emotion/react */
import classnames from 'classnames'
import { Toolbar } from './components/Toolbar'
import { TableHeader } from './components/TableHeader'
import { TableBody } from './components/TableBody'
import { useFutureTableController } from './FutureTable.controller'
import { StyledTableWrapper, StyledTable, StyledPaginationWrapper, styles } from './FutureTable.styles'

const FutureTable = ({
  data,
  globalFilter,
  defaultColumns,
  selectedRowIds = [],
  rowIdKey = 'id',
  placeholder,
  filterValue = '',
  filterNameReachable = '',
  onFilterChange,
  actionView,
  actionEdit,
  actionDelete,
  onRowClick,
  onCellClick,
  onRowsChange,
  tableConfig,
  columnsConfig,
  toolbarConfig,
  switchConfig,
  ...restProps
}) => {
  const {
    tableRef,
    tableOptions,
    columnsOptions,
    switchOptions,
    toolbarOptions,
    defaultCols,
    getTableProps,
    getTableBodyProps,
    headerGroup,
    page,
    pageIndex,
    pageCount,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    previousPage,
    nextPage,
    rows,
    prepareRow,
    globalFilterValue,
    visibleColumns,
    rowLimit,
    handleGlobalFilterChange,
    handleGlobalFilterSelectAll,
    handleGlobalFilterDeselectAll,
    handleVisibleColumnsChange,
    handleOnMouse,
    handleVerticalScroll,
    reorderVisibleColumns,
  } = useFutureTableController({
    tableConfig,
    columnsConfig,
    toolbarConfig,
    switchConfig,
    placeholder,
    onFilterChange,
    data,
    globalFilter,
    defaultColumns,
    filterValue,
    onRowsChange,
  })

  return (
    <StyledTableWrapper
      css={styles.tableWrapper(columnsOptions.isDisabled)}
      className={classnames('FutureTableWrapper primary-pagination', restProps.className, {
        '-highlight': selectedRowIds.length < columnsOptions.selectionLimit,
        '-striped': columnsOptions.isStriped,
      })}
    >
      <Toolbar
        defaultCols={defaultCols}
        rowNumber={rows.length}
        toolbarOptions={toolbarOptions}
        columnsOptions={columnsOptions}
        visibleColumns={visibleColumns}
        handleVisibleColumnsChange={handleVisibleColumnsChange}
        reorderVisibleColumns={reorderVisibleColumns}
      />
      <StyledTable
        {...getTableProps()}
        ref={tableRef}
        className="future-table"
        css={styles.table}
        onMouseEnter={handleOnMouse}
        onMouseLeave={handleOnMouse}
        onScroll={handleVerticalScroll}
      >
        <TableHeader
          headerGroup={headerGroup}
          columnsOptions={columnsOptions}
          switchOptions={switchOptions}
          filterNameReachable={filterNameReachable}
          globalFilterValue={globalFilterValue}
          visibleColumns={visibleColumns}
          handleGlobalFilterChange={handleGlobalFilterChange}
          handleGlobalFilterSelectAll={handleGlobalFilterSelectAll}
          handleGlobalFilterDeselectAll={handleGlobalFilterDeselectAll}
        />
        <TableBody
          getTableBodyProps={getTableBodyProps}
          tableOptions={tableOptions}
          columnsOptions={columnsOptions}
          switchOptions={switchOptions}
          rowIdKey={rowIdKey}
          page={page}
          rows={rows}
          prepareRow={prepareRow}
          selectedRowIds={selectedRowIds}
          onRowClick={onRowClick}
          onCellClick={onCellClick}
          visibleColumns={visibleColumns}
          rowLimit={rowLimit}
        />
      </StyledTable>
      {tableOptions.usePagination && (
        <StyledPaginationWrapper css={styles.pagination}>
          <button css={styles.paginationButton} type="button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            <i className="fa fa-angle-double-left" css={styles.paginationIcon} />
          </button>
          <button
            css={styles.paginationButton}
            type="button"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            <i className="fa fa-angle-left" css={styles.paginationIcon} />
          </button>
          <span css={styles.paginationInfo}>{`Page ${pageIndex + 1} of ${pageOptions.length}`}</span>
          <button css={styles.paginationButton} type="button" onClick={() => nextPage()} disabled={!canNextPage}>
            <i className="fa fa-angle-right" css={styles.paginationIcon} />
          </button>
          <button
            css={styles.paginationButton}
            type="button"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            <i className="fa fa-angle-double-right" css={styles.paginationIcon} />
          </button>
        </StyledPaginationWrapper>
      )}
    </StyledTableWrapper>
  )
}

export { FutureTable }
