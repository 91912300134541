import styled from '@emotion/styled'
import { Label, Col } from 'reactstrap'
import { Button } from 'components'

export const StyledWrapper = styled.div``

export const StyledCol = styled(Col)``

export const StyledSwitchLabel = styled(Label)``

export const StyledButton = styled(Button)``

const toolbarStyles = {
  height: '50px',
  marginTop: '-10px',
  marginBottom: '10px',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',

  '@media (max-width: 575px)': {
    height: 'auto',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}

const switchWrapperStyles = {
  display: 'flex',
  alignItems: 'center',
  margin: '0.75rem 0.75rem 0 0',

  '@media (max-width: 575px)': {
    flexDirection: 'column',
  },
}

const switchLabelStyles = {
  marginRight: '0.7rem',
}

const colStyles = {
  display: 'flex',
  justifyContent: 'flex-end',

  '@media (max-width: 575px)': {
    width: '100%',
  },
}

const buttonStyles = (isDeleteMode, marginRight = 1) => ({
  marginRight,
  opacity: isDeleteMode ? 1 : 0,
  display: isDeleteMode ? 'null' : 'none',
  transition: 'all 0.5s ease-in-out',

  '@media (max-width: 575px)': {
    width: '100%',
    margin: '0.35rem 0',
  },
})

const tableStyles = {
  height: 'calc(100% - 50px)',
}

export const styles = {
  toolbar: toolbarStyles,
  col: colStyles,
  switchWrapper: switchWrapperStyles,
  switchLabel: switchLabelStyles,
  button: buttonStyles,
  table: tableStyles,
}
