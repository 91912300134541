import { Redirect, Route } from 'react-router-dom'
import { getToken } from 'utils'

const PrivateRoute = ({ children, ...args }) => {
  const token = getToken()

  const renderRoute = () => {
    return token ? children : <Redirect to="/login" />
  }

  return <Route {...args} render={renderRoute} />
}

export { PrivateRoute }
