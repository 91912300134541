import { useContext } from 'react'
import { Col } from 'reactstrap'
import { Card } from 'components'
import { GlobalContext } from 'context'
import Baseline from './components/Baseline'
import Keywords from './components/Keywords'

const BulkLoadContent = () => {
  const { editorRole } = useContext(GlobalContext)

  return (
    <Col xs={12}>
      <Card title="New baseline" cardHeight={{ largeDesktop: 'responsive', desktop: 'responsive' }}>
        <Baseline editorRole={editorRole} />
      </Card>
      <Card title="New keywords" cardHeight={{ largeDesktop: 'minimized' }}>
        <Keywords editorRole={editorRole} />
      </Card>
    </Col>
  )
}

export const BulkLoad = () => {
  return <BulkLoadContent />
}
