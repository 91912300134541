import styled from '@emotion/styled'
import { Row, Col } from 'reactstrap'

export const StyledRow = styled(Row)``

export const StyledCol = styled(Col)``

export const StyledButtonWrapper = styled.div``

const rowStyles = {
  margin: '0',
  padding: '2rem',
  borderRadius: '10px',
  border: '2px solid #EBECf4',
}

const colStyles = {
  '@media (min-width: 1200px)': {
    width: '50%',
    margin: '4rem auto',
    paddingLeft: '2rem',
  },
}

const buttonWrapperStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: 10,
  marginTop: 'auto',
}

export const styles = {
  row: rowStyles,
  col: colStyles,
  buttonWrapper: buttonWrapperStyles,
}
