import PropTypes from 'prop-types'
import NotificationAlert from 'react-notification-alert'
import { useToastController } from './Toast.controller'

const Toast = ({ type, delay, text }) => {
  const { notificationAlert } = useToastController({ type, delay, text })

  return <NotificationAlert ref={notificationAlert} />
}

Toast.defaultProps = {
  delay: 3,
  text: '',
}

Toast.propTypes = {
  type: PropTypes.oneOf(['success', 'danger']).isRequired,
  delay: PropTypes.number,
  text: PropTypes.string,
}

export { Toast }
