const containerStyles = {
  overflowY: 'auto',
  paddingRight: '0.5rem',
  zIndex: 10,
}

const selectStyles = {
  marginBottom: '30px',
  '& .react-select__control .react-select__indicators .react-select__clear-indicator:before': {
    cursor: 'pointer',
    fontSize: 20,
    fontWeight: 700,
    marginRight: 8,
  },
  '@media (min-width: 1200px)': {
    maxWidth: '25rem',
  },
}

const buttonWrapperStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: 10,
  marginTop: 'auto',
}

const alertBodyStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  marginLeft: '1rem',
  maxHeight: '21rem',
  overflowY: 'auto',

  '& div': {
    fontWeight: 700,
    fontSize: '1.15rem',
    marginBottom: '0.5rem',
  },
  '& p': {
    fontSize: '1rem',
    textAlign: 'left',
  },
}

export const styles = {
  container: containerStyles,
  select: selectStyles,
  buttonWrapper: buttonWrapperStyles,
  alertBody: alertBodyStyles,
}
