import { AdvancedBulkLoadContext } from 'context'
import { useContext } from 'react'

export const useBaselinePreviewController = () => {
  const { baselineDetails } = useContext(AdvancedBulkLoadContext)

  return {
    baselineDetails,
  }
}
