/** @jsxImportSource @emotion/react */
import { Fragment } from 'react'
import { Label } from 'reactstrap'
import Select from 'react-select'
import { Button } from 'components'
import { createLabelFromApiField } from 'utils'
import { useParameterAttributeSelectorController } from './ParameterAttributeSelector.controller'
import { styles } from './ParameterAttributeSelector.styles'

export const ParameterAttributeSelector = props => {
  const { editorRole } = props

  const {
    isViewHidden,
    baselineDetails,
    selectOptions,
    isButtonDisabled,
    handleChange,
    resetToInitialState,
    handleSave,
  } = useParameterAttributeSelectorController()

  if (isViewHidden) {
    return null
  }
  return (
    <>
      <div css={styles.container}>
        {Object.entries(baselineDetails.columns).map(([name, value]) => (
          <Fragment key={name}>
            <Label>{createLabelFromApiField(name)}</Label>
            <Select
              css={styles.select}
              className="react-select primary"
              classNamePrefix="react-select"
              isClearable
              isSearchable={false}
              placeholder="Select an option"
              name={name}
              options={selectOptions}
              menuPosition="fixed"
              menuShouldBlockScroll
              value={
                value
                  ? {
                      value,
                      label: createLabelFromApiField(value),
                    }
                  : null
              }
              onChange={handleChange}
            />
          </Fragment>
        ))}
      </div>
      <div css={styles.buttonWrapper}>
        <Button outline isCancelButton color="danger" handleClick={resetToInitialState}>
          Cancel
        </Button>
        <Button priority={editorRole?.priority} disabled={isButtonDisabled} handleClick={handleSave}>
          Save
        </Button>
      </div>
    </>
  )
}
