/** @jsxImportSource @emotion/react */
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Footer } from '@reddieoffice/reddie_react_lib'
import { getToken } from 'utils'
import { StyledBackground, styles } from './LoginLayout.styles'

const LoginLayout = ({ children }) => {
  const { push } = useHistory()
  const token = getToken()

  useEffect(() => {
    if (token) {
      push('/')
    }
  }, [token, push])

  if (token) {
    return null
  }
  return (
    <StyledBackground className="wrapper" css={styles.background}>
      {children}
      <Footer fluid />
    </StyledBackground>
  )
}

export { LoginLayout }
