/** @jsxImportSource @emotion/react */
import { Col } from 'reactstrap'
import { Card } from 'components'
import { AdvancedBulkLoadProvider } from 'context'
import Details from './components/Details'
import ParameterAttributeSelector from './components/ParameterAttributeSelector'
import BaselinePreview from './components/BaselinePreview'
import { useAdvancedBulkLoadController } from './AdvancedBulkLoad.controller'
import { styles } from './AdvancedBulkLoad.styles'

const AdvancedBulkLoadState = ({ children }) => {
  return <AdvancedBulkLoadProvider>{children}</AdvancedBulkLoadProvider>
}

const AdvancedBulkLoadContent = () => {
  const { editorRole, transition, isFirstStep, detailsCardHeight } = useAdvancedBulkLoadController()

  return (
    <>
      <Col xs={12} md={12} lg={isFirstStep ? 12 : 6} css={styles.col(false)}>
        <Card title="Details" cardHeight={{ largeDesktop: detailsCardHeight, desktop: detailsCardHeight }}>
          <Details isFirstStep={isFirstStep} editorRole={editorRole} />
        </Card>
      </Col>
      <Col xs={12} md={12} lg={6} css={styles.col(isFirstStep, transition)}>
        <Card title="Columns Equivalence">
          <ParameterAttributeSelector editorRole={editorRole} />
        </Card>
      </Col>
      {!isFirstStep && (
        <Col xl={12}>
          <Card title="Baseline Preview" cardHeight={{ largeDesktop: 'minimized' }}>
            <BaselinePreview />
          </Card>
        </Col>
      )}
    </>
  )
}

export const AdvancedBulkLoad = () => {
  return (
    <AdvancedBulkLoadState>
      <AdvancedBulkLoadContent />
    </AdvancedBulkLoadState>
  )
}
