/** @jsxImportSource @emotion/react */
import { Row, Col, Label, Input } from 'reactstrap'
import Select from 'react-select'
import Switch from 'react-bootstrap-switch'
import { FileUpload, Button } from 'components'
import { StyledWrapper, styles } from './Details.styles'
import { useDetailsController } from './Details.controller'

const MANUFACTURER_LIST_DATA = [
  { label: 'ERICSSON', value: 'ERICSSON' },
  { label: 'NOKIA', value: 'NOKIA' },
]

const TECHNOLOGY_LIST_DATA = [
  { label: '3G', value: '3G' },
  { label: '4G', value: '4G' },
  { label: '5G', value: '5G' },
]

const HARDWARE_TYPE_LIST_DATA = [
  { label: 'DU', value: 'DU' },
  { label: 'BB', value: 'BB' },
  { label: 'HW', value: 'HW' },
]

export const Details = props => {
  const { isFirstStep, editorRole } = props
  const {
    validate,
    formDetails,
    baselineDetails,
    baselineListData,
    selectedBaseline,
    isButtonDisabled,
    isLoading,
    toggleValidate,
    toggleImport,
    uploadFile,
    handleBaselineDetailsChange,
    handleBaselineSelectorChange,
    handleFormDetailsChange,
    handleClick,
  } = useDetailsController()

  return (
    <div css={styles.container}>
      <Row xl={12} css={styles.row(isFirstStep)}>
        <Col xl={isFirstStep ? 3 : 12}>
          {selectedBaseline && (
            <>
              <Label>Selected Baseline</Label>
              <Select
                css={styles.select}
                className="react-select primary"
                classNamePrefix="react-select"
                options={baselineListData}
                value={{
                  value: selectedBaseline,
                  label: selectedBaseline,
                }}
                onChange={handleBaselineSelectorChange}
                isSearchable={false}
              />
            </>
          )}
          {!isFirstStep && (
            <>
              <StyledWrapper css={styles.switchWrapper}>
                <Label css={styles.switchLabel}>Import</Label>
                <Switch onColor="future" value={baselineDetails?.import || false} onChange={toggleImport} />
              </StyledWrapper>
              <Label>Baseline Version</Label>
              <Input
                css={styles.input}
                placeholder="Baseline Version"
                value={baselineDetails?.baseline.baseline_version || ''}
                onChange={handleBaselineDetailsChange}
                name="baseline_version"
                disabled={!baselineDetails?.import}
              />
              <Label>Software Version</Label>
              <Input
                css={styles.input}
                placeholder="Software Version"
                value={baselineDetails?.baseline.software_version || ''}
                onChange={handleBaselineDetailsChange}
                name="software_version"
                disabled={!baselineDetails?.import}
              />
              <Label>Hardware Type</Label>
              <Select
                css={styles.select}
                name="hardware_type"
                className="react-select primary"
                classNamePrefix="react-select"
                options={HARDWARE_TYPE_LIST_DATA}
                value={{
                  value: baselineDetails?.baseline.hardware_type || '',
                  label: baselineDetails?.baseline.hardware_type || '',
                }}
                onChange={handleBaselineDetailsChange}
                isSearchable={false}
                isDisabled={!baselineDetails?.import}
              />
            </>
          )}
          {!isFirstStep && <p css={styles.resumeLabel}>Resume</p>}
          <StyledWrapper css={styles.switchWrapper}>
            <Label css={styles.switchLabel}>Validate</Label>
            <Switch
              onColor="future"
              value={validate}
              onChange={toggleValidate}
              name="validate"
              disabled={!isFirstStep || isLoading}
            />
          </StyledWrapper>
          <Label>Manufacturer</Label>
          <Select
            css={styles.select}
            name="manufacturer"
            className="react-select primary"
            classNamePrefix="react-select"
            options={MANUFACTURER_LIST_DATA}
            value={{
              value: formDetails.manufacturer,
              label: formDetails.manufacturer,
            }}
            onChange={handleFormDetailsChange}
            isSearchable={false}
            isDisabled={!isFirstStep || isLoading}
          />
          <Label>Technology</Label>
          <Select
            css={styles.select}
            name="technology"
            className="react-select primary"
            classNamePrefix="react-select"
            options={TECHNOLOGY_LIST_DATA}
            value={{
              value: formDetails.technology,
              label: formDetails.technology,
            }}
            onChange={handleFormDetailsChange}
            isSearchable={false}
            isDisabled={!isFirstStep || isLoading}
          />
        </Col>
        {isFirstStep && (
          <Col xl={9} css={styles.col}>
            <FileUpload label="Baseline File" isDisabled={isLoading} callbackFn={uploadFile} />
          </Col>
        )}
      </Row>
      {isFirstStep && (
        <StyledWrapper css={styles.buttonWrapper}>
          <Button
            priority={editorRole?.priority}
            isDisabled={isButtonDisabled}
            isLoading={isLoading}
            handleClick={handleClick}
          >
            Next
          </Button>
        </StyledWrapper>
      )}
    </div>
  )
}
