import styled from '@emotion/styled'
import { Card, CardHeader, CardTitle, Button, Collapse, CardBody } from 'reactstrap'
import { HEIGHT_180, HEIGHT_250, HEIGHT_650, HEIGHT_720, HEIGHT_780, HEIGHT_850 } from 'utils/constants'

export const StyledCard = styled(Card)``

export const StyledHeader = styled(CardHeader)``

export const StyledCardTitle = styled(CardTitle)``

export const StyledButton = styled(Button)``

export const StyledIcon = styled.i``

export const StyledCollapse = styled(Collapse)``

export const StyledCardBody = styled(CardBody)``

const cardStyles = (isAccordionOpen, cardHeight) => {
  const [minHeight, desktopHeight, largeDesktopHeight] =
    cardHeight.desktop === 'responsive'
      ? [0, 'fit-content', 'fit-content']
      : [
          HEIGHT_720,
          `calc(100vh - ${HEIGHT_780}px)`,
          `calc(100vh - ${cardHeight.largeDesktop === 'minimized' ? HEIGHT_780 : HEIGHT_180}px)`,
        ]

  return {
    display: 'flex',
    minHeight: isAccordionOpen ? minHeight : 'initial',
    height: isAccordionOpen ? largeDesktopHeight : 'initial',

    '@media (max-width: 1200px)': {
      height: isAccordionOpen ? desktopHeight : 'initial',
    },
  }
}

const headerStyles = {
  display: 'flex',
  alignItems: 'center',
  height: '70px',
}

const titleStyles = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}

const closeButtonStyles = {
  marginBottom: '5px',
  '&.btn.btn-link:hover': {
    boxShadow: 'none',
  },
  '&.btn.btn-link:not(.disabled):active': {
    backgroundColor: 'transparent',
  },
}

const accordionButtonStyles = {
  ...closeButtonStyles,
  marginLeft: 'auto',
}

const iconStyles = {
  color: 'gray',
  fontSize: 'large',
}

const collapseStyles = (isAccordionOpen, cardHeight) => {
  const [minHeight, largeDesktopHeight] =
    cardHeight.desktop === 'responsive'
      ? [0, 'fit-content']
      : [HEIGHT_650, `calc(100vh - ${cardHeight.largeDesktop === 'minimized' ? HEIGHT_850 : HEIGHT_250}px)`]

  return {
    overflow: 'hidden',
    minHeight: isAccordionOpen ? minHeight : 'initial',
    height: isAccordionOpen ? largeDesktopHeight : 'initial',

    '@media (max-width: 1200px)': {
      height: 'fit-content',
    },
  }
}

const cardBodyStyles = {
  height: '100%',
  display: 'flex',
  flexDirection: 'column ',
}

export const styles = {
  card: cardStyles,
  header: headerStyles,
  title: titleStyles,
  icon: iconStyles,
  collapse: collapseStyles,
  cardBody: cardBodyStyles,
  closeButton: closeButtonStyles,
  accordionButton: accordionButtonStyles,
}
