import { useEffect, useState, useContext, useMemo, useCallback } from 'react'
import { GlobalContext, CRUDManagementContext } from 'context'
import { createUser, updateUser, deleteUser as deleteUserApi } from 'api'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { showPrompt, showToast } from 'utils'

const INITIAL_FORM = {
  userName: '',
  email: '',
  password: '',
  password_confirm: '',
  role: null,
}

export const useUserFormController = () => {
  const queryClient = useQueryClient()
  const { roles } = useContext(GlobalContext)
  const { selected, mode, deleteSelected, activateCreationMode } = useContext(CRUDManagementContext)

  const [form, setForm] = useState(INITIAL_FORM)

  const reset = () => {
    deleteSelected()
    setForm(INITIAL_FORM)
  }

  const { mutate: create } = useMutation(createUser, {
    onSuccess: response => {
      queryClient.setQueryData(['users'], data => {
        return [...data, response.data]
      })
      reset()
      showToast(response.message)
    },
  })

  const { mutate: update } = useMutation(updateUser, {
    onSuccess: response => {
      queryClient.setQueryData(['users'], data => {
        return data.map(element => (element.user_id === response.data.user_id ? response.data : element))
      })
      reset()
      showToast(response.message)
    },
  })

  const { mutateAsync: deleteUser } = useMutation(deleteUserApi, {
    onSuccess: (response, variables) => {
      queryClient.setQueryData(['users'], data => {
        return data.filter(element => element.user_id !== variables.userId)
      })
      reset()
      showToast(response.message)
    },
  })

  const rolesOptions = useMemo(() => {
    return Object.values(roles).map(r => ({ value: r.role_id, label: r.name }))
  }, [roles])

  const isActionButtonDisabled = useMemo(() => {
    if (mode !== 'default') {
      return (
        !form.userName ||
        !form.email ||
        !form.role ||
        form.password_confirm !== form.password ||
        (mode === 'create' && !form.password)
      )
    }
    return false
  }, [form, mode])

  const handleInputChange = useCallback(
    event => {
      const { name, value } = event.target
      setForm({ ...form, [name]: value })
    },
    [form, setForm]
  )

  const handleSelectChange = useCallback(
    newRole => {
      setForm({ ...form, role: newRole })
    },
    [form, setForm]
  )

  const handlePrimaryButtonClick = () => {
    switch (mode) {
      case 'create':
        create({
          user: form.userName,
          email: form.email,
          password: form.password,
          password_confirm: form.password_confirm,
          role_id: form.role.value,
        })
        break
      case 'edit':
        update({
          userId: selected.id,
          email: form.email,
          role_id: form.role.value,
        })
        break
      case 'default':
        activateCreationMode()
        break
      default:
        break
    }
  }

  const handleDeleteUser = useCallback(async () => {
    try {
      await deleteUser({ userId: selected.id })
    } catch (error) {
      // error handled on api interceptor
    }
  }, [deleteUser, selected])

  const handleDelete = () => {
    showPrompt({
      title: 'Are you sure you want to delete the user?',
      confirmButtonText: 'Yes, delete',
      callbackAsyncFn: handleDeleteUser,
    })
  }

  useEffect(() => {
    if (selected) {
      setForm({
        ...form,
        userName: selected.user,
        email: selected.email,
        role: { value: selected.role_id, label: selected.role },
      })
    }
  }, [selected]) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    form,
    selected,
    rolesOptions,
    isDefaultMode: mode === 'default',
    isEditMode: mode === 'edit',
    isCreationMode: mode === 'create',
    isActionButtonDisabled,
    handleInputChange,
    handleSelectChange,
    handlePrimaryButtonClick,
    handleCancel: reset,
    handleDelete,
  }
}
