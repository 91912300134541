/** @jsxImportSource @emotion/react */
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Button } from 'reactstrap'
import { useToolbarController } from './Toolbar.controller'
import { styles } from './Toolbar.styles'

const TooltipTrigger = ({ text, children }) => {
  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 250 }}
      overlay={<Tooltip css={styles.tooltip}>{text}</Tooltip>}
    >
      {children}
    </OverlayTrigger>
  )
}

export const Toolbar = () => {
  const { isDisabled, handleChangePassword } = useToolbarController()

  return (
    <div css={styles.container}>
      <Button css={styles.button} disabled={isDisabled} onClick={handleChangePassword} color="link">
        <TooltipTrigger text="Change password">
          <i css={styles.icon} className="now-ui-icons objects_key-25" />
        </TooltipTrigger>
      </Button>
    </div>
  )
}
