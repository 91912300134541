import { createContext, useReducer, useCallback, useMemo, useEffect, useContext } from 'react'
import { showErrorAlert } from 'utils'
import { useQuery } from '@tanstack/react-query'
import { getStatus } from 'api'
import { GlobalContext } from '../global/GlobalContext'
import { rulesReducer } from './RulesReducer'

export const RulesContext = createContext({})

const RULES_INITIAL_STATE = {
  selectedBaseline: null,
  selectedStatus: null,
  userIsOwnerForSelectedBaseline: false,
  selectedParameters: [],
  keyWordFilter: '',
  selectedCell: null,
  statusListFiltered: [],
}

export const RulesProvider = ({ maxNumberSelectedParameters, children }) => {
  const [state, dispatch] = useReducer(rulesReducer, RULES_INITIAL_STATE)
  const { user } = useContext(GlobalContext)

  const { data: status } = useQuery(['status'], getStatus)

  const updateStatusList = useCallback(
    selectedOptions => {
      const updatedStatusList = state.statusListFiltered.map(st => ({
        ...st,
        checked: selectedOptions.some(option => option.label === st.label),
      }))
      dispatch({ type: 'UPDATE_STATUS_LIST', payload: updatedStatusList })
    },
    [state.statusListFiltered]
  )

  const selectBaseline = useCallback(
    baseline => {
      const selectedStatus = status?.find(el => el.status_id === baseline.status_id)
      dispatch({ type: 'SELECT_BASELINE', payload: { baseline, userId: user?.user_id, selectedStatus } })
    },
    [status, user?.user_id]
  )

  const selectParameter = useCallback(parameter => {
    dispatch({ type: 'SELECT_PARAMETER', payload: [parameter] })
  }, [])

  const addParameter = useCallback(
    parameter => {
      if (state.selectedParameters.length === maxNumberSelectedParameters) {
        showErrorAlert({ text: 'Number of selected parameters exceeded' })
        return
      }

      dispatch({ type: 'SELECT_PARAMETER', payload: [...state.selectedParameters, parameter] })
    },
    [maxNumberSelectedParameters, state.selectedParameters]
  )

  const removeParameter = useCallback(ruleId => {
    dispatch({ type: 'REMOVE_PARAMETER', payload: ruleId })
  }, [])

  const removeAllParameters = useCallback(() => {
    dispatch({ type: 'REMOVE_ALL_PARAMETERS' })
  }, [])

  const overloadParameter = useCallback(parameter => {
    dispatch({ type: 'OVERLOAD_PARAMETER', payload: parameter })
  }, [])

  const addKeywordFilter = useCallback(filter => {
    dispatch({ type: 'ADD_KEYWORD_FILTER', payload: filter })
  }, [])

  const selectCell = useCallback(cell => {
    dispatch({ type: 'SELECT_CELL', payload: cell })
  }, [])

  const userIsOwnerForSelectedBaseline = useMemo(() => {
    if (!state.selectedBaseline || !user) return false
    return state.selectedBaseline.owner_id === user.user_id
  }, [state.selectedBaseline, user])

  const contextValue = useMemo(
    () => ({
      ...state,
      userIsOwnerForSelectedBaseline,
      selectBaseline,
      selectParameter,
      addParameter,
      removeParameter,
      removeAllParameters,
      overloadParameter,
      addKeywordFilter,
      selectCell,
      updateStatusList,
    }),
    [
      state,
      userIsOwnerForSelectedBaseline,
      selectBaseline,
      selectParameter,
      addParameter,
      removeParameter,
      removeAllParameters,
      overloadParameter,
      addKeywordFilter,
      selectCell,
      updateStatusList,
    ]
  )

  useEffect(() => {
    if (status !== undefined) {
      const transformedStatus = status.map(st => ({
        value: st.name,
        label: st.name,
        checked: true,
      }))
      dispatch({ type: 'UPDATE_STATUS_LIST', payload: transformedStatus })
    }
  }, [status])

  return <RulesContext.Provider value={contextValue}>{children}</RulesContext.Provider>
}
