/** @jsxImportSource @emotion/react */
import { Label } from 'reactstrap'
import { Button } from 'components'
import { EDITOR_ROLE_PRIORITY } from 'utils/constants'
import { useParameterFormController } from './ParameterForm.controller'
import { StyledTextarea, StyledButtonWrapper, styles } from './ParameterForm.styles'

export const ParameterForm = () => {
  const {
    field,
    comment,
    isReadOnly,
    isMutationLoading,
    handleFieldChange,
    handleCommentChange,
    handlePrimaryButton,
    handleCancel,
  } = useParameterFormController()

  return (
    <>
      <Label css={styles.columnLabel}>{field?.label}</Label>
      <StyledTextarea
        css={styles.textarea}
        placeholder={field?.label}
        value={field?.value || ''}
        onChange={handleFieldChange}
        disabled={isReadOnly}
      />
      <Label css={styles.label}>Comments</Label>
      <StyledTextarea
        css={styles.textarea}
        placeholder="Comments"
        value={comment}
        onChange={handleCommentChange}
        disabled={isReadOnly}
      />
      <StyledButtonWrapper css={styles.buttonWrapper}>
        <Button outline isCancelButton color="danger" handleClick={handleCancel} isDisabled={isMutationLoading}>
          Cancel
        </Button>
        <Button
          handleClick={handlePrimaryButton}
          isDisabled={!field?.value || isReadOnly}
          isLoading={isMutationLoading}
          priority={EDITOR_ROLE_PRIORITY}
        >
          Save
        </Button>
      </StyledButtonWrapper>
    </>
  )
}
