import { useContext, useMemo } from 'react'
import { GlobalContext } from 'context'
import { Layout, PrivateRoute } from 'components'
import {
  VIEWER_ROLE_PRIORITY,
  EDITOR_ROLE_PRIORITY,
  ADVANCED_ROLE_PRIORITY,
  ADMIN_ROLE_PRIORITY,
} from 'utils/constants'
import RuleValidation from 'views/RuleValidation'
import ParameterEditor from 'views/ParameterEditor'
import KeywordEditor from 'views/KeywordEditor'
import BaselineHistory from 'views/BaselineHistory'
import BaselineEditor from 'views/BaselineEditor'
import BulkLoad from 'views/BulkLoad'
import AdvancedBulkLoad from 'views/AdvancedBulkLoad'
import UserEditor from 'views/UserEditor'
import DataTypes from 'views/DataTypes'

const appRoutes = [
  {
    id: 'rules_validation',
    path: '/rules/validation',
    name: 'Rule Validation',
    icon: 'now-ui-icons design-2_ruler-pencil',
    component: RuleValidation,
    priority: VIEWER_ROLE_PRIORITY,
  },
  {
    id: 'parameters_editor',
    path: '/parameters/editor',
    name: 'Parameter Editor',
    icon: 'now-ui-icons design_bullet-list-67',
    component: ParameterEditor,
    priority: VIEWER_ROLE_PRIORITY,
  },
  {
    id: 'keywords_editor',
    path: '/keywords/editor',
    name: 'Keyword editor',
    icon: 'now-ui-icons shopping_tag-content',
    component: KeywordEditor,
    priority: VIEWER_ROLE_PRIORITY,
  },
  {
    id: 'data_types',
    path: '/types/editor',
    name: 'Data Types',
    icon: 'now-ui-icons education_agenda-bookmark',
    component: DataTypes,
    priority: ADVANCED_ROLE_PRIORITY,
  },
  {
    id: 'baselines_history',
    path: '/baselines/history',
    name: 'Baseline History',
    icon: 'now-ui-icons education_glasses',
    component: BaselineHistory,
    priority: VIEWER_ROLE_PRIORITY,
  },
  {
    id: 'baselines_editor',
    path: '/baselines/editor',
    name: 'Baseline Editor',
    icon: 'now-ui-icons files_paper',
    component: BaselineEditor,
    priority: VIEWER_ROLE_PRIORITY,
  },
  {
    id: 'bulk_load',
    path: '/load/basic',
    name: 'Bulk Load',
    icon: 'now-ui-icons arrows-1_cloud-upload-94',
    component: BulkLoad,
    priority: EDITOR_ROLE_PRIORITY,
  },
  {
    id: 'advanced_bulk_load',
    path: '/load/advanced',
    name: 'Advanced Bulk Load',
    icon: 'now-ui-icons design_vector',
    component: AdvancedBulkLoad,
    priority: EDITOR_ROLE_PRIORITY,
  },
  {
    id: 'users_editor',
    path: '/users/editor',
    name: 'User editor',
    icon: 'now-ui-icons users_single-02',
    component: UserEditor,
    priority: ADMIN_ROLE_PRIORITY,
    atBottom: true,
  },
]

export const useRoutePaths = () => {
  const { getUserRole, highestPriority } = useContext(GlobalContext)
  const userRole = getUserRole()

  const appRoutesFilteredByPriority = useMemo(
    () =>
      appRoutes.filter(route => {
        if (!userRole) {
          // all the routes are included due redirect to login will be executed on PrivateRoute component
          return true
        }
        const comparator = route.priority || highestPriority
        return userRole.priority >= comparator
      }),
    [highestPriority, userRole]
  )

  const memoizedRoutes = useMemo(() => {
    return appRoutesFilteredByPriority.map(route => {
      return (
        <PrivateRoute path={route.path} key={route.id}>
          <Layout routeList={appRoutesFilteredByPriority} activeRouteName={route.name}>
            <route.component />
          </Layout>
        </PrivateRoute>
      )
    })
  }, [appRoutesFilteredByPriority])

  return { appRoutes: memoizedRoutes }
}
