import { GlobalContext } from 'context'
import { useEffect, useContext, useState } from 'react'

const DEFAULT_CARD_HEIGHT = {
  desktop: 'default',
  largeDesktop: 'default',
}

export const useCardController = ({ cardHeight, priority, onClose, alwaysVisible }) => {
  const { getUserRole } = useContext(GlobalContext)
  const userRole = getUserRole()

  const [isVisible, setIsVisible] = useState(true)
  const [isAccordionOpen, setIsAccordionOpen] = useState(true)

  const overriddenCardHeight = cardHeight ? { ...DEFAULT_CARD_HEIGHT, ...cardHeight } : DEFAULT_CARD_HEIGHT

  const toggleAccordionVisibility = () => setIsAccordionOpen(prevState => !prevState)

  const handleClose = () => {
    if (!alwaysVisible) setIsVisible(false)
    if (onClose) onClose()
  }

  useEffect(() => {
    if (userRole?.priority < priority) {
      setIsVisible(false)
    }
  }, [priority, userRole?.priority])

  return {
    isVisible,
    isAccordionOpen,
    overriddenCardHeight,
    handleClose,
    toggleAccordionVisibility,
  }
}
