/** @jsxImportSource @emotion/react */
import { useMemo } from 'react'
import { Row, Col } from 'reactstrap'
import { SimpleTable } from 'futurekit'
import CodeMirror from '@uiw/react-codemirror'
import { python } from '@codemirror/lang-python'
import { Card, Spinner, EmptyState, Button } from 'components'
import { showPrompt } from 'utils'
import { EDITOR_ROLE_PRIORITY, HEIGHT_300 } from 'utils/constants'
import { useRuleDetailsController } from './RuleDetails.controller'
import { StyledTitle, StyledTextarea, StyledButtonWrapper, StyledAlertBody, styles } from './RuleDetails.styles'

export const RuleDetails = ({ selectedParameter, alwaysVisible = false }) => {
  const {
    keywordsData,
    isKeywordsLoading,
    isValidationLoading,
    validationResponse,
    telefonicaValue,
    rule,
    pseudocodeValue,
    isPseudocodeError,
    isReadOnly,
    handleRemove,
    handleKeywordTableRowClick,
    handleTelefonicaValueChange,
    handleRuleChange,
    handleValidate,
    handleSaveValidation,
    resetValidation,
  } = useRuleDetailsController({ selectedParameter })

  const alert = useMemo(() => {
    if (!validationResponse) {
      return
    }
    const title = (
      <>
        <div>{`Validation was ${validationResponse.status === 'SUCCESS' ? 'successful' : 'failed'}`}</div>
        <div>Do you want to save it?</div>
      </>
    )
    const body = (
      <StyledAlertBody css={styles.alertBody}>
        {(validationResponse.keywords_undefined?.length && (
          <>
            <div>New keywords:</div>
            <div>{validationResponse.keywords_undefined.join(', ')}</div>
          </>
        )) ||
          null}
        {(validationResponse.keywords_defined?.length && (
          <>
            <div>Keywords defined:</div>
            <div>{validationResponse.keywords_defined.join(', ')}</div>
          </>
        )) ||
          null}
        <div>{`${validationResponse.status === 'SUCCESS' ? 'Pseudocode' : 'Error'}:`}</div>
        <textarea defaultValue={validationResponse.pseudocode || validationResponse.error_message} disabled />
      </StyledAlertBody>
    )

    showPrompt({
      icon: validationResponse.status === 'SUCCESS' ? 'success' : 'error',
      title,
      body,
      callbackAsyncFn: handleSaveValidation,
      onClose: resetValidation,
    })
  }, [validationResponse, handleSaveValidation, resetValidation])

  const keywords = useMemo(() => {
    if (!!selectedParameter && isKeywordsLoading) {
      return <Spinner />
    }
    if (!selectedParameter || (!!selectedParameter && !keywordsData.length)) {
      return <EmptyState />
    }
    return (
      <SimpleTable
        options={{ showHead: false, readOnlyMode: false, highlightSelection: false }}
        data={keywordsData}
        columns={['name']}
        onRowClick={handleKeywordTableRowClick}
        css={styles.table}
      />
    )
  }, [handleKeywordTableRowClick, isKeywordsLoading, keywordsData, selectedParameter])

  const pseudocode = useMemo(() => {
    if (isPseudocodeError) {
      return (
        <>
          <StyledTitle css={styles.title}>Error message</StyledTitle>
          <StyledTextarea value={pseudocodeValue} css={styles.pseudocode} disabled />
        </>
      )
    }
    return (
      <>
        <StyledTitle css={styles.title}>Pseudocode</StyledTitle>
        <CodeMirror
          extensions={[python()]}
          height={`${HEIGHT_300}px`}
          placeholder="Pseudocode"
          value={pseudocodeValue}
          editable={false}
          css={styles.codeMirror}
        />
      </>
    )
  }, [isPseudocodeError, pseudocodeValue])

  return (
    <Card
      title={
        (selectedParameter && `${selectedParameter.mo_class_name} - ${selectedParameter.parameter_name}`) ||
        'Rule details'
      }
      cardHeight={{ largeDesktop: 'responsive', desktop: 'responsive' }}
      enableAccordionBehavior
      enableCloseBehavior={!!selectedParameter}
      alwaysVisible={alwaysVisible}
      onClose={handleRemove}
    >
      {alert}
      <Row className="flex-row">
        <Col xs={12} md={12} lg={2} className="order-4 order-lg-1 mt-4 mt-lg-0">
          {keywords}
        </Col>
        <Col xs={12} md={4} lg={3} className="order-1 order-lg-2 mb-3 mb-md-0">
          <StyledTitle css={styles.title}>Telefonica Value</StyledTitle>
          <StyledTextarea
            value={telefonicaValue}
            onChange={handleTelefonicaValueChange}
            css={styles.textarea}
            disabled={!selectedParameter || isReadOnly}
          />
        </Col>
        <Col xs={12} md={4} lg={4} className="order-2 order-lg-3 mb-3 mb-md-0">
          <StyledTitle css={styles.title}>Regla</StyledTitle>
          <StyledTextarea
            value={rule}
            onChange={handleRuleChange}
            css={styles.textarea}
            disabled={!selectedParameter || isReadOnly}
          />
        </Col>
        <Col xs={12} md={4} lg={3} className="order-3 order-lg-4">
          {pseudocode}
        </Col>
      </Row>
      <StyledButtonWrapper css={styles.buttonWrapper}>
        <Button
          handleClick={handleValidate}
          isDisabled={!selectedParameter || !rule.length || isReadOnly}
          isLoading={isValidationLoading}
          priority={EDITOR_ROLE_PRIORITY}
        >
          Validate
        </Button>
      </StyledButtonWrapper>
    </Card>
  )
}
