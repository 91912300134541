import { useContext, useCallback, useMemo } from 'react'
import { useMutation } from '@tanstack/react-query'
import { getColumnsEquivalence } from 'api'
import { AdvancedBulkLoadContext } from 'context'

export const useDetailsController = () => {
  const {
    validate,
    file,
    formDetails,
    baselineDetails,
    columnsEquivalence,
    selectedBaseline,
    toggleValidate,
    uploadFile,
    setFormDetails,
    setBaselineDetails,
    addColumnsEquivalence,
    selectBaseline,
    toggleImport,
  } = useContext(AdvancedBulkLoadContext)

  const { mutate: fetchColumnsEquivalence, isLoading: isColumnsEquivalenceLoading } = useMutation(
    getColumnsEquivalence,
    {
      onSuccess: addColumnsEquivalence,
    }
  )

  const baselineListData = useMemo(() => {
    if (!columnsEquivalence) {
      return []
    }
    return Object.keys(columnsEquivalence).map(element => ({
      value: element,
      label: element,
    }))
  }, [columnsEquivalence])

  const handleFormDetailsChange = useCallback(
    (selected, event) => {
      setFormDetails({ name: event.name, value: selected.value })
    },
    [setFormDetails]
  )

  const handleBaselineDetailsChange = useCallback(
    (arg1, arg2) => {
      if (arg1.props) {
        // Switch field
        setBaselineDetails({ name: arg1.props.name, value: arg2 })
        return
      }
      if (!arg1.target) {
        // Select field
        setBaselineDetails({ name: arg2.name, value: arg1.value })
        return
      }
      // Input field
      const { name, value } = arg1.target
      setBaselineDetails({ name, value })
    },
    [setBaselineDetails]
  )

  const handleBaselineSelectorChange = useCallback(
    baselineSelected => {
      selectBaseline(baselineSelected.value)
    },
    [selectBaseline]
  )

  const handleClick = useCallback(() => {
    fetchColumnsEquivalence({ ...formDetails, file })
  }, [formDetails, fetchColumnsEquivalence, file])

  return {
    validate,
    formDetails,
    baselineDetails,
    baselineListData,
    selectedBaseline,
    isButtonDisabled: !file || Object.values(formDetails).some(field => !field),
    isLoading: isColumnsEquivalenceLoading,
    toggleValidate,
    toggleImport,
    uploadFile,
    handleFormDetailsChange,
    handleBaselineDetailsChange,
    handleBaselineSelectorChange,
    handleClick,
  }
}
