import styled from '@emotion/styled'
import Select from 'react-select'

export const StyledFilter = styled.div``
const filterStyles = {
  height: '50px',
  marginTop: '-10px',
  marginBottom: '-40px',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  columnGap: '10px',

  '@media (max-width: 1024px)': {
    marginBottom: '100px',
    flexDirection: 'column',
    rowGap: '10px',
  },
}

export const StyledSelect = styled(Select)``
const selectStyles = {
  minWidth: '12rem',
  maxWidth: '20rem',
  width: 'calc(25%)',

  '@media (max-width: 1024px)': {
    width: '100%',
    maxWidth: '100%',
  },
}

export const styles = {
  filter: filterStyles,
  select: selectStyles,
}
