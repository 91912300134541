import styled from '@emotion/styled'

export const StyledWrapper = styled.div``

const wrapperStyles = (height = '100%') => ({
  height,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const innerStyles = (size = '3rem') => ({
  height: size,
  width: size,
  fill: 'rgba(0,0,0,0.2)',
})

export const styles = {
  wrapper: wrapperStyles,
  inner: innerStyles,
}
