import styled from '@emotion/styled'
import { Row, Col } from 'reactstrap'

export const StyledRow = styled(Row)``

export const StyledCol = styled(Col)``

const rowStyles = {
  minHeight: '60px',
  alignItems: 'center',

  '@media (max-width: 768px)': {
    minHeight: 'initial',
  },
}

const colStyles = {
  display: 'flex',
  justifyContent: 'flex-end',

  '@media (max-width: 575px)': {
    justifyContent: 'flex-start',
  },
}

const tableStyles = {
  maxHeight: 565,
  height: 'calc(100% - 60px)',

  '@media (max-width: 575px)': {
    height: 'calc(100% - 90px)',
  },
}

export const styles = {
  row: rowStyles,
  col: colStyles,
  table: tableStyles,
}
