import styled from '@emotion/styled'

const columnLabelStyles = {
  fontSize: '14px !important',
  fontWeight: 700,
  color: '#f96332 !important',
}

const labelStyles = {
  marginTop: 20,
}

export const StyledTextarea = styled.textarea``
const textareaStyles = {
  width: '100%',
  resize: 'none',
  height: 250,
  padding: '10px 15px',
}

export const StyledButtonWrapper = styled.div``
const buttonWrapperStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: 10,
  marginTop: 'auto',
}

export const styles = {
  columnLabel: columnLabelStyles,
  label: labelStyles,
  textarea: textareaStyles,
  buttonWrapper: buttonWrapperStyles,
}
