import { useContext, useEffect, useState } from 'react'
import { useQueryClient, useMutation } from '@tanstack/react-query'
import { CRUDManagementContext, GlobalContext } from 'context'
import { updateKeyword, createKeyword } from 'api'
import { showToast } from 'utils'

export const useKeywordFormController = () => {
  const queryClient = useQueryClient()
  const { isUserRoleEqualOrGreaterThanEditor } = useContext(GlobalContext)
  const { selected, mode, deleteSelected, activateCreationMode } = useContext(CRUDManagementContext)

  const [name, setName] = useState('')
  const [definition, setDefinition] = useState('')
  const [description, setDescription] = useState('')
  const [dataType, setDataType] = useState('')
  const [location, setLocation] = useState('')
  const [searchKey, setSearchKey] = useState('')

  let isPrimaryButtonDisabled = !definition || (mode === 'create' && (!name || !dataType))
  if (mode === 'default') {
    isPrimaryButtonDisabled = false
  }

  const cleanForm = () => {
    setName('')
    setDefinition('')
    setDescription('')
    setDataType('')
    setLocation('')
    setSearchKey('')
    deleteSelected()
  }

  const { mutate: update, isLoading: isUpdateLoading } = useMutation(updateKeyword, {
    onSuccess: response => {
      queryClient.setQueryData(['keywords'], data => {
        return data.map(element => (element.keyword_id === response.data.keyword_id ? response.data : element))
      })
      cleanForm()
      showToast(response.message)
    },
  })

  const { mutate: create, isLoading: isCreateLoading } = useMutation(createKeyword, {
    onSuccess: response => {
      queryClient.setQueryData(['keywords'], data => {
        return [...data, response.data]
      })
      cleanForm()
      showToast(response.message)
    },
  })

  const handleChange = event => {
    switch (event.target.name) {
      case 'name':
        setName(event.target.value)
        break
      case 'description':
        setDescription(event.target.value)
        break
      case 'data-type':
        setDataType(event.target.value)
        break
      case 'location':
        setLocation(event.target.value)
        break
      case 'search-key':
        setSearchKey(event.target.value)
        break
      default:
        break
    }
  }

  const handlePrimaryButton = () => {
    switch (mode) {
      case 'edit':
        update({
          keywordId: selected.keyword_id,
          definition,
          description,
          data_type: dataType,
          location,
          search_key: searchKey,
        })
        break
      case 'create':
        create({
          name,
          definition,
          description,
          data_type: dataType,
          location,
          search_key: searchKey,
        })
        break
      default:
        activateCreationMode()
        break
    }
  }

  useEffect(() => {
    setDefinition(selected?.definition || '')
    setDescription(selected?.description || '')
    setDataType(selected?.data_type || '')
    setLocation(selected?.location || '')
    setSearchKey(selected?.search_key || '')
  }, [selected])

  return {
    name,
    definition,
    description,
    dataType,
    location,
    searchKey,
    isDefaultMode: mode === 'default',
    isEditMode: mode === 'edit',
    isCreationMode: mode === 'create',
    isPrimaryButtonDisabled,
    isLoading: isUpdateLoading || isCreateLoading,
    isReadOnly: !isUserRoleEqualOrGreaterThanEditor,
    setDefinition,
    handleChange,
    handleCancel: cleanForm,
    handlePrimaryButton,
  }
}
