import { FormGroup, Input } from 'reactstrap'
import { matchSorter } from 'match-sorter'

const renderColumnFilter = ({ placeholder, ref, innerRef, disabled, onFilterChange, resetRowLimit }) =>
  function returnFilter({ column: { setFilter } }) {
    return (
      <FormGroup>
        <Input
          ref={ref}
          innerRef={innerRef}
          placeholder={placeholder || 'Search'}
          type="search"
          onChange={e => {
            setFilter(e.target.value || undefined)
            resetRowLimit()
            if (onFilterChange) onFilterChange()
          }}
          disabled={disabled}
        />
      </FormGroup>
    )
  }

function fuzzyTextFilterFn(rows, id, filterValue) {
  // This is an autoRemove method on the filter function that
  // when given the new filter value and returns true, the filter
  // will be automatically removed. Normally this is just an undefined
  // check, but here, we want to remove the filter if it's not a number

  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}
fuzzyTextFilterFn.autoRemove = val => !val

export { renderColumnFilter, fuzzyTextFilterFn }
