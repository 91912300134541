/** @jsxImportSource @emotion/react */
import { useFilterController } from './Filter.controller'
import { StyledFilter, StyledSelect, styles } from './Filter.styles'

export const Filter = () => {
  const {
    selectedTechnology,
    selectedManufacturer,
    selectedHardwareType,
    technologyListData,
    manufacturerListData,
    hardwareTypeListData,
    isTechnologyLoading,
    isManufacturerLoading,
    isHardwareTypeLoading,
    selectTechnology,
    selectManufacturer,
    selectHardwareType,
  } = useFilterController()

  return (
    <StyledFilter css={styles.filter}>
      <StyledSelect
        css={styles.select}
        className="react-select primary"
        classNamePrefix="react-select"
        options={technologyListData}
        value={selectedTechnology}
        onChange={selectTechnology}
        isDisabled={isTechnologyLoading}
        isSearchable={false}
      />
      <StyledSelect
        css={styles.select}
        className="react-select primary"
        classNamePrefix="react-select"
        options={manufacturerListData}
        value={selectedManufacturer}
        onChange={selectManufacturer}
        isDisabled={isManufacturerLoading}
        isSearchable={false}
      />
      <StyledSelect
        css={styles.select}
        className="react-select primary"
        classNamePrefix="react-select"
        options={hardwareTypeListData}
        value={selectedHardwareType}
        onChange={selectHardwareType}
        isDisabled={isHardwareTypeLoading}
        isSearchable={false}
      />
    </StyledFilter>
  )
}
