import { tfSpainApi } from './config'

// Baselines
const getBaselines = () => tfSpainApi.get('/baseline').then(res => res.data)

const getParametersByBaseline = ({ queryKey }) => tfSpainApi.get(`/baseline/${queryKey[1]}/rules`).then(res => res.data)

const getParameterFilterByBaseline = ({ queryKey }) =>
  tfSpainApi.get(`/baseline/${queryKey[1]}/rules/filter`).then(res => res.data)

const createBaseline = params => tfSpainApi.post('/baseline', params).then(res => res.data)

const updateBaseline = variables => {
  const { baselineId, ...params } = variables
  return tfSpainApi.put(`/baseline/${baselineId}`, params).then(res => res.data)
}

const deleteBaseline = ({ baselineId }) => tfSpainApi.delete(`/baseline/${baselineId}`).then(res => res.data)

const cloneBaseline = variables => {
  const { baselineId, ...params } = variables
  return tfSpainApi.post(`/baseline/${baselineId}/clone`, params).then(res => res.data)
}

const validateBaseline = ({ baselineId }) => tfSpainApi.post(`/baseline/${baselineId}/validate`).then(res => res.data)

const searchBaselineWithProdStatus = variables => {
  const { baselineId, ...params } = variables
  return tfSpainApi.get(`/baseline/${baselineId}/status/production`, { params }).then(res => res.data)
}

// Keywords
const getKeywords = () => tfSpainApi.get('/keywords').then(res => res.data)

const getKeywordsByBaseline = ({ queryKey }) =>
  tfSpainApi.get(`/baseline/${queryKey[1]}/keywords`).then(res => res.data)

const getKeywordsByParameter = ({ queryKey }) => tfSpainApi.get(`/rules/${queryKey[1]}/keywords`).then(res => res.data)

const updateKeyword = variables => {
  const { keywordId, ...params } = variables
  return tfSpainApi.put(`/keywords/${keywordId}`, params).then(res => res.data)
}

const createKeyword = params => tfSpainApi.post('/keywords', params).then(res => res.data)

const deleteKeywords = params => tfSpainApi.post('/keywords/delete', params).then(res => res.data)

// Rules
const getRuleDetails = ({ queryKey }) => tfSpainApi.get(`/rules/${queryKey[1]}`).then(res => res.data)

const updateParameter = variables => {
  const { ruleId, ...params } = variables
  return tfSpainApi.put(`/rules/${ruleId}`, params).then(res => res.data)
}

const restoreParameter = variables => {
  const { ruleId, ...params } = variables
  return tfSpainApi.post(`/rules/${ruleId}/restore`, params).then(res => res.data)
}

const validateParameter = params => {
  const { ruleId, telefonicaValue, rule } = params
  return tfSpainApi
    .post(`/rules/${ruleId}/validate`, { telefonica_value: telefonicaValue, regla: rule })
    .then(res => res.data)
}

const saveValidation = variables => {
  const { ruleId, ...params } = variables
  delete params.status
  delete params.error_code

  return tfSpainApi.post(`/rules/${ruleId}/validate/save`, params).then(res => res.data)
}

// History
const getBaselineHistory = variables => {
  const restParams = [...variables.queryKey]
  restParams.shift()
  const params = {
    technology: restParams[0],
    manufacturer: restParams[1],
    hardwaretype: restParams[2],
  }
  return tfSpainApi.get('/baseline/historical', { params }).then(res => res.data)
}

const getBaselineHistoryFilter = variables => {
  const restParams = [...variables.queryKey]
  restParams.shift()
  const params = {
    technology: restParams[0],
    manufacturer: restParams[1],
    hardwaretype: restParams[2],
  }

  return tfSpainApi.get('/baseline/historical/filter', { params }).then(res => res.data)
}

const getBaselineHistoryByBaseline = ({ queryKey }) =>
  tfSpainApi.get(`/baseline/${queryKey[1]}/historical`).then(res => res.data)

const getBaselineHistoryFilterByBaseline = ({ queryKey }) =>
  tfSpainApi.get(`/baseline/${queryKey[1]}/historical/filter`).then(res => res.data)

const getBaselineStatusHistory = () => tfSpainApi.get('/baseline/status/historical').then(res => res.data)

const getBaselineStatusHistoryFilter = () => tfSpainApi.get('/baseline/status/historical/filter').then(res => res.data)

// DataTypes
const getDataTypes = () => tfSpainApi.get('/datatypes').then(res => res.data)

const getDataTypesFilter = () => tfSpainApi.get('/datatypes/filter').then(res => res.data)

const createDataType = params => tfSpainApi.post('/datatypes', params).then(res => res.data)

const updateDataType = variables => {
  const { dataTypeId, ...params } = variables
  return tfSpainApi.put(`/datatypes/${dataTypeId}`, params).then(res => res.data)
}

const deleteDataType = params => tfSpainApi.post('/datatypes/delete', params).then(res => res.data)

// Status
const getStatus = () => tfSpainApi.get('/baseline/status').then(res => res.data)

const getStatusFilter = ({ queryKey }) => tfSpainApi.get(`/baseline/${queryKey[1]}/status/filter`).then(res => res.data)

// Filters
const getTechnologyFilter = () => tfSpainApi.get('/baseline/filter/technology').then(res => res.data)

const getManufacturerFilter = () => tfSpainApi.get('/baseline/filter/manufacturer').then(res => res.data)

const getHardwareTypeFilter = () => tfSpainApi.get('/baseline/filter/hardwaretype').then(res => res.data)

// Columns
const getColumnsEquivalence = params => {
  const { file, manufacturer, technology } = params
  const formData = new FormData()
  formData.append('file', file)
  formData.append('manufacturer', manufacturer)
  formData.append('technology', technology)

  return tfSpainApi
    .post('/baseline/columns_equivalence', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(res => res.data)
}

// Login
const logIn = params => tfSpainApi.post('/login', params).then(res => res.data)

const changePassword = params => tfSpainApi.put(`/users/password`, params).then(res => res.data)

const changeUserPassword = variables => {
  const { userId, ...params } = variables
  return tfSpainApi.put(`/users/${userId}/password`, params).then(res => res.data)
}

// Users
const getMe = () => tfSpainApi.get('/users/me').then(res => res.data)

const getUsers = () => tfSpainApi.get('/users').then(res => res.data)

const getUsersAllowedToBeOwner = () => tfSpainApi.get('/users/owner/allowed').then(res => res.data)

const createUser = params => tfSpainApi.post('/users', params).then(res => res.data)

const updateUser = variables => {
  const { userId, ...params } = variables
  return tfSpainApi.put(`/users/${userId}`, params).then(res => res.data)
}

const deleteUser = ({ userId }) => tfSpainApi.delete(`/users/${userId}`).then(res => res.data)

// Roles
const getRoles = () => tfSpainApi.get('/roles').then(res => res.data)

// Files
const uploadBaselineFile = params => {
  const { baselineId, file, validate } = params
  const formData = new FormData()
  formData.append('file', file)
  formData.append('validation', validate)

  return tfSpainApi
    .post(`/baseline/${baselineId}/load`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(res => res.data)
}

const uploadKeywordsFile = params => {
  const { file } = params
  const formData = new FormData()
  formData.append('file', file)

  return tfSpainApi
    .post('/keywords/load', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(res => res.data)
}

const uploadAdvancedBaselineFile = params => {
  const { file, validate, columnsEquivalence } = params
  const formData = new FormData()
  formData.append('file', file)
  formData.append('validation', validate)
  formData.append('data', JSON.stringify(columnsEquivalence))

  return tfSpainApi
    .post('/baseline/load/advanced', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(res => res.data)
}

export {
  getBaselines,
  getParametersByBaseline,
  getParameterFilterByBaseline,
  createBaseline,
  updateBaseline,
  deleteBaseline,
  cloneBaseline,
  validateBaseline,
  searchBaselineWithProdStatus,
  getKeywords,
  getKeywordsByBaseline,
  getKeywordsByParameter,
  updateKeyword,
  createKeyword,
  deleteKeywords,
  getRuleDetails,
  updateParameter,
  restoreParameter,
  validateParameter,
  getStatus,
  getStatusFilter,
  saveValidation,
  getBaselineHistory,
  getBaselineHistoryFilter,
  getBaselineHistoryByBaseline,
  getBaselineHistoryFilterByBaseline,
  getBaselineStatusHistory,
  getBaselineStatusHistoryFilter,
  getDataTypes,
  getDataTypesFilter,
  createDataType,
  updateDataType,
  deleteDataType,
  getTechnologyFilter,
  getManufacturerFilter,
  getHardwareTypeFilter,
  getColumnsEquivalence,
  logIn,
  changePassword,
  changeUserPassword,
  getMe,
  getUsers,
  getUsersAllowedToBeOwner,
  createUser,
  updateUser,
  deleteUser,
  getRoles,
  uploadBaselineFile,
  uploadKeywordsFile,
  uploadAdvancedBaselineFile,
}
