import { useContext } from 'react'
import { useQuery } from '@tanstack/react-query'
import { getBaselines } from 'api'
import { CRUDManagementContext } from 'context'

export const useBaselineListController = () => {
  const { data, isLoading } = useQuery(['baselines'], getBaselines)

  const { selected, select, mode, deleteSelected, activateCreationMode } = useContext(CRUDManagementContext)

  return {
    baselines: data,
    isBaselinesLoading: isLoading,
    select,
    deleteSelected,
    selectedRowIds: selected ? [selected.baseline_id] : [],
    isCreationMode: mode === 'create',
    activateCreationMode,
  }
}
