import axios from 'axios'
import { getToken, removeToken, showErrorAlert } from 'utils'

const BASE_URL = process.env.REACT_APP_API_URL
const NODE_ENV = process.env.REACT_APP_NODE_ENV

const tfSpainApi = axios.create({
  baseURL: BASE_URL,
})

tfSpainApi.interceptors.request.use(axiosConfig => {
  const config = axiosConfig ? { ...axiosConfig } : {}
  if (!config.headers) {
    config.headers = {}
  }

  const token = getToken()
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})

tfSpainApi.interceptors.response.use(
  response => response,
  error => {
    if (!error.response) {
      showErrorAlert({ title: error.code })
      throw new Error(error)
    }
    if (error.response.status === 401) {
      showErrorAlert({ title: 'Session Expired', text: 'Please login again' }).then(() => {
        removeToken()
        window.location = '/login'
      })
      return
    }

    showErrorAlert({ title: `${error.response.status} Error`, text: error.response.data.error })
    if (NODE_ENV === 'development') {
      // eslint-disable-next-line
      console.error("FC error message: ", error.response.data.message)
    }
    throw new Error(error)
  }
)

export { tfSpainApi }
