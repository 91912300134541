import { useRef, useCallback, useState } from 'react'

export const useFileUploadController = ({ isDisabled, callbackFn }) => {
  const [file, setFile] = useState(null)
  const containerRef = useRef(null)
  const fileInputRef = useRef(null)

  const uploadFile = useCallback(
    newFile => {
      setFile(newFile)
      callbackFn(newFile)
      containerRef.current.dataset.dragging = 'false'
    },
    [setFile, callbackFn]
  )

  const handleButtonClick = useCallback(() => {
    fileInputRef.current?.click()
  }, [fileInputRef])

  const handleButtonDrop = useCallback(
    event => {
      event.preventDefault()
      uploadFile(event.dataTransfer.files[0])
    },
    [uploadFile]
  )

  const handleDrop = useCallback(
    event => {
      uploadFile(event.target.files[0])
    },
    [uploadFile]
  )

  const handleDragOver = useCallback(event => {
    event.preventDefault()
    containerRef.current.dataset.dragging = 'true'
  }, [])

  const handleDragLeave = useCallback(event => {
    event.preventDefault()
    containerRef.current.dataset.dragging = 'false'
  }, [])

  const handleIconClick = useCallback(() => {
    if (isDisabled) return

    setFile(null)
    callbackFn(null)
  }, [isDisabled, callbackFn])

  return {
    file,
    containerRef,
    fileInputRef,
    handleButtonClick,
    handleButtonDrop,
    handleDrop,
    handleDragOver,
    handleDragLeave,
    handleIconClick,
  }
}
