const BaselineHistoryReducer = (state, action) => {
  switch (action.type) {
    case 'SELECT_INITIAL_OPTIONS':
      return {
        ...state,
        selectedTechnology: action.payload.selectedTechnology,
        selectedManufacturer: action.payload.selectedManufacturer,
        selectedHardwareType: action.payload.selectedHardwareType,
      }
    case 'SELECT_TECHNOLOGY':
      return {
        ...state,
        selectedTechnology: action.payload,
      }
    case 'SELECT_MANUFACTURER':
      return {
        ...state,
        selectedManufacturer: action.payload,
      }
    case 'SELECT_HARDWARE_TYPE':
      return {
        ...state,
        selectedHardwareType: action.payload,
      }
    default:
      return state
  }
}

export { BaselineHistoryReducer }
