import styled from '@emotion/styled'

export const StyledTableWrapper = styled.div``
const resizerStyles = {
  '&.table-resizer': {
    display: 'inline-block',
    position: 'absolute',
    width: '36px',
    top: 0,
    bottom: 0,
    right: '-18px',
    cursor: 'col-resize',
    zIndex: 10,
  },
}
const footStyles = {
  '&.table-tfoot': {
    flex: '1 0 auto',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0 0 15px 0 rgba(0, 0, 0, 0.15)',

    '&.table-td': {
      borderRight: '1px solid rgba(0, 0, 0, 0.05)',

      '&:last-child': {
        borderRight: 0,
      },
    },
  },
}
const noDataStyles = {
  '&.table-noData': {
    display: 'block',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'rgba(255, 255, 255, 0.8)',
    transition: 'all 0.3s ease',
    zIndex: 1,
    pointerEvents: 'none',
    padding: '20px',
    color: 'rgba(0, 0, 0, 0.5)',
  },
}

const tableWrapperStyles = disabled => ({
  display: 'flex',
  flexDirection: 'column',
  pointerEvents: disabled ? 'none' : 'auto',
  height: '100%',
  position: 'relative',
  backgroundColor: 'transparent',

  '&.-striped .table-tr.-odd': {
    background: 'rgba(0, 0, 0, 0.03)',
  },
  ...resizerStyles,
  ...footStyles,
  ...noDataStyles,
})

export const StyledTable = styled.table``
const tableStyles = {
  display: 'flex',
  flex: 'auto 1',
  flexDirection: 'column',
  alignItems: 'stretch',
  width: '100%',
  borderCollapse: 'collapse',
  overflow: 'auto',
}

export const StyledTableRow = styled.tr``
const tableRowStyles = applyHoverStyle => ({
  display: 'inline-flex',
  flex: '1 0 auto',
  height: '3rem',
  maxHeight: '3rem',
  ...(applyHoverStyle && {
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.05)',
    },
  }),

  '&.table-tr-group': {
    display: 'flex',
    flex: '1 0 auto',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
})

export const StyledPaginationWrapper = styled.div``
const paginationStyles = {
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  columnGap: 3,
  marginTop: 10,

  '& button:hover': {
    cursor: 'pointer',
  },
}

const paginationButtonStyles = {
  border: 0,
  minWidth: 30,
  height: 30,
  paddingTop: 5,
  color: '#2c2c2c',
  background: 'transparent',

  '&:disabled': {
    height: 15,
    paddingTop: 0,
    color: '#9a9a9a',

    '&:hover': {
      cursor: 'not-allowed',
    },
  },
}

const paginationIconStyles = {
  fontSize: 16,
}

const paginationInfoStyles = {
  fontSize: 15,
  fontWeight: 700,
  fontFamily: 'Montserrat',
  color: '#2c2c2c',
  margin: '0 8px',
}

export const styles = {
  tableWrapper: tableWrapperStyles,
  table: tableStyles,
  tableRow: tableRowStyles,
  pagination: paginationStyles,
  paginationButton: paginationButtonStyles,
  paginationIcon: paginationIconStyles,
  paginationInfo: paginationInfoStyles,
}
