/** @jsxImportSource @emotion/react */
import { FutureTable } from 'futurekit'
import Switch from 'react-bootstrap-switch'
import { EmptyState, Spinner } from 'components'
import { StyledWrapper, StyledSwitchLabel, StyledButton, styles } from './DataTypeList.styles'
import { useDataTypeListController } from './DataTypeList.controller'

export const DataTypeList = ({ deleteMode, toggleDeleteMode }) => {
  const {
    dataTypes,
    filter,
    isDataTypesLoading,
    selectedRowIds,
    isCreationMode,
    isDeleteLoading,
    selectDataType,
    handleSelectAll,
    handleDelete,
  } = useDataTypeListController({
    deleteMode,
  })

  if (isDataTypesLoading) {
    return <Spinner />
  }
  if (!dataTypes || !dataTypes.length) {
    return <EmptyState size="5rem" />
  }
  return (
    <>
      <StyledWrapper css={styles.toolbar}>
        <StyledWrapper css={styles.switchWrapper}>
          <StyledSwitchLabel css={styles.switchLabel}>Delete mode</StyledSwitchLabel>
          <Switch onColor="future" value={deleteMode} onChange={toggleDeleteMode} />
        </StyledWrapper>
        <StyledButton outline color="danger" handleClick={handleSelectAll} css={styles.button(deleteMode, 10)}>
          Select all
        </StyledButton>
        <StyledButton
          handleClick={handleDelete}
          disabled={deleteMode && !selectedRowIds.length}
          css={styles.button(deleteMode)}
          isLoading={isDeleteLoading}
        >
          Delete
        </StyledButton>
      </StyledWrapper>
      <FutureTable
        data={dataTypes}
        globalFilter={filter}
        filterValue={isCreationMode ? '' : null}
        filterNameReachable="name"
        rowIdKey="data_type_id"
        selectedRowIds={selectedRowIds}
        onRowClick={selectDataType}
        tableConfig={{
          usePagination: true,
        }}
        columnsConfig={{
          isStriped: false,
          isVirtualized: true,
          selectionLimit: deleteMode ? dataTypes.length : 1,
          highlightSelection: true,
          autoSelection: true,
          isDisabled: isCreationMode,
          dynamicColumns: true,
          advancedFilter: true,
          columnsExcluded: ['data_type_id'],
        }}
        toolbarConfig={{
          showFilter: true,
          showOrder: true,
          showRowNumber: true,
        }}
        css={styles.table}
      />
    </>
  )
}
