import styled from '@emotion/styled'

export const StyledTableBody = styled.tbody``

const tableBodyStyles = disabled => ({
  display: 'flex',
  flex: '99999 1 auto',
  flexDirection: 'column',
  minWidth: 'fit-content',
  opacity: disabled ? 0.2 : 1,

  '&.table-tr-group': {
    borderBottom: 'solid 1px rgba(0, 0, 0, 0.05)',

    '&:last-child': {
      borderBottom: 0,
    },
  },

  '&.table-expandable': {
    cursor: 'pointer',
  },
})

const cellStyles = {
  flex: '1 0 auto',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  padding: '7px 5px',
  overflow: 'hidden',
  transition: '0.3s ease',
  transitionProperty: 'width, min-width, padding, opacity',
  margin: 'auto',
  minWidth: '14.3rem',
  width: 0,

  '&.-hidden': {
    width: 0,
    minWidth: 0,
    padding: 0,
    border: 0,
    opacity: 0,
  },

  '&:last-child': {
    borderRight: 0,
  },
}

const tableCellStyles = (selectedCell, columnId, rowId) => ({
  ...cellStyles,
  ...(selectedCell?.columnId === columnId &&
    selectedCell?.rowId === rowId && {
      minHeight: 49,
      paddingTop: '13px !important',
      border: '1px solid #828282',
      backgroundColor: 'rgb(222, 222, 222)',
    }),
})

const switchContainerStyles = {
  ...cellStyles,
  textAlign: 'center',
  '& > div': {
    margin: 'auto',
  },
}

export const styles = {
  tableBody: tableBodyStyles,
  tableCell: tableCellStyles,
  switchContainer: switchContainerStyles,
}
