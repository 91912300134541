/* eslint-disable react/destructuring-assignment */
import React from 'react'
import Error from 'views/Error'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  render() {
    if (this.state.hasError) {
      return <Error />
    }
    return this.props.children
  }
}

export default ErrorBoundary
