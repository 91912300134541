import styled from '@emotion/styled'
import Select from 'react-select'

export const StyledSelect = styled(Select)``
const selectStyles = {
  '@media (max-width: 768px)': {
    marginBottom: '10px',
  },
}

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? 'blue' : 'white',
    color: state.isSelected ? 'white' : 'black',
  }),
}

export const styles = {
  select: selectStyles,
  custom: customStyles,
}
