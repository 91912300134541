/** @jsxImportSource @emotion/react */
import { FutureTable } from 'futurekit'
import { EmptyState } from 'components'
import { styles } from './BaselinePreview.styles'
import { useBaselinePreviewController } from './BaselinePreview.controller'

export const BaselinePreview = () => {
  const { baselineDetails } = useBaselinePreviewController()

  if (!baselineDetails?.excel_table?.length) {
    return <EmptyState size="5rem" />
  }
  return (
    <FutureTable
      data={baselineDetails.excel_table}
      columnsConfig={{
        isStriped: false,
        dynamicColumns: true,
      }}
      css={styles.table}
    />
  )
}
