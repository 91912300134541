/** @jsxImportSource @emotion/react */
import { useMemo } from 'react'
import { Label } from 'reactstrap'
import { Button } from 'components'
import { useUserFormController } from './UserForm.controller'
import { StyledInput, StyledSelect, StyledButtonWrapper, styles } from './UserForm.styles'

export const UserForm = () => {
  const {
    form,
    rolesOptions,
    isDefaultMode,
    isEditMode,
    isCreationMode,
    isActionButtonDisabled,
    handleInputChange,
    handleSelectChange,
    handlePrimaryButtonClick,
    handleCancel,
    handleDelete,
  } = useUserFormController()

  const buttonText = useMemo(() => {
    if (isDefaultMode) return 'Add'
    if (isEditMode) return 'Save'
    return 'Create'
  }, [isDefaultMode, isEditMode])

  return (
    <>
      <Label>Name</Label>
      <StyledInput
        css={styles.input}
        name="userName"
        placeholder="Name"
        value={form.userName}
        onChange={handleInputChange}
        disabled={isDefaultMode || isEditMode}
      />
      <Label>Email</Label>
      <StyledInput
        css={styles.input}
        name="email"
        placeholder="Email"
        value={form.email}
        onChange={handleInputChange}
        disabled={isDefaultMode}
      />
      {isCreationMode && (
        <>
          <Label>Password</Label>
          <StyledInput
            css={styles.input}
            name="password"
            placeholder="Password"
            type="password"
            onChange={handleInputChange}
          />
          <Label>Confirm password</Label>
          <StyledInput
            css={styles.input}
            name="password_confirm"
            placeholder="Repeat password"
            type="password"
            onChange={handleInputChange}
          />
        </>
      )}
      <Label>Role</Label>
      <StyledSelect
        css={styles.select}
        className="react-select primary"
        classNamePrefix="react-select"
        options={rolesOptions}
        value={form.role}
        onChange={handleSelectChange}
        isDisabled={isDefaultMode}
      />
      <StyledButtonWrapper css={styles.buttonWrapper}>
        {isEditMode && (
          <Button outline color="danger" css={styles.secondaryActionButton} handleClick={handleDelete}>
            Delete
          </Button>
        )}
        {!isDefaultMode && (
          <Button outline isCancelButton color="danger" handleClick={handleCancel}>
            Cancel
          </Button>
        )}
        <Button handleClick={handlePrimaryButtonClick} isDisabled={isActionButtonDisabled}>
          {buttonText}
        </Button>
      </StyledButtonWrapper>
    </>
  )
}
