/** @jsxImportSource @emotion/react */
import { ReactComponent as EmptyStateIcon } from 'assets/img/empty-state.svg'
import { StyledWrapper, styles } from './EmptyState.styles'

export const EmptyState = ({ height, size }) => {
  return (
    <StyledWrapper css={styles.wrapper(height)}>
      <EmptyStateIcon css={styles.inner(size)} />
    </StyledWrapper>
  )
}
