import { createContext, useReducer, useCallback, useMemo, useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { getTechnologyFilter, getManufacturerFilter, getHardwareTypeFilter } from 'api'
import { BaselineHistoryReducer } from './BaselineHistoryReducer'

export const BaselineHistoryContext = createContext({})

const BASELINE_HISTORY_INITIAL_STATE = {
  selectedTechnology: null,
  selectedManufacturer: null,
  selectedHardwareType: null,
}

export const BaselineHistoryProvider = ({ children }) => {
  const [state, dispatch] = useReducer(BaselineHistoryReducer, BASELINE_HISTORY_INITIAL_STATE)

  const { data: technology, isLoading: isTechnologyLoading } = useQuery(['technologyFilter'], getTechnologyFilter)
  const technologyListData = technology?.values.map(el => ({
    value: el,
    label: el,
  }))

  const { data: manufacturer, isLoading: isManufacturerLoading } = useQuery(
    ['manufacturerFilter'],
    getManufacturerFilter
  )
  const manufacturerListData = manufacturer?.values.map(el => ({
    value: el,
    label: el,
  }))

  const { data: hardwareType, isLoading: isHardwareTypeLoading } = useQuery(
    ['hardwareTypeFilter'],
    getHardwareTypeFilter
  )
  const hardwareTypeListData = hardwareType?.values.map(el => ({
    value: el,
    label: el,
  }))

  const selectTechnology = useCallback(payload => {
    dispatch({ type: 'SELECT_TECHNOLOGY', payload })
  }, [])

  const selectManufacturer = useCallback(payload => {
    dispatch({ type: 'SELECT_MANUFACTURER', payload })
  }, [])

  const selectHardwareType = useCallback(payload => {
    dispatch({ type: 'SELECT_HARDWARE_TYPE', payload })
  }, [])

  const contextValue = useMemo(
    () => ({
      ...state,
      technologyListData,
      manufacturerListData,
      hardwareTypeListData,
      isTechnologyLoading,
      isManufacturerLoading,
      isHardwareTypeLoading,
      selectTechnology,
      selectManufacturer,
      selectHardwareType,
    }),
    [
      state,
      technologyListData,
      manufacturerListData,
      hardwareTypeListData,
      isTechnologyLoading,
      isManufacturerLoading,
      isHardwareTypeLoading,
      selectTechnology,
      selectManufacturer,
      selectHardwareType,
    ]
  )

  useEffect(() => {
    if (
      technologyListData?.length &&
      manufacturerListData?.length &&
      hardwareTypeListData?.length &&
      !state.selectedTechnology &&
      !state.selectedManufacturer &&
      !state.selectedHardwareType
    ) {
      dispatch({
        type: 'SELECT_INITIAL_OPTIONS',
        payload: {
          selectedTechnology: technologyListData[0],
          selectedManufacturer: manufacturerListData[0],
          selectedHardwareType: hardwareTypeListData[0],
        },
      })
    }
  }, [
    technologyListData,
    hardwareTypeListData,
    manufacturerListData,
    state.selectedTechnology,
    state.selectedManufacturer,
    state.selectedHardwareType,
  ])

  return <BaselineHistoryContext.Provider value={contextValue}>{children}</BaselineHistoryContext.Provider>
}
