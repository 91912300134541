const CRUDManagementReducer = (state, action) => {
  switch (action.type) {
    case 'SET_SELECTED':
      return {
        ...state,
        selected: action.payload,
        mode: action.payload ? 'edit' : 'default',
      }
    case 'SET_MODE':
      return {
        ...state,
        mode: action.payload,
      }
    default:
      return state
  }
}

export { CRUDManagementReducer }
