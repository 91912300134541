import styled from '@emotion/styled'

export const StyledContainer = styled.div``
const containerStyles = {
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#f9f9f9',
}

export const StyledTitle = styled.p``
const titleStyles = {
  fontSize: '3rem',
  color: '#F80F52',
}

export const StyledLink = styled.a``
const linkStyles = {
  marginBottom: '10rem',
  fontSize: '1.7rem',
  cursor: 'pointer',
  color: '#7370e4',
  '&:hover': {
    color: '#9796C1',
  },
}

export const styles = {
  container: containerStyles,
  title: titleStyles,
  link: linkStyles,
}
