/** @jsxImportSource @emotion/react */
import { StyledContainer, StyledTitle, StyledLink, styles } from './Error.styles'

export const Error = () => {
  return (
    <StyledContainer css={styles.container}>
      <StyledTitle css={styles.title}>Something went wrong</StyledTitle>
      <StyledLink href="/" css={styles.link}>
        Go to Home Page
      </StyledLink>
    </StyledContainer>
  )
}
