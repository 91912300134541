import styled from '@emotion/styled'
import { HEIGHT_300 } from 'utils/constants'

export const StyledTitle = styled.h6``

export const StyledTextarea = styled.textarea``

export const StyledButtonWrapper = styled.div``

export const StyledAlertBody = styled.div``

const titleStyles = {
  fontSize: '12px',
  color: '#9a9a9a',
  fontWeight: 400,
}

const tableStyles = {
  height: HEIGHT_300,
  marginTop: '1.5rem',

  '@media (max-width: 1200px)': {
    marginTop: '10px',
  },
}

const textareaStyles = {
  width: '100%',
  minHeight: HEIGHT_300,
  padding: '10px 15px',
}

const pseudocodeStyles = {
  ...textareaStyles,
  fontWeight: 'bold',
  color: '#ff3636cc',
}

const codeMirrorStyles = {
  border: '1px solid rgba(118, 118, 118, 0.3)',
  borderRadius: '0.25rem',
}

const buttonWrapperStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: 10,
  marginTop: 'auto',
}

const alertBodyStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  marginLeft: '1rem',

  '& div:nth-of-type(2n+1)': {
    fontWeight: 700,
    fontSize: '1rem',
    marginBottom: '5px',
  },
  '& *:nth-of-type(2n)': {
    textAlign: 'left',
    marginBottom: '1rem',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  textarea: {
    resize: 'none',
    border: 'none',
    width: '100%',
    height: '120px',
    backgroundColor: 'white',
    marginBottom: '1rem',
  },
}

export const styles = {
  title: titleStyles,
  table: tableStyles,
  textarea: textareaStyles,
  pseudocode: pseudocodeStyles,
  codeMirror: codeMirrorStyles,
  buttonWrapper: buttonWrapperStyles,
  alertBody: alertBodyStyles,
}
