/** @jsxImportSource @emotion/react */
import { Card, CardHeader, CardBody, CardTitle, Input, Col } from 'reactstrap'
import { Button } from 'components'
import { useLoginController } from './Login.controller'
import { StyledContainer, StyledRow, StyledFooter, StyledInputWrapper, styles } from './Login.styles'

export const Login = () => {
  const { user, password, handleUserChange, handlePasswordChange, handleLogIn, clearFields, handleKeyDown } =
    useLoginController()

  return (
    <StyledContainer css={styles.container}>
      <StyledRow css={styles.row}>
        <Col md="6">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Login</CardTitle>
            </CardHeader>
            <CardBody>
              <label>User</label>
              <StyledInputWrapper css={styles.inputWrapper}>
                <Input type="text" placeholder="username" value={user} onChange={handleUserChange} />
              </StyledInputWrapper>
              <label>Password</label>
              <StyledInputWrapper css={styles.inputWrapper}>
                <Input
                  type="password"
                  placeholder="password"
                  value={password}
                  onKeyDown={handleKeyDown}
                  onChange={handlePasswordChange}
                />
              </StyledInputWrapper>
            </CardBody>
            <StyledFooter css={styles.footer}>
              <Button className="btn-fill" color="outline-primary" handleClick={clearFields}>
                Clear
              </Button>
              <Button className="btn-fill" handleClick={handleLogIn}>
                Login
              </Button>
            </StyledFooter>
          </Card>
        </Col>
      </StyledRow>
    </StyledContainer>
  )
}
