/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types'
import { Spinner } from '../Spinner'
import { useExcelGeneratorController } from './ExcelGenerator.controller'
import { StyledButton, styles } from './ExcelGenerator.styles'

const ExcelGenerator = ({ collection, columnsToSkip, excelName, sheetName }) => {
  const { isLoading, handleClick } = useExcelGeneratorController({ collection, columnsToSkip, excelName, sheetName })

  return (
    <StyledButton
      color="primary"
      onClick={handleClick}
      disabled={!collection || !collection.length || !collection[0]}
      css={styles.button}
    >
      {(isLoading && <Spinner color="white" size="1rem" />) || <>Export</>}
    </StyledButton>
  )
}

ExcelGenerator.defaultProps = {
  columnsToSkip: [],
  excelName: 'Excel name',
  sheetName: 'Sheet name',
}

ExcelGenerator.propTypes = {
  collection: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columnsToSkip: PropTypes.arrayOf(PropTypes.string),
  excelName: PropTypes.string,
  sheetName: PropTypes.string,
}

export { ExcelGenerator }
