import styled from '@emotion/styled'
import { CardFooter, Row, Container } from 'reactstrap'

export const StyledRow = styled(Row)``

export const StyledFooter = styled(CardFooter)``

export const StyledInputWrapper = styled.div``

export const StyledContainer = styled(Container)``

const fullHeightStyles = {
  height: '100%',
}

const rowStyles = {
  ...fullHeightStyles,
  justifyContent: 'center',
  alignItems: 'center',
}

const footerStyles = {
  display: 'flex',
  justifyContent: 'end',

  '.btn-fill.btn.btn-outline-primary': {
    marginRight: '10px',
  },
}

const inputWrapperStyles = {
  marginBottom: '10px',
  position: 'relative',
  input: {
    padding: '10px 18px',
  },
}

const containerStyles = {
  ...fullHeightStyles,
}

export const styles = {
  row: rowStyles,
  footer: footerStyles,
  inputWrapper: inputWrapperStyles,
  container: containerStyles,
}
