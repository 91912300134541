const colStyles = (isFirstStep, transition) => {
  const partialStyles = transition
    ? {
        maxWidth: isFirstStep ? 0 : 'initial',
        maxHeight: isFirstStep ? 0 : 'initial',
        opacity: isFirstStep ? 0 : 1,
        transition,
      }
    : {
        maxWidth: isFirstStep ? 0 : 'initial',
        transition: `all 0.5s ease-in-out`,
      }

  return {
    ...partialStyles,
    '@media (max-width: 1200px)': {
      width: '100%',
    },
  }
}

export const styles = {
  col: colStyles,
}
