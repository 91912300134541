import { GlobalContext } from 'context'
import { useEffect, useContext, useState } from 'react'

export const useButtonController = ({ priority, isCancelButton }) => {
  const { getUserRole, highestPriority } = useContext(GlobalContext)
  const userRole = getUserRole()

  const [isDisabledByRole, setIsDisabledByRole] = useState(false)

  useEffect(() => {
    const comparator = priority || highestPriority
    if (userRole?.priority < comparator && !isCancelButton) {
      setIsDisabledByRole(true)
    }
  }, [priority, userRole?.priority, highestPriority, isCancelButton])

  return {
    isDisabledByRole,
  }
}
