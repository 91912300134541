import { useContext, useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'
import { GlobalContext } from 'context'
import { changePassword as changePasswordApi } from 'api'
import { showChangePasswordAlert, showSuccessAlert } from 'utils'

export const useLayoutController = () => {
  const { user, logOut } = useContext(GlobalContext)

  const { mutateAsync } = useMutation(changePasswordApi, {
    onSuccess: res => {
      showSuccessAlert({
        title: res.message,
        onClose: logOut,
      })
    },
  })

  const callbackAsyncFn = useCallback(async () => {
    const alertValues = {
      currentPassword: document.getElementById('currentPassword')?.value,
      newPassword: document.getElementById('newPassword')?.value,
    }
    try {
      await mutateAsync({
        old_password: alertValues.currentPassword,
        new_password: alertValues.newPassword,
        new_password_confirm: alertValues.newPassword,
      })
    } catch (error) {
      // error handled on api interceptor
    }
  }, [mutateAsync])

  const handleChangePassword = () => showChangePasswordAlert({ callbackAsyncFn })

  return {
    user,
    logOut,
    handleChangePassword,
  }
}
