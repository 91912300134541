/** @jsxImportSource @emotion/react */
import { useContext } from 'react'
import { useQuery } from '@tanstack/react-query'
import { getBaselineHistory, getBaselineHistoryFilter } from 'api'
import { CRUDManagementContext, BaselineHistoryContext } from 'context'

export const useListController = () => {
  const { selected, select } = useContext(CRUDManagementContext)
  const { selectedTechnology, selectedManufacturer, selectedHardwareType } = useContext(BaselineHistoryContext)

  const { data, isLoading, fetchStatus, refetch } = useQuery(
    ['baselineHistory', selectedTechnology?.value, selectedManufacturer?.value, selectedHardwareType?.value],
    getBaselineHistory,
    {
      enabled: !!selectedTechnology && !!selectedManufacturer && !!selectedHardwareType,
    }
  )

  const {
    data: filter,
    isLoading: isFilterLoading,
    fetchStatus: fetchFilterStatus,
  } = useQuery(
    ['baselineHistoryFilter', selectedTechnology?.value, selectedManufacturer?.value, selectedHardwareType?.value],
    getBaselineHistoryFilter,
    {
      enabled: !!selectedTechnology && !!selectedManufacturer && !!selectedHardwareType,
    }
  )

  return {
    data,
    filter,
    isLoading: (isLoading && fetchStatus === 'fetching') || (isFilterLoading && fetchFilterStatus === 'fetching'),
    selected,
    select,
    refetch,
  }
}
