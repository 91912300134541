import styled from '@emotion/styled'
import { Label } from 'reactstrap'
import { Button } from 'components'

export const StyledWrapper = styled.div``

export const StyledSwitchLabel = styled(Label)``

export const StyledButton = styled(Button)``

const toolbarStyles = {
  height: '50px',
  marginTop: '-10px',
  marginBottom: '10px',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
}

const switchWrapperStyles = {
  display: 'flex',
  alignItems: 'center',
  marginTop: '0.75rem',
  marginRight: 'auto',
}

const switchLabelStyles = {
  marginRight: '0.7rem',
}

const buttonStyles = (isDeleteMode, marginRight = 1) => ({
  marginRight,
  opacity: isDeleteMode ? 1 : 0,
  visibility: isDeleteMode ? 'visible' : 'hidden',
  transition: 'all 0.5s ease-in-out',
})

const tableStyles = {
  height: 'calc(100% - 50px)',
}

export const styles = {
  toolbar: toolbarStyles,
  switchWrapper: switchWrapperStyles,
  switchLabel: switchLabelStyles,
  button: buttonStyles,
  table: tableStyles,
}
