import styled from '@emotion/styled'

const BOX_SHADOW_VALUE = 'rgba(44, 41, 37, 0.25) 0px 1px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px'

export const StyledDraggableElement = styled.div``

const draggableElementStyles = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  padding: '3px 0 3px 6px',
  marginX: 5,
  border: '1px solid transparent',
  borderRadius: 8,
  boxShadow: isDragging ? BOX_SHADOW_VALUE : 'none',
  color: isDragging ? '#d2d2d2' : 'initial',
  transition: 'box-shadow 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s',
  ...draggableStyle,

  '&:hover': {
    boxShadow: BOX_SHADOW_VALUE,
  },
})

export const styles = {
  draggableElement: draggableElementStyles,
}
