/** @jsxImportSource @emotion/react */
import { FileUpload, Button } from 'components'
import { useKeywordsController } from './Keywords.controller'
import { StyledRow, StyledCol, StyledButtonWrapper, styles } from './Keywords.styles'

export const Keywords = ({ editorRole }) => {
  const { disabled, isLoading, handleSave, setFile } = useKeywordsController()

  return (
    <>
      <StyledRow xl={12} css={styles.row}>
        <StyledCol xl={12} css={styles.col}>
          <FileUpload label="Keywords File" isDisabled={isLoading} callbackFn={setFile} />
        </StyledCol>
      </StyledRow>
      <StyledButtonWrapper css={styles.buttonWrapper}>
        <Button priority={editorRole.priority} isDisabled={disabled} handleClick={handleSave}>
          Save
        </Button>
      </StyledButtonWrapper>
    </>
  )
}
