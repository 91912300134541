import { useState, useCallback } from 'react'
import { useQuery } from '@tanstack/react-query'
import { getBaselineStatusHistory, getBaselineStatusHistoryFilter } from 'api'

export const useBaselineStatusHistoryController = () => {
  const [selected, setSelected] = useState(null)

  const { data, isLoading } = useQuery(['baselineStatusHistory'], getBaselineStatusHistory)

  const { data: filter, isLoading: isFilterLoading } = useQuery(
    ['baselineStatusHistoryFilter'],
    getBaselineStatusHistoryFilter
  )

  const handleRowClick = useCallback(value => {
    setSelected(value)
  }, [])

  return {
    data,
    filter,
    isLoading: isLoading || isFilterLoading,
    selected,
    handleRowClick,
  }
}
