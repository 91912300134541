import styled from '@emotion/styled'

export const StyledWrapper = styled.div``

const rowStyles = {
  height: 'calc(100% - 70px)',
}

const colStyles = {
  height: '100%',
}

const codeMirrorStyles = {
  border: '1px solid rgba(118, 118, 118, 0.3)',
  borderRadius: '0.25rem',
}

const buttonWrapperStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: 10,
  marginTop: 'auto',
}

export const styles = {
  row: rowStyles,
  col: colStyles,
  codeMirror: codeMirrorStyles,
  buttonWrapper: buttonWrapperStyles,
}
