import { BaselineHistory } from 'components'
import { useListController } from './List.controller'

export const List = () => {
  const { data, filter, isLoading, selected, select, refetch } = useListController()

  return (
    <BaselineHistory
      data={data}
      filter={filter}
      isLoading={isLoading}
      showRestoreButton={false}
      exportConfig={{
        columnsToSkip: [],
        excelName: 'baseline_history',
        sheetName: 'Sheet 1',
      }}
      useAdvancedFilter
      selected={selected}
      handleRowClick={select}
      refetch={refetch}
    />
  )
}
