import { useState } from 'react'

const INITIAL_DROPDOWNS_VISIBILITY = {
  filter: false,
  order: false,
}

export const useToolbarController = () => {
  const [dropdownVisibility, setDropdownVisibility] = useState(INITIAL_DROPDOWNS_VISIBILITY)

  const toggleDropdownVisibility = name =>
    setDropdownVisibility(prevState => {
      return {
        ...INITIAL_DROPDOWNS_VISIBILITY,
        [name]: !prevState[name],
      }
    })

  const handleFilterClick = () => toggleDropdownVisibility('filter')

  const handleOrderClick = () => toggleDropdownVisibility('order')

  return { dropdownVisibility, handleFilterClick, handleOrderClick }
}
