import styled from '@emotion/styled'
import { Tooltip } from 'react-bootstrap'
import { Button } from 'reactstrap'

export const StyledToolbar = styled.div``

export const StyledButton = styled(Button)``

export const StyledIcon = styled.i``

export const StyledTooltip = styled(Tooltip)``

const toolbarStyles = {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '10px',
  width: '100%',
  marginTop: '-15px',
  marginBottom: '20px',
}

const buttonStyles = {
  margin: 0,
  padding: '5px',
  '&.btn.btn-link': {
    color: 'gray',
  },
  '&.btn.btn-link:hover': {
    boxShadow: 'none',
  },
  '&.btn.btn-link:not(.disabled):active': {
    color: 'lightgray',
    backgroundColor: 'transparent',
  },
}

const iconStyles = {
  fontSize: 'medium',
}

const tooltipStyles = {
  '&.show': {
    opacity: 1,
  },
  '.tooltip-arrow::before': {
    borderTopColor: '#ffffff',
  },
  '.tooltip-inner': {
    backgroundColor: '#ffffff',
  },
}

export const styles = {
  toolbar: toolbarStyles,
  toolbarButton: buttonStyles,
  toolbarIcon: iconStyles,
  tooltip: tooltipStyles,
}
