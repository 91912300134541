import { useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { uploadKeywordsFile } from 'api'
import { showPrompt, showSuccessAlert } from 'utils'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

export const useKeywordsController = () => {
  const [file, setFile] = useState(null)

  const history = useHistory()

  const handleOnClose = () => {
    history.push('/keywords/editor')
  }

  const { mutateAsync: uploadKeywords, isLoading: loadFileIsLoading } = useMutation(uploadKeywordsFile, {
    onSuccess: res => {
      showSuccessAlert({
        title: res.message,
        body: 'You will be redirected to keyword editor',
        onClose: handleOnClose,
      })
    },
  })

  const handleUpload = async () => {
    try {
      await uploadKeywords({ file })
    } catch (error) {
      // error handled on api interceptor
    }
  }

  const handleSave = () => {
    showPrompt({
      title: 'Are you sure you want to upload the keywords?',
      callbackAsyncFn: handleUpload,
    })
  }

  return {
    disabled: !file,
    isLoading: loadFileIsLoading,
    handleSave,
    setFile,
  }
}
