import styled from '@emotion/styled'

export const StyledFileUploadContainer = styled.div``

export const StyledFormField = styled.input``

export const StyledDragDropText = styled.p``

export const StyledUploadFileBtn = styled.button``

export const StyledIcon = styled.i``

export const StyledFileWrapper = styled.div``

export const StyledDeleteContainer = styled.div``

const fileUploadContainerStyles = {
  position: 'relative',
  margin: '0 0 15px',
  border: '2px dotted #d6d7df',
  padding: '35px 20px',
  borderRadius: '6px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: 'white',
  transition: 'all 250ms ease-in-out',

  '&[data-dragging="true"] ': {
    backgroundColor: 'rgba(202,202,202,0.10)',
  },
  '@media (max-width: 1200px)': {
    padding: '15px 8px',
  },
}

const formFieldStyles = {
  fontSize: '18px',
  display: 'block',
  width: '100%',
  border: 'none',
  textTransform: 'none',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  opacity: 0,
  '&:focus': {
    outline: 'none',
  },
}

const dragDropTextStyles = {
  letterSpacing: '2px',
  marginTop: 0,
  textAlign: 'center',
  fontSize: '12px',
}

const uploadFileBtnStyles = {
  boxSizing: 'border-box',
  appearance: 'none',
  backgroundColor: 'transparent',
  border: '2px solid #fa7a50',
  cursor: 'pointer',
  fontSize: '1rem',
  lineHeight: 1,
  padding: '1.1rem 2.8rem',
  textAlign: 'center',
  textTransform: 'uppercase',
  fontWeight: 700,
  borderRadius: '6px',
  color: '#fa7a50',
  position: 'relative',
  overflow: 'hidden',
  zIndex: 1,
  transition: 'color 250ms ease-in-out',
  fontFamily: "'Open Sans', sans-serif",
  width: '45%',
  display: 'flex',
  alignItems: 'center',
  paddingRight: 0,
  justifyContent: 'center',
  '&:after': {
    content: "''",
    position: 'absolute',
    display: 'block',
    top: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    width: 0,
    height: '100%',
    background: '#fa7a50',
    zIndex: '-1',
    transition: 'width 250ms ease-in-out',
  },
  ' @media only screen and (max-width: 894px)': {
    width: '85%',
  },
  '@media only screen and (max-width: 350px)': {
    width: '100%',
  },
  '&:hover': {
    color: '#fff',
    outline: '0',
    background: 'transparent',
    '&:after': {
      width: '110%',
    },
  },
  '&:focus': {
    outline: '0',
    background: 'transparent',
  },
  '&:disabled': {
    opacity: '0.4',
    filter: 'grayscale(100%)',
    pointerEvents: 'none',
  },
}

const styledIconStyles = {
  fontSize: '22px',
  marginRight: '5px',
  borderRight: '2px solid',
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  width: '20%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}

const fileWrapperStyles = {
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
}

const deleteContainerStyles = isDisabled => ({
  marginBottom: '5px',
  opacity: isDisabled ? 0.6 : 1,
  cursor: isDisabled ? 'not-allowed' : 'pointer',
})

export const styles = {
  fileUploadContainer: fileUploadContainerStyles,
  formField: formFieldStyles,
  dragDropText: dragDropTextStyles,
  uploadFileBtn: uploadFileBtnStyles,
  styledIcon: styledIconStyles,
  fileWrapper: fileWrapperStyles,
  deleteContainer: deleteContainerStyles,
}
