import { useContext, useState, useMemo, useEffect } from 'react'
import { RulesContext, GlobalContext } from 'context'
import { EDITABLE_STATUS } from 'utils/constants'

export const useParameterEditorController = () => {
  const {
    selectedBaseline,
    selectedStatus,
    selectedParameters,
    selectBaseline,
    selectParameter,
    selectCell,
    userIsOwnerForSelectedBaseline,
  } = useContext(RulesContext)
  const { isUserRoleEqualOrGreaterThanEditor, isUserRoleEqualOrGreaterThanAdvanced } = useContext(GlobalContext)

  const selectedParameter = selectedParameters[0] || null
  const [transition, setTransition] = useState()

  const isReadOnly = useMemo(() => {
    if (!EDITABLE_STATUS.includes(selectedStatus?.name)) {
      return true
    }
    if (isUserRoleEqualOrGreaterThanAdvanced) {
      return false
    }
    return isUserRoleEqualOrGreaterThanEditor && userIsOwnerForSelectedBaseline
  }, [
    isUserRoleEqualOrGreaterThanAdvanced,
    isUserRoleEqualOrGreaterThanEditor,
    selectedStatus?.name,
    userIsOwnerForSelectedBaseline,
  ])

  useEffect(() => {
    const wTransTiming = selectedParameter ? 'linear' : 'ease-in-out'
    const wTransDelay = selectedParameter ? '0.5s' : '0.1s'
    const oTransDelay = selectedParameter ? '' : '0.45s'

    setTransition(`width 0s ${wTransTiming} ${wTransDelay}, opacity 0.5s ease-in ${oTransDelay}`)
  }, [selectedParameter])

  return {
    transition,
    selectedBaseline,
    selectedParameters,
    selectBaseline,
    selectParameter,
    selectCell,
    isParameterSelected: !!selectedParameter,
    cardTitle: selectedParameter ? `${selectedParameter.mo_class_name} - ${selectedParameter.parameter_name}` : '',
    isReadOnly,
  }
}
