import styled from '@emotion/styled'
import { Col } from 'reactstrap'

export const StyledCol = styled(Col)``

const colStyles = (isParameterSelected, transition) => {
  const partialStyles = transition
    ? {
        width: isParameterSelected ? '33%' : 0,
        height: isParameterSelected ? '100%' : 0,
        opacity: isParameterSelected ? 1 : 0,
        transition,
      }
    : {
        width: `${isParameterSelected ? '66' : '100'}%`,
        transition: `width 0.5s ease-in-out ${isParameterSelected ? '' : '0.45s'}`,
      }

  return {
    ...partialStyles,
    flex: '0 0 auto',
    '@media (max-width: 1200px)': {
      width: '100%',
    },
  }
}

export const styles = {
  col: colStyles,
}
